import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PageMeta from '../../common/PageMeta.js';
import BlogPostHeader from '../BlogPostHeader.js';
import FooterOne from '../../../layout/Footer/FooterOne.js';
import Navbar from '../../../layout/Header/Navbar.js';
import Layout from '../../../layout/Layout.js';
import { blogPosts } from '../../../data/blogPosts.js'
import NeedStorefrontHelp from '../../cta/NeedStorefrontHelp.js';
import { Helmet } from 'react-helmet';


const BlogPost003 = () => {

  const slug = 'compatible-domain-service-providers'

  const blogPost = blogPosts.find(obj => {
    return obj.slug === slug
  })

  return (
    <React.Fragment>  

      <Layout>
        <PageMeta title={`${blogPost.title} | SmartSeller Blog`}/>
        <Helmet>
          {/* Essential META Tags */}
          <meta property="og:title" content={`${blogPost.title} | SmartSeller Blog`} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={`https://sseller.io${blogPost.images[0]}`} />
          <meta property="og:url" content={`https://sseller.io${blogPost.images[0]}`} />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Non-Essential, But Recommended */}
          <meta name="description" content={blogPost.excerpt} />
          <meta property="og:description" content={blogPost.excerpt} />
          <meta property="og:site_name" content="SmartSeller"/>
          <meta name="twitter:image:alt" content={`${blogPost.title} | SmartSeller Blog`} />
          {/* Non-Essential, But Required for Analytics */}
          {/* <meta property="fb:app_id" content="your_app_id" />
          <meta name="twitter:site" content="@website-username" /> */}
        </Helmet>
        <Navbar navDark posAbsolute />
        <BlogPostHeader title={blogPost.title} categories={blogPost.categories} readTime={blogPost.readTime} updatedDate={blogPost.updatedDateString}/>
        <section className='blog-details pt-60 pb-120'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-lg-8 pe-5'>
                <div className='blog-details-wrap'>
                  <img
                    src={blogPost.images[0]}
                    className='img-fluid mb-4 rounded-custom'
                    alt='apply'
                  />
                  <p>
                    Connecting your domain to your SmartSeller storefront is a straightforward process, even if it's currently linked to another website. Our managed services team will help 
                    you with the process. SmartSeller caters to over 20 domain providers, making it a seamless experience that only requires a few clicks to set up! The following is a list of 
                    supported providers.
                  </p>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='domain-providers'>Domain Providers</h2>
                    <ul>
                      <li>
                        <Link to='https://www.123-reg.co.uk/' target='_blank'>123-Reg</Link>
                      </li>
                      <li>
                        <Link to='https://aws.amazon.com/route53/' target='_blank'>Amazon Route 53</Link>
                      </li>
                      <li>
                        <Link to='https://www.arsys.net/' target='_blank'>Arsys</Link>
                      </li>
                      <li>
                        <Link to='https://www.bluehost.com/' target='_blank'>Bluehost</Link>
                      </li>
                      <li>
                        <Link to='https://www.crazydomains.com/' target='_blank'>Crazy Domains</Link>
                      </li>
                      <li>
                        <Link to='https://www.domain.com/' target='_blank'>Domain.com</Link>
                      </li>
                      <li>
                        <Link to='https://www.digitalocean.com/' target='_blank'>Digital Ocean</Link>
                      </li>
                      <li>
                        <Link to='https://dnsimple.com/' target='_blank'>DNSimple</Link>
                      </li>
                      <li>
                        <Link to='https://www.dreamhost.com/' target='_blank'>DreamHost</Link>
                      </li>
                      <li>
                        <Link to='https://www.gandi.net/en-US' target='_blank'>Gandi</Link>
                      </li>
                      <li>
                        <Link to='https://www.godaddy.com/' target='_blank'>GoDaddy</Link>
                      </li>
                      <li>
                        <Link to='https://www.hetzner.com/' target='_blank'>Hetzner</Link>
                      </li>
                      <li>
                        <Link to='https://www.hover.com/' target='_blank'>Hover</Link>
                      </li>
                      <li>
                        <Link to='https://www.hostgator.com/' target='_blank'>HostGator</Link>
                      </li>
                      <li>
                        <Link to='https://www.ionos.com/' target='_blank'>IONOS</Link>
                      </li>
                      <li>
                        <Link to='https://iwantmyname.com/' target='_blank'>IWantMyName</Link>
                      </li>
                      <li>
                        <Link to='https://www.name.com/' target='_blank'>Name.com</Link>
                      </li>
                      <li>
                        <Link to='https://www.namebright.com/' target='_blank'>NameBright</Link>
                      </li>
                      <li>
                        <Link to='https://www.namecheap.com/' target='_blank'>Namecheap</Link>
                      </li>
                      <li>
                        <Link to='https://www.namesilo.com/' target='_blank'>NameSilo</Link>
                      </li>
                      <li>
                        <Link to='https://www.networksolutions.com/' target='_blank'>Network Solutions</Link>
                      </li>
                      <li>
                        <Link to='https://www.one.com/en/' target='_blank'>One.com</Link>
                      </li>
                      <li>
                        <Link to='https://www.ovhcloud.com/en/' target='_blank'>OVH</Link>
                      </li>
                      <li>
                        <Link to='https://porkbun.com/' target='_blank'>Porkbun</Link>
                      </li>
                      <li>
                        <Link to='https://www.register.com/' target='_blank'>Register.com</Link>
                      </li>
                      <li>
                        <Link to='https://www.register.it/' target='_blank'>Register.it</Link>
                      </li>
                      <li>
                        <Link to='https://registro.br/' target='_blank'>Registro.br</Link>
                      </li>
                      <li>
                        <Link to='https://www.siteground.com/' target='_blank'>SiteGround</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='author-wrap  bg-light p-5 sticky-sidebar rounded-custom mt-5 mt-lg-0'>
                  <div className='mb-4 text-left'>
                    <div className='author-info'>
                      <h5 className='mb-0'>In This Post</h5>
                    </div>
                    <ul>
                      {blogPost.menu.map((data) => (
                        <li key={`sidebar-${blogPost.id}-${data}`}>
                          <HashLink to={`#${data.id}`} className='text-muted text-decoration-none py-1 d-block'>{data.label}</HashLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <hr className='mb-4'></hr>
                  <div className='text-center'>
                    <img
                      src={blogPost.author.image}
                      alt='author'
                      width='100'
                      className='img-fluid shadow-sm rounded-circle mb-4'
                    />
                    <div className='author-info'>
                      <h5 className='mb-0'>{blogPost.author.name}</h5>
                      <span className='small'>{blogPost.author.title}</span>
                    </div>
                    <ul className='list-unstyled author-social-list list-inline mb-0'>
                      {
                        blogPost.author.social.facebook 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.facebook} target='_blank'>
                                  <i className='fab fa-facebook'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.instagram
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.instagram} target='_blank'>
                                  <i className='fab fa-instagram'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.linkedIn 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.linkedIn} target='_blank'>
                                  <i className='fab fa-linkedin-in'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.gitHub 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.gitHub} target='_blank'>
                                  <i className='fab fa-github'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.gitLab
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.gitLab} target='_blank'>
                                  <i className='fab fa-gitlab'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.twitter
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.twitter} target='_blank'>
                                  <i className='fab fa-twitter'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NeedStorefrontHelp />
        <FooterOne
          style={{
            background:
              "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
          }}
        />
      </Layout>
    </React.Fragment>
  );
};

export default BlogPost003;