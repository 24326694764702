import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const TermsOfUseDetails = () => {
  return (
    <>
      <section className='support-content ptb-60'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-4 col-md-4 d-none d-md-block d-lg-block'>
              <div className='support-article-sidebar sticky-sidebar'>
                <div className='nav flex-column nav-pills support-article-tab bg-light rounded-custom p-5'>
                  <HashLink to='#introduction' className='text-muted text-decoration-none py-2 d-block'>1. Introduction</HashLink>
                  <HashLink to='#account-terms' className='text-muted text-decoration-none py-2 d-block'>2. Account Terms</HashLink>
                  <HashLink to='#account-activation' className='text-muted text-decoration-none py-2 d-block'>3. Account Activation</HashLink>
                  <HashLink to='#smartseller-rights' className='text-muted text-decoration-none py-2 d-block'>4. SmartSeller Rights</HashLink>
                  <HashLink to='#your-responsibilities' className='text-muted text-decoration-none py-2 d-block'>5. Your Responsibilities</HashLink>
                  <HashLink to='#payment' className='text-muted text-decoration-none py-2 d-block'>6. Payment of Fees and Taxes</HashLink>
                  <HashLink to='#confidentiality' className='text-muted text-decoration-none py-2 d-block'>7. Confidentiality</HashLink>
                  <HashLink to='#limitation' className='text-muted text-decoration-none py-2 d-block'>8. Limitation of Liability and Indemnification</HashLink>
                  <HashLink to='#intellectual' className='text-muted text-decoration-none py-2 d-block'>9. Intellectual Property and Your Materials</HashLink>
                  <HashLink to='#additional' className='text-muted text-decoration-none py-2 d-block'>10. Additional Services</HashLink>
                  <HashLink to='#feedback' className='text-muted text-decoration-none py-2 d-block'>11. Feedback and Reviews</HashLink>
                  <HashLink to='#dmca' className='text-muted text-decoration-none py-2 d-block'>12. DMCA Notice and Takedown Procedures</HashLink>
                  <HashLink to='#privacy' className='text-muted text-decoration-none py-2 d-block'>13. Privacy and Data Protection</HashLink>
                  <HashLink to='#contracting-party' className='text-muted text-decoration-none py-2 d-block'>14. SmartSeller Contracting Party</HashLink>
                  <HashLink to='#term' className='text-muted text-decoration-none py-2 d-block'>15. Term and Termination</HashLink>
                  <HashLink to='#modifications' className='text-muted text-decoration-none py-2 d-block'>16. Modifications</HashLink>
                  <HashLink to='#general' className='text-muted text-decoration-none py-2 d-block'>17. General Conditions</HashLink>
                </div>
              </div>
            </div>
            <div className='col-lg-8 col-md-8 p-lg-5'>

              <div className='support-article-wrap mb-5' id='introduction'>
                <h1 className='display-5 mb-4 fw-bold'>
                  1. Introduction
                </h1>
                <p>                
                  Welcome to SmartSeller! By creating a SmartSeller Account (as defined in Section 2) or using any SmartSeller Services (as defined below), you are agreeing to abide by the following terms 
                  and conditions (the <strong>"Terms of Service"</strong>).
                </p>
                <p>
                  In these Terms of Service, the terms <strong>"we", "us", "our"</strong> and <strong>"SmartSeller"</strong> refer to the relevant SmartSeller Contracting Party (as defined in Section 14 
                  below), while "you" refers to the SmartSeller User (if registering for or using a SmartSeller Service as an individual) or the business employing the SmartSeller User (if registering 
                  for or using a SmartSeller Service as a business), along with its affiliates.
                </p>
                <p>
                  SmartSeller offers a comprehensive commerce platform that empowers merchants to streamline their commerce operations. This platform includes various tools to help merchants create and 
                  customize online stores, sell across multiple channels (including web, mobile, social media, online marketplaces, and other online locations ("Online Services")), manage products, inventory, 
                  payments, fulfillment, shipping, business operations, marketing, advertising, and engage with both existing and potential customers. All such services provided by SmartSeller are 
                  collectively referred to as the "Services." Any new features or tools added to the existing Services will also be subject to these Terms of Service. You can review the current version of 
                  the Terms of Service at any time on our website: https://www.sseller.io/terms-of-service.
                </p>
                <p>
                  Before signing up for a SmartSeller Account or using any SmartSeller Service, you must read, agree with, and accept all the terms and conditions contained in these Terms of Service. This 
                  includes SmartSeller's Acceptable Use Policy ("AUP"), Privacy Policy, SmartSeller API License and Terms of Use ("API Terms"), and the SmartSeller Data Processing Addendum ("DPA"), 
                  if applicable. If you offer goods or services related to COVID-19, you must also read, acknowledge, and agree to the Rules of Engagement for Sale of COVID-19 Related Products.
                </p>
              </div>

              <div className='support-article-wrap mb-5' id='account-terms'>
                <h1 className='display-5 mb-4 fw-bold'>
                  2. Account Terms
                </h1>
                <ol>
                  <li>
                    To access and use the Services, you must register for a SmartSeller account ("Account"). To complete your Account registration, you must provide your full legal name, business address, 
                    phone number, a valid email address, and any other information indicated as required. SmartSeller may reject your application for an Account or cancel an existing Account at our sole 
                    discretion.
                  </li>
                  <li>
                    You must be at least 18 years old or, if the age of majority in your jurisdiction is higher, you must meet the age of majority in your jurisdiction to open an Account.
                  </li>
                  <li>
                    You confirm that you are using any Services provided by SmartSeller for the purpose of conducting business, not for personal, household, or family use.
                  </li>
                  <li>
                    You acknowledge that SmartSeller will primarily use the email address you provide during Account registration as the main method of communication ("Primary Email Address"). You are 
                    responsible for monitoring and maintaining an active Primary Email Address capable of sending and receiving messages. Authenticating your email communications with SmartSeller requires 
                    messages sent from your Primary Email Address.
                  </li>
                  <li>
                    You are responsible for safeguarding your password. SmartSeller is not liable for any loss or damage resulting from your failure to maintain the security of your Account and password. 
                    We may request additional security measures at our discretion and reserve the right to modify these requirements.
                  </li>
                  <li>
                    Technical support for the Services is provided exclusively to SmartSeller Users. Any questions about the Terms of Service should be directed to SmartSeller Support.
                  </li>
                  <li>
                    You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service, use of the Services, or access to the Services without the express written permission 
                    of SmartSeller.
                  </li>
                  <li>
                    You agree not to circumvent the technical limitations of the Services, including processing orders outside SmartSeller's Checkout, enabling disabled features, or reverse engineering 
                    the Services in any way.
                  </li>
                  <li>
                    You agree not to access the Services or monitor any content from the Services using automated means like robots, spiders, or scrapers.
                  </li>
                  <li>
                    You understand that your Materials may be transferred without encryption and may undergo changes to meet technical requirements of different networks or devices. "Materials" include 
                    your trademarks, copyrighted content, products, services you sell through the Services (including descriptions and prices), and any photos, images, videos, graphics, written content, 
                    audio files, code, information, or other data provided by you or your affiliates to SmartSeller or its affiliates.
                  </li>
                </ol>
              </div>

              <div className='support-article-wrap mb-5' id='account-activation'>
                <h1 className='display-5 mb-4 fw-bold'>
                  3. Account Activation
                </h1>
                <div className='job-details-info my-4'>
                  <h3 className='h5'>
                    3.1 Store Owner
                  </h3>
                  <ol>
                    <li>
                      Subject to Section 3.1.2, the person opening an Account and signing up for the Service is the contracting party (<strong>"Store Owner"</strong>) for the purposes of our Terms of 
                      Service. The Store Owner is the individual authorized to use any corresponding Account provided by SmartSeller for the Service. The Store Owner's name, including the legal name of 
                      the owning company if applicable, must be prominently displayed on the Store's website.
                    </li>
                    <li>
                      If you sign up for the Services on behalf of your employer, your employer becomes the Store Owner. In this case, you must use your employer-issued email address and represent that 
                      you have the authority to bind your employer to our Terms of Service. A Store Owner can have multiple SmartSeller Stores, and each Store must use SmartSeller Checkout for transactions.
                    </li>
                  </ol>
                  <h3 className='h5'>
                    3.2 Staff Accounts
                  </h3>
                  <ol>
                    <li>
                      Depending on your SmartSeller pricing plan, you can create one or more staff accounts (<strong>"Staff Accounts"</strong>) to allow others to access the Account. Each Staff Account 
                      must include a full legal name and a valid email account. The Store Owner is responsible for ensuring that employees, agents, and subcontractors, including those with Staff Accounts, 
                      comply with these Terms of Service. Any breaches by these individuals are the responsibility of the Store Owner. Store Owners are accountable for fulfilling all obligations under the 
                      Agreement, whether they sublicense or subcontract these obligations to third parties, including affiliates or subsidiaries of the Store Owner. Both the Store Owner and the users under 
                      Staff Accounts are referred to as <strong>"SmartSeller Users"</strong>.
                    </li>
                  </ol>
                  <h3 className='h5'>
                    3.3 Stripe and SmartSeller Payments Accounts
                  </h3>
                  <ol>
                    <li>
                      After signing up for the Service, SmartSeller will create a Stripe account on your behalf using your Primary Email Address. Depending on your location, SmartSeller may also create a 
                      SmartSeller Payments account for you. As the Store Owner, it is your responsibility to activate and maintain these payment accounts. If you decide to deactivate either payment 
                      account, it is your responsibility to do so.                  
                    </li>
                  </ol>
                  <h3 className='h5'>
                    3.4 Apple Pay for Safari Account
                  </h3>
                  <ol>
                    <li>
                      Upon sign-up, SmartSeller will create an Apple Pay for Safari (<strong>"Apple Pay"</strong>) account for you using the associated URL(s) and business name. If your location requires 
                      it, you will need to activate your Apple Pay account within your Account admin. You can deactivate the Apple Pay account if you no longer wish to use it. By using Apple Pay on your 
                      Store, you agree to be bound by the <Link to="https://www.apple.com/legal/applepayments/direct-payments/"> Apple Pay Platform Web Merchant Terms and Conditions</Link>, which may be updated by Apple. 
                      Updates to these terms will be posted at https://www.apple.com/legal/applepayments/direct-payments/. Your continued use of Apple Pay on your Store after any changes to the terms constitutes your 
                      agreement to the updated terms.                  
                    </li>
                  </ol>
                  <h3 className='h5'>
                    3.5 Google Pay
                  </h3>
                  <ol>
                    <li>
                      If you have enrolled in SmartSeller Payments, SmartSeller will create a Google Pay account on your behalf. You can deactivate this account if you no longer wish to use it. By using 
                      Google Pay on your Store, you agree to be bound by the <Link to="https://payments.developers.google.com/terms/sellertos" target="_blank"> Google Pay API Terms of Service</Link>, which 
                      may be updated by Google. Updates to these terms will be posted at https://payments.developers.google.com/terms/sellertos. Your continued use of Google Pay on your Store after any 
                      changes to the terms constitutes your agreement to the updated terms.                 
                    </li>
                  </ol>
                  <h3 className='h5'>
                    3.6 Meta Pay
                  </h3>
                  <ol>
                    <li>                  
                      If you have enrolled in SmartSeller Payments, SmartSeller will also create a Meta Pay account on your behalf. You can deactivate this account if you no longer wish to use it. By 
                      using Meta Pay on your Store, you agree to be bound by the <Link to="https://transparency.fb.com/en-gb/policies/other-policies/meta-pay-terms-and-conditions/" target="_blank"> Meta 
                      Pay Merchant Terms and Conditions</Link>, which may be updated by Meta. Updates to these terms will be posted by Meta. Your continued use of Meta Pay on your Store after any 
                      changes to the terms constitutes your agreement to the updated terms.
                    </li>
                  </ol>
                  <h3 className='h5'>
                    3.7 Domain Names
                  </h3>
                  <ol>
                    <li>
                      When you purchase a domain name through SmartSeller, domain registration will be set to automatically renew each year as long as your SmartSeller Account remains active. You are 
                      responsible for deactivating the auto-renewal function if you choose to do so.                  
                    </li>
                  </ol>
                </div>
              </div>

              <div className='support-article-wrap mb-5' id='smartseller-rights'>
                <h1 className='display-5 mb-4 fw-bold'>
                  4. SmartSeller Rights
                </h1>
                <ol>
                  <li>
                    The Services offer various features and functionalities. We are not obligated to make all Services or features available in every jurisdiction. We reserve the right to modify the 
                    Services or any part thereof for any reason, without notice, and at any time. SmartSeller does not pre-screen Materials, but we may refuse or remove any Materials from the Services, 
                    including if we determine that your goods or services violate our <Link to="/aup-policy"> AUP</Link> or these Terms of Service. Verbal or written abuse of any SmartSeller employee, 
                    member, or officer will result in immediate Account termination. We reserve the right to provide our Services to your competitors and offer no exclusivity. SmartSeller employees and 
                    contractors may also be SmartSeller customers or merchants, and they may compete with you while refraining from using your Confidential Information.
                  </li>
                  <li>
                    In case of a dispute regarding Account ownership, we may request documentation to determine or confirm ownership. Documentation may include a scanned copy of your business license, 
                    government-issued photo ID, the last four digits of the credit card on file, or confirmation of your employment status.
                  </li>
                  <li>
                    SmartSeller retains the authority to assess and establish the legitimate ownership of an Account, and may transfer it to the rightful Store Owner at our sole discretion. In cases 
                    where we cannot reasonably ascertain the rightful Store Owner, SmartSeller reserves the option to temporarily suspend or deactivate the Account until a resolution is reached between 
                    the involved parties, without prejudice to our other available rights and remedies.
                  </li>
                </ol>
              </div>

              <div className='support-article-wrap mb-5' id='your-responsibilities'>
                <h1 className='display-5 mb-4 fw-bold'>
                  5. Your Responsibilities
                </h1>
                <ol>
                  <li>
                    You must provide public-facing contact information, a refund policy, and order fulfillment timelines on your SmartSeller Store. The Services do not function as a marketplace, 
                    and any sales contract made through the Services is directly between you and the customer. You are the seller of record for all items you sell through the Services. You are responsible 
                    for all aspects of your SmartSeller Store, Materials, goods and services you sell through the Services, and transactions with your customers. This includes authorizing customer charges, 
                    refunds, returns, sales, customer service, fraudulent transactions, legal disclosures, regulatory compliance, alleged or actual violations of applicable laws, or breaches of these 
                    Terms of Service. You must ensure that your Store, Materials, and the goods and services you sell are accurate, complete, and in compliance with applicable laws, regulations, and 
                    third-party rights.
                  </li>
                  <li>
                    You are solely responsible for any goods or services you sell through the Services, including descriptions, prices, fees, taxes, defects, legal disclosures, regulatory compliance, 
                    offers, or promotional content, as well as compliance with any applicable laws or regulations. You may not use the Services for illegal or unauthorized purposes or violate any laws in 
                    your jurisdiction or your customers' jurisdiction.
                  </li>
                  <li>
                    You are responsible for determining, collecting, withholding, reporting, and remitting applicable taxes, duties, fees, surcharges, and additional charges related to your SmartSeller 
                    Store or use of the Services. We do not provide refunds.
                  </li>
                  <li>
                    The <Link to="/api-terms-of-use"> API Terms</Link> regulate your access to and utilization of the SmartSeller API, as specifically defined within those terms. You bear full responsibility for all 
                    activities performed using your API Credentials, as defined in the API Terms, and for ensuring the security of your API Credentials.
                  </li>
                  <li>
                    You agree to employing SmartSeller Checkout exclusively for any transactions linked to your online store. "SmartSeller Checkout" pertains to the checkout process offered by 
                    SmartSeller, enabling customers to input their shipping details and payment information after adding items to their cart and before finalizing their order. This includes checkouts 
                    facilitated through the SmartSeller Checkout API.
                  </li>
                </ol>
              </div>

              <div className='support-article-wrap mb-5' id='payment'>
                <h1 className='display-5 mb-4 fw-bold'>
                  6. Payment of Fees and Taxes
                </h1>
                <ol>
                  <li>
                    You are responsible for paying the fees associated with your Online Service subscription (<strong>"Subscription Fees"</strong>). Additionally, you must cover other relevant fees, 
                    including but not limited to charges related to the value of sales made through your Store when using payment providers other than SmartSeller Payments (<strong>"Transaction Fees"</strong>), 
                    as well as fees related to the purchase or use of products or services such as SmartSeller Payments, shipping, integrations, themes, domain names, or Third Party Services (<strong>"Additional Fees"</strong>). 
                    All these fees, which encompass Subscription Fees, Transaction Fees, and Additional Fees, are collectively termed as the <strong>"Fees"</strong>.
                  </li>
                  <li>
                    To fulfill your payment obligations, you must maintain a valid payment method on file with us. We will charge the applicable Fees to any valid payment method that you authorize (<strong>"Authorized Payment Method"</strong>). 
                    We will continue to charge the Authorized Payment Method for applicable Fees until the Services are terminated, and all outstanding Fees have been settled in full. Unless otherwise 
                    specified, all Fees and other charges are in U.S. dollars, and payments must be made in U.S. currency.
                  </li>
                  <li>
                    Subscription Fees are paid in advance and are billed at 30-day intervals (each such date, a <strong>"Billing Date"</strong>). Transaction Fees and Additional Fees will be charged at 
                    SmartSeller's discretion. On each Billing Date, you will be charged for any outstanding Fees that have not been previously billed. These Fees will be detailed on an invoice sent to the 
                    Store Owner via the provided Primary Email Address. Additionally, the invoice will be accessible on your Store's administrative console's Account page. Users have approximately two 
                    weeks to address and resolve any billing issues related to Subscription Fees.
                  </li>
                  <li>
                    In the event that we are unable to process payment of Fees using the Authorized Payment Method, we may make subsequent attempts to process payment using any other Authorized Payment 
                    Method. If we cannot successfully process payment within 28 days from the initial attempt, we reserve the right to suspend and revoke access to your Account and the Services. Your 
                    Account will be reactivated once you have paid any outstanding Fees, in addition to the Fees applicable to your next billing cycle. During any suspension period, you may not be able to 
                    access your Account or your storefront. If the outstanding Fees remain unpaid for 60 days following the suspension date, SmartSeller reserves the right to terminate your Account in 
                    accordance with Section 15.
                  </li>
                  <li>
                    All Fees are exclusive of applicable federal, provincial, state, local, or other governmental taxes, fees, or charges, including sales taxes, goods and services taxes, harmonized taxes, 
                    and other taxes currently in force or enacted in the future (<strong>"Taxes"</strong>).
                  </li>
                  <li>
                    You are responsible for all Taxes arising from your subscription to or purchase of SmartSeller's products and services. When SmartSeller charges Taxes, they are calculated based on the 
                    tax rates associated with the billing address you provide. These Tax amounts are in addition to the Fees for the respective products and services and will be billed to your Authorized 
                    Payment Method. If you qualify for a Tax exemption, you must provide evidence of your exemption, such as an original certificate satisfying applicable legal requirements for tax-exempt 
                    status in your jurisdiction. Tax exemption will apply from the date we receive satisfactory evidence of your exemption. If Taxes are not charged by SmartSeller, you are responsible for 
                    determining if Taxes are payable and, if so, self-remitting Taxes to the appropriate tax authorities in your jurisdiction.
                  </li>
                  <li>
                    To be clear, all payments to SmartSeller under these Terms of Service must be made without deductions or withholdings, except for Taxes charged by SmartSeller and remitted to tax 
                    authorities on your behalf. Any deductions or withholdings required by law are your responsibility, and you must pay them separately to the relevant tax authority. SmartSeller is 
                    entitled to charge the full amount of Fees as specified in these Terms of Service to your Authorized Payment Method, disregarding any required deductions or withholdings.
                  </li>
                  <li>
                    You are solely responsible for determining, collecting, withholding, reporting, and remitting any applicable taxes, duties, fees, surcharges, and additional charges resulting from any 
                    sale on your SmartSeller Store or your use of the Services. It's important to note that the Services do not operate as a marketplace, and any sales contracts made through the Services 
                    are directly between you and your customers.
                  </li>
                  <li>
                    You must maintain an accurate location in the administrative console of your SmartSeller Store. In the event of a jurisdiction change, you must promptly update your location in the 
                    administrative console.
                  </li>
                  <li>
                    SmartSeller does not offer refunds.
                </li>
                </ol>
              </div>

              <div className='support-article-wrap mb-5' id='confidentiality'>
                <h1 className='display-5 mb-4 fw-bold'>
                  7. Confidentiality
                </h1>
                <ol>
                  <li>
                    <strong>"Confidential Information"</strong> encompasses a wide array of non-public data pertaining to a party's business, including, but not limited to, specific business details, 
                    technical processes, software, customer records, potential customer lists, personal information such as names and addresses, product blueprints, financial information, business 
                    strategies, marketing data, and any other information that is confidential and proprietary, regardless of whether it is designated as such. SmartSeller's Confidential Information 
                    comprises all non-public information received by you related to us or our Services, particularly information regarding our security protocols and practices, which is not publicly 
                    known.
                  </li>
                  <li>
                    Each party agrees to using each other's Confidential Information exclusively for the purpose of fulfilling their obligations under these Terms of Service and in adherence to the 
                    other obligations outlined in this section. Each party agrees to take reasonable measures, at a minimum, equivalent to those taken to protect their own proprietary information, to 
                    prevent the duplication, disclosure, or use of such Confidential Information. Exceptions to this rule include:
                    <ol>
                      <li>
                        Disclosure to employees, agents, and subcontractors who require access to this Confidential Information to fulfill their responsibilities under these Terms. These individuals 
                        must treat the Confidential Information in accordance with the terms provided here and be subject to confidentiality obligations that are at least as strict as those detailed 
                        in these Terms.
                      </li>
                      <li>
                        Disclosure as required by any applicable law, regulation, or court order from a court with proper jurisdiction over the parties and the subject matter covered in these Terms 
                        of Service. In such cases, the receiving party will promptly inform the disclosing party and make commercially reasonable efforts to ensure that the disclosed information is 
                        treated with confidentiality.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Confidential Information does not cover any information that the receiving party can demonstrate:
                    <ul>
                      <li>Was already publicly known or in the possession of the receiving party at the time of disclosure</li>
                      <li>Was independently developed by the receiving party without using or referencing the other party's Confidential Information, without breaching any provisions of these 
                        Terms of Service</li>
                      <li>Was subsequently obtained rightfully by the receiving party from a source other than the disclosing party without violating any provision of these Terms of Service.</li>
                    </ul>
                  </li>
                </ol>
              </div>

              <div className='support-article-wrap mb-5' id='limitation'>
                <h1 className='display-5 mb-4 fw-bold'>
                  8. Limitation of Liability and Indemnification
                </h1>
                <ol>
                  <li>
                    You explicitly acknowledge and agree that, to the extent allowed by applicable laws, SmartSeller and its suppliers shall not be held responsible for any direct, indirect, incidental, 
                    special, consequential, or exemplary damages. This includes, but is not limited to, damages related to the loss of profits, goodwill, use, data, or other intangible losses stemming 
                    from the use of or inability to use the Service or these Terms of Service, regardless of the cause, including negligence.
                  </li>
                  <li>
                    You undertake to indemnify and protect us, and where applicable, our parent company, subsidiaries, affiliates, SmartSeller partners, officers, directors, agents, employees, and 
                    suppliers from any claims or demands, including reasonable legal fees, raised by any third party due to or arising from (a) your violation of these Terms of Service or the documents 
                    it refers to (including the AUP); (b) your infringement of any law or a third party's rights; or (c) any aspect of the transaction between you and your Customer, such as refunds, 
                    fraudulent transactions, alleged or actual violations of applicable laws (including but not limited to federal and state consumer protection laws), or your violation of the Terms of 
                    Service.
                  </li>
                  <li>
                    You will be held accountable for any breaches of the Terms of Service by your affiliates, agents, or subcontractors and will be liable as if these were your own violations.
                  </li>
                  <li>
                    Your utilization of the Services is entirely at your own risk. The Services are offered on an "as is" and "as available" basis, without any expressed, implied, or statutory warranties or conditions.
                  </li>
                  <li>
                    SmartSeller does not guarantee that the Services will be continuous, timely, secure, or free from errors.
                  </li>
                  <li>
                    SmartSeller does not guarantee that the results obtained from using the Services will be accurate or reliable.
                  </li>
                  <li>
                    SmartSeller bears no responsibility for any of your tax obligations or liabilities associated with the use of SmartSeller's Services.
                  </li>
                  <li>
                    SmartSeller does not assure that the quality of any products, services, information, or other materials obtained through the Services will meet your expectations, nor that any errors 
                    in the Services will be rectified.
                  </li>
                </ol>
              </div>              

              <div className='support-article-wrap mb-5' id='intellectual'>
                <h1 className='display-5 mb-4 fw-bold'>
                  9. Intellectual Property and Your Materials
                </h1>
                <div className='job-details-info my-4'>
                  <h3 className='h5'>
                    9.1 Your Materials
                  </h3>
                  <ol>
                    <li>
                      We do not assert ownership over the Materials you provide to SmartSeller; however, we do require a license to use those Materials. You grant SmartSeller a non-exclusive, 
                      transferable, sub-licensable, royalty-free, worldwide right and license to host, utilize, distribute, display, modify, execute, duplicate, store, publicly present, communicate 
                      to the public (including through telecommunication), broadcast, reproduce, make accessible, exhibit, and translate, as well as create derivative works from any Materials provided 
                      by you in connection with the Services. We may exercise our rights under this license to operate, provide, and promote the Services, as well as to fulfill our obligations and enforce 
                      our rights under the Terms of Service. You affirm, guarantee, and consent that you possess all the necessary rights to grant this license. You irrevocably renounce any and all moral 
                      rights you might have in the Materials in favor of SmartSeller, and you agree that this waiver may be invoked by anyone who acquires rights in the materials through SmartSeller, 
                      including anyone to whom SmartSeller may transfer or grant (including via license or sublicense) any rights in the Materials.                  
                    </li>
                    <li>
                      If you were the rightful owner of the Materials before providing them to SmartSeller, then, even after uploading them to your SmartSeller Store, they continue to be yours, subject 
                      to any rights or licenses granted in the Terms of Service or elsewhere. You can delete your SmartSeller Store at any time by closing your Account. Removing your SmartSeller 
                      Store does not nullify any rights or licenses granted to SmartSeller for the Materials that are necessary to exercise any rights or fulfill any obligations that arose during the 
                      Term.
                    </li>
                    <li>
                      You consent to SmartSeller's authority to review and, if deemed necessary, delete any or all of the Materials submitted to the Services, although SmartSeller is not obliged to do so.
                    </li>
                    <li>
                      You grant SmartSeller a non-exclusive, transferable, sub-licensable, royalty-free, worldwide right and license to use the names, trademarks, service marks and logos associated 
                      with your Store (<storng>"Your Trademarks"</storng>) to operate, provide, and promote the Services and to perform our obligations and exercise our rights under the Terms of Service. 
                      This license will survive any termination of the Terms of Service solely to the extent that SmartSeller requires the license to exercise any rights or perform any obligations that 
                      arose during the Term.
                    </li>
                  </ol>
                  <h3 className='h5'>
                    9.2 SmartSeller Intellectual Property
                  </h3>
                  <ol>
                    <li>
                      You may not utilize any trademarks, logos, or service marks associated with SmartSeller, whether they are registered or unregistered. This includes but is not limited to the word 
                      mark SMARTSELLER, the word mark SMARTSELLER PAY, and the <strong>"SmartSeller"</strong> light bulb design mark (<strong>"SmartSeller Trademarks"</strong>), unless you have obtained 
                      written authorization from SmartSeller.
                    </li>
                    <li>
                      You are prohibited from using or adopting any marks that could be perceived as confusing with the SmartSeller Trademarks.
                    </li>
                    <li>
                      Any variations or misspellings of the SmartSeller Trademarks are considered confusing with the SmartSeller Trademarks.
                    </li>
                    <li>
                      You are not allowed to acquire, register, or use search engine or other pay-per-click keywords (such as Google Ads), trademarks, email addresses, social media names, or domain names 
                      (including top-level domains, subdomains, and page URLs) that incorporate SmartSeller or SmartSeller Trademarks, or that contain terms that may cause confusion with the SmartSeller 
                      Trademarks.
                    </li>
                    <li>
                      It is important to note that the Terms of Service do not grant you the right to implement SmartSeller patents.
                    </li>
                  </ol> 
                </div>
              </div>

              <div className='support-article-wrap mb-5' id='additional'>
                <h1 className='display-5 mb-4 fw-bold'>
                  10. Additional Services
                </h1>
                <div className='job-details-info my-4'>
                  <h3 className='h5'>
                    10.1 SmartSeller Shipping
                  </h3>
                  <ol>
                    <li>
                      You have the option to acquire shipping labels through your SmartSeller administrative console (<strong>"SmartSeller Shipping"</strong>) for sending products bought from your 
                      SmartSeller Store. It's essential to be aware that apart from the Terms of Service mentioned here, your usage of SmartSeller Shipping is governed by the SmartSeller Shipping Terms 
                      of Service, accessible at https://www.sseller.io/shipping-terms-us.
                    </li>
                  </ol>
                  <h3 className='h5'>
                    10.2 SMS Messaging
                  </h3>
                  <ol>
                    <li>
                      The Services enable you to transmit specific communications to your customers via short message service (SMS) messaging, commonly referred to as the "SMS Services." It is crucial 
                      to ensure that your use of the SMS Services adheres to these Terms of Service, any relevant terms (including Nexmo Inc.'s <Link to="https://www.vonage.com/" target="_blank"> Acceptable 
                      Use Policy</Link> and Twilio's <Link to="https://www.twilio.com/en-us/legal/aup" target="_blank"> Acceptable Use Policy</Link>, subject to updates), as well as the laws of the jurisdiction 
                      from which you send messages and where your messages are received, to remain compliant.                  
                    </li>
                  </ol> 
                  <h3 className='h5'>
                    10.3 Theme Store
                  </h3>
                  <ol>
                    <li>                  
                      You have the option to customize the appearance of your SmartSeller Store using design templates available from SmartSeller's Theme Store, referred to as a "Theme." When you download 
                      a Theme, you are granted a license to use it exclusively for a single Store. In the event that you choose to close your first Store, you are allowed to transfer the Theme to a second 
                      Store of your own. To initiate the transfer of a Theme to a second Store, please get in touch with SmartSeller Support. It's important to note that you are not authorized to transfer 
                      or sell a Theme to another individual's Store, whether on SmartSeller or elsewhere. Each Store requires a separate download, and there may be applicable fees associated with each 
                      download. Please be aware that SmartSeller does not guarantee the continued availability of a specific Theme for additional downloads.
                    </li>
                    <li>
                      You are permitted to modify the Theme to align with your Store's unique requirements. SmartSeller retains the right to make additions or modifications to the Theme's footer that 
                      reference SmartSeller at its discretion. Additionally, SmartSeller may make changes to the Theme if, in its sole discretion, the Theme includes elements that violate the SmartSeller 
                      Acceptable Use Policy or other provisions outlined in the Terms of Service, even if you received the Theme in its initial condition. Technical adjustments and updates may also be 
                      made to the Theme as necessary.
                    </li>
                    <li>
                      It's important to recognize that the intellectual property rights of the Theme remain the property of the designer. If you exceed the rights granted through your Theme purchase, 
                      the designer may take legal action against you. Moreover, without prejudice to our other rights or remedies, SmartSeller may take administrative actions such as modifying or closing 
                      your Store.
                    </li>
                    <li>
                      For technical support related to a Theme, the responsibility lies with the designer, and SmartSeller does not assume responsibility for providing such support. However, 
                      SmartSeller may assist you in establishing contact with the designer.
                    </li>
                    <li>
                      Please be aware that it is your responsibility, and not SmartSeller's, to ensure that the installation of a new Theme does not overwrite or damage your current or preexisting 
                      theme or user interface.
                    </li>
                  </ol> 
                  <h3 className='h5'>
                    10.4 SmartSeller Email
                  </h3>
                  <p>
                    You are allowed to generate and send emails from your Account using the SmartSeller email services (the <strong>"Email Services"</strong>). In addition to the general terms 
                    applicable to the Services, such as SmartSeller's Acceptable Use Policy and Privacy Policy, the following terms pertain to your access and usage of the Email Services:
                  </p>
                  <ol>
                    <li>                  
                      SmartSeller employs measures to scan the content of emails sent via the Email Services before delivery (<strong>"Content Scanning"</strong>), with the aim of preventing spam, 
                      phishing, or other harmful content that violates these Terms of Service and SmartSeller's Acceptable Use Policy (collectively <strong>"Threats"</strong>). By using the Email 
                      Services, you explicitly grant SmartSeller the right to conduct this Content Scanning. Please note that SmartSeller does not guarantee that the Email Services will be entirely 
                      free from Threats, and each SmartSeller merchant is responsible for all content generated by their respective Stores.
                    </li>
                    <li>
                      BY CREATING OR SENDING EMAILS THROUGH THE EMAIL SERVICES, YOU AGREE TO ADHERE TO THE FOLLOWING REQUIREMENTS (THE <strong>"EMAIL SERVICES REQUIREMENTS"</strong>). FAILURE TO COMPLY
                      MAY RESULT IN THE SUSPENSION OR TERMINATION OF YOUR ACCESS TO THE EMAIL SERVICES BY SMARTSELLER OR ITES THIRD-PARTY PROVIDERS.
                      <ol>
                        <li>
                          Your use of the Email Services must conform to all relevant laws, including those pertaining to spam or unsolicited commercial email (<strong>"UCE"</strong>), privacy, security, 
                          obscenity, defamation, intellectual property, pornography, terrorism, homeland security, gambling, child protection, and other applicable laws. It is your responsibility to be 
                          aware of and comprehend the laws relevant to your use of the Email Services and the emails you generate or send through them.
                        </li>
                        <li>
                          Your use of the Email Services must align with SmartSeller's Privacy Policy. It is your responsibility to read and understand the Privacy Policy that pertains to your usage of 
                          the Email Services and the emails you create or send through them.
                        </li>
                        <li>
                          You must make reasonable efforts not to transmit sensitive personal data including medical information, racial or ethnic origin, political opinions, religious or philosophical 
                          beliefs, or other sensitive data, (collectively, <strong>"Sensitive Data"</strong>) through the Email Services. You are responsible for understanding your obligations concerning 
                          Sensitive Data.
                        </li>
                        <li>
                          Your use of the Email Services must adhere to all relevant guidelines established by SmartSeller. The following are examples of practices that may violate the Email Services 
                          Requirements when generating or sending emails through the Email Services:
                          <ol>
                            <li>
                              Using non-permission-based email lists (i.e., lists where recipients have not explicitly granted permission to receive emails by opting in).
                            </li>
                            <li>
                              Using purchased or rented email lists.
                            </li>
                            <li>
                              Using third-party email addresses, domain names, or mail servers without proper permission.
                            </li>
                            <li>
                              Sending emails to non-specific addresses (e.g., webmaster@domain.com or info@domain.com).
                            </li>
                            <li>
                              Sending emails that result in an excessive number of spam or unsolicited commercial email (UCE) complaints, even if the emails themselves are not spam or UCE.
                            </li>
                            <li>
                              Failing to include a functional "unsubscribe" link in each email that allows recipients to remove themselves from your mailing list.
                            </li>
                            <li>
                              Not complying with any request from a recipient to be removed from your mailing list within 10 days of receiving the request.
                            </li>
                            <li>
                              Failing to include in each email a link to the current privacy policy applicable to that email.
                            </li>
                            <li>
                              Disguising the origin or subject matter of any email, or falsifying or manipulating the originating email address, subject line, headers, or transmission path information 
                              for any email.
                            </li>
                            <li>
                              Not including your valid physical mailing address in each email or providing a link to that information.
                            </li>
                            <li>
                              Including content in emails that encourages recipients to forward the email to others, such as <strong>"junk mail," "chain letters," "pyramid schemes,"</strong> incentives 
                              (e.g., coupons, discounts, awards, or other incentives), or other materials.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      If you or a customer identifies or suspects violations of the Email Services Requirements, please notify SmartSeller at support@sseller.io. Compliance with the Email Services 
                      Requirements will be determined at the discretion of SmartSeller.
                    </li>
                    <li>
                      SmartSeller's Email Services make use of Third-Party Providers, including SendGrid (a Twilio Company). Your use of the Email Services is subject to SendGrid's 
                      <Link to="https://www.twilio.com/en-us/legal/aup" target="_blank"> Acceptable Use Policy</Link>, which may be updated by SendGrid from time to time.
                    </li>
                  </ol>                 
                  <h3 className='h5'>
                    10.5 Third Party Services
                  </h3>
                  <ol>
                    <li>     
                      At times, SmartSeller may suggest, grant access to, or enable third-party software, applications (<strong>"Apps"</strong>), products, services, or website links 
                      (collectively, <strong>"Third Party Services"</strong>) for your consideration or use, including through SmartSeller Integrations or Team Member Support. These Third 
                      Party Services are provided for your convenience, and any purchase, access, or use of such Third Party Services is a direct arrangement between you and the respective 
                      third-party service provider (<strong>"Third Party Provider"</strong>). In addition to these Terms of Service, you also agree to abide by the specific terms and conditions 
                      associated with services you acquire from or receive through Third Party Providers.
                    </li>
                    <li>
                      Team Member Support serves as a directory of SmartSeller Team Members (<strong>"Experts"</strong>) who are available to assist you in creating and managing your SmartSeller Store.
                    </li>
                    <li>
                      You have the option to directly collaborate and work with an Expert.
                    </li>
                    <li>
                      Your use of Third Party Services provided through the Services, SmartSeller Experts, or SmartSeller's website is entirely at your discretion and risk. It is your responsibility to 
                      review the terms and conditions and privacy policies associated with such Third Party Services before utilizing them. Additionally, in certain cases, SmartSeller may receive a revenue 
                      share from Third Party Providers recommended to you or engaged through your use of the Services, SmartSeller Experts, or SmartSeller's website.
                    </li>
                    <li>
                      We do not offer any warranties or provide representations concerning Third Party Services. You acknowledge that SmartSeller lacks control over Third Party Services and will not assume 
                      responsibility or liability for you or any other individuals using such Third Party Services. The presence of Third Party Services on SmartSeller's websites, including SmartSeller 
                      Integrations or Experts, or the integration and enabling of these services within the Services, does not imply or establish an endorsement, authorization, sponsorship, or affiliation 
                      with SmartSeller. SmartSeller does not guarantee the continuous availability of Third Party Services, and you acknowledge that SmartSeller may, at its sole discretion and without 
                      prior notice, deactivate access to any Third Party Service. SmartSeller is not accountable or liable to anyone for the discontinuation or suspension of access to, or the disabling of, 
                      any Third Party Service. We strongly advise you to seek specialized guidance before using or relying on Third Party Services to ensure they meet your specific requirements. In 
                      particular, it is essential to use tax calculators for reference purposes only and not as a replacement for independent tax advice when determining the appropriate tax rates to charge 
                      your customers.
                    </li>
                    <li>
                      If you install or enable a Third Party Service for use with the Services, you grant us permission to allow the applicable Third Party Provider to access your data and other Materials 
                      and to take any other actions as required for the interoperation of the Third Party Service with the Services, and any exchange of data or other Materials or other interaction between 
                      you and the Third Party Provider is solely between you and such Third Party Provider. SmartSeller is not responsible for any disclosure, modification or deletion of your data or other 
                      Materials, or for any corresponding losses or damages you may suffer, as a result of access by a Third Party Service or a Third Party Provider to your data or other Materials. Google 
                      Maps is a Third Party Service that is used within the Services. Your use of the Services is subject to your acceptance of the Google Maps and Earth Enterprise Universal Acceptable Use 
                      Policy, Google Maps and Google Earth Additional Terms of Service and Google Privacy Policy as it may be amended by Google from time to time.
                    </li>
                    <li>
                      You acknowledge and agree to the following:
                      <ol>
                        <li>
                          (i) When you submit a request for assistance or other information through Experts, you give your consent to be contacted by one or more Experts at the Primary Email Address 
                          (or any other email address provided by you), as well as the relevant user email address.
                        </li>
                        <li>
                          (ii) SmartSeller will receive all email communications exchanged through Experts or in any reply emails (each a <strong>"Reply"</strong>) originating from the Experts Marketplace, 
                          whether directly or indirectly involving you and the Experts.
                        </li>
                      </ol>
                      Furthermore, you consent to SmartSeller sharing your contact details and the background information you provide with the Experts. In some cases, Experts may require access to specific 
                      admin pages on your SmartSeller Store.
                    </li>
                    <li>
                      The relationship between you and any Third Party Provider is exclusively a matter between you and the respective Third Party Provider. SmartSeller is under no obligation to mediate 
                      in any disputes that may arise between you and a Third Party Provider.
                    </li>
                    <li>
                      In no event shall SmartSeller be held responsible for any form of damages, including direct, indirect, incidental, special, consequential, punitive, extraordinary, exemplary, or any 
                      other damages whatsoever, stemming from the use of Third Party Services or your contractual relationship with any Third Party Provider, including any Expert. These limitations remain 
                      in effect even if SmartSeller has been informed of the potential for such damages. These restrictions will be upheld to the maximum extent allowed by applicable law.
                    </li>
                    <li>
                      You agree to indemnify and protect us, along with our parent company, subsidiaries, affiliates, SmartSeller partners, officers, directors, agents, employees, and suppliers, from any 
                      claims or demands, which may include reasonable attorney's fees, resulting from your utilization of a Third Party Service or your association with a Third Party Provider.
                    </li>
                  </ol>                 
                  <h3 className='h5'>
                    10.6 Beta Services
                  </h3>
                  <p>
                    On occasion, at its own discretion, SmartSeller may extend an invitation to you for a trial period to test pre-release or beta features that are still under development and not yet 
                    accessible to all merchants (<strong>"Beta Services"</strong>). It's important to note that Beta Services are distinct from the regular Services and may be subject to additional terms 
                    and conditions, which SmartSeller will provide to you before you use the Beta Services. Conversations and any materials related to these Beta Services are considered SmartSeller 
                    Confidential Information and are subject to the confidentiality terms outlined in this agreement. To be more specific, you agree not to make any public statements or disclose your participation in the Beta Services without obtaining SmartSeller's prior written consent. SmartSeller 
                    does not guarantee that the Beta Services will perform as expected, and it retains the right to discontinue these services at any time at its sole discretion. SmartSeller bears no 
                    liability for any damages or issues arising from your use of a Beta Service. It's essential to understand that Beta Services may not operate in the same manner as a final version, and 
                    SmartSeller may decide to modify or not release a final or commercial version of a Beta Service at its discretion.
                  </p>
                  <h3 className='h5'>
                    10.7 SmartSeller Pixel Manager
                  </h3>
                  <p>
                    SmartSeller permits you to incorporate pixels into your SmartSeller Store, facilitating the tracking of customer events by either yourself or a third party through the 
                    "SmartSeller Pixel Manager." You can conveniently manage these pixels directly from the user interface within the administrative console of your SmartSeller Store.
                  </p>
                  <ol>
                    <li>
                      Apart from the general terms that apply to your use of the Services, including these Terms of Service, SmartSeller's Acceptable Use Policy, and Privacy Policy, the following 
                      conditions specifically pertain to your access and use of the SmartSeller Pixel Manager:
                      <ul>
                        <li>
                          You are responsible for compliance with all relevant laws and securing all requisite consents from every visitor and customer on your website whose activities you track 
                          through pixels.
                        </li>
                        <li>
                          Should you provide SmartSeller with any data collected through pixels, including personally identifiable information such as names, email addresses, phone numbers, or other 
                          personal data, you must obtain all necessary rights and consents before sharing such information with SmartSeller.
                        </li>
                        <li>
                          SmartSeller retains the right to deactivate any pixels that it identifies as malicious, at its sole discretion.
                        </li>
                        <li>
                          You are prohibited from using pixels or allowing any third parties to use them:
                          <ul>
                            <li>for engaging in or endorsing any unlawful, infringing, defamatory, or otherwise harmful activities; or</li>
                            <li>to disable, interfere with, or bypass any aspect of the Services.</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      SmartSeller may gather data related to the SmartSeller Pixel Manager, including details about pixel usage and the methods and content of scripts being added. This information can 
                      be utilized by SmartSeller to enhance, uphold, safeguard, and advance the SmartSeller Pixel Manager.
                    </li>
                  </ol>
                </div>
              </div>

              <div className='support-article-wrap mb-5' id='feedback'>
                <h1 className='display-5 mb-4 fw-bold'>
                  11. Feedback and Reviews
                </h1>
                <p>
                  SmartSeller values and encourages any ideas or suggestions for improving or expanding the Services. However, it's important to note that any disclosure of ideas, suggestions, or related 
                  materials, or any feedback provided on the Services, Third Party Services, or any Third Party Provider (collectively, <strong>"Feedback"</strong>) to SmartSeller will not be subject to 
                  confidentiality obligations or expectations of compensation. When you submit Feedback to SmartSeller, whether directly to SmartSeller or on any SmartSeller hosted forum or page, you 
                  relinquish all rights associated with that Feedback. SmartSeller is at liberty to implement and utilize the Feedback, as provided by you or as modified by SmartSeller, without requiring 
                  permission or a license from you or any third party. If you submit reviews regarding a Third Party Service or Third Party Provider to SmartSeller, they must be accurate to the best of 
                  your knowledge and must not be illegal, obscene, threatening, defamatory, invasive of privacy, infringing on intellectual property rights, or otherwise harmful to third parties or 
                  objectionable. SmartSeller retains the right, though not the obligation, to remove or modify Feedback related to Third Party Services or Third Party Providers. However, SmartSeller does 
                  not routinely monitor posted Feedback.
                </p>
              </div>

              <div className='support-article-wrap mb-5' id='dmca'>
                <h1 className='display-5 mb-4 fw-bold'>
                  12. DMCA Notice and Takedown Procedures
                </h1>
                <p>
                  SmartSeller upholds the importance of safeguarding intellectual property and expects our merchants to do the same. We have a policy in place to address all notifications of suspected 
                  copyright infringement. If an individual believes that one of our merchants is violating their intellectual property rights, they can send a DMCA Notice to SmartSeller's designated agent 
                  via email. Upon receipt of a DMCA Notice, we may take action, such as removing or disabling access to the Materials that are alleged to infringe on copyright. If a merchant disagrees with 
                  the complaint, they have the option to respond with a counter notification. Following the submission of a counter notification, the original complainant has a 14-business-day window to 
                  seek a court order that would restrain the merchant from continuing the alleged infringing activity. If no court order is sought within this time frame, we will reinstate the material.
                </p>
              </div>

              <div className='support-article-wrap mb-5' id='privacy'>
                <h1 className='display-5 mb-4 fw-bold'>
                  13. Privacy and Data Protection
                </h1>
                <ol>
                  <li>
                    SmartSeller places a strong emphasis on safeguarding the privacy of both your personal information and that of your customers. When you use our Service, you acknowledge and agree that 
                    the collection, utilization, and disclosure of this personal information by SmartSeller are regulated by our <Link to="/privacy-policy"> Privacy Policy</Link>.
                  </li>
                  <li>
                    In cases where SmartSeller processes the personal information of your customers as a "data processor" or "service provider" under specific data privacy or protection regulations, 
                    including the EU or UK General Data Protection Regulation and the California Consumer Privacy Act, our collection and use of personal information are additionally governed by our
                    <Link to="/data-processing-addendum"> Data Processing Addendum</Link>.
                  </li>
                </ol>
              </div>

              <div className='support-article-wrap mb-5' id='contracting-party'>
                <h1 className='display-5 mb-4 fw-bold'>
                  14. SmartSeller Contracting Party
                </h1>
                <ol>
                  <li>
                    The term "SmartSeller Contracting Party" refers to SmartSeller, Inc., a United States corporation, with its principal offices situated at 66 W Flagler St, 9th Floor, Miami, Florida, 
                    33130, United States.
                  </li>
                  <li>
                    The parties agree to submit, in an irrevocable and unconditional manner, to the exclusive jurisdiction of the State of Florida's courts concerning any dispute or claim that arises 
                    in connection with the Terms of Service. It is expressly stated that the United Nations Convention on Contracts for the International Sale of Goods does not apply to these Terms of 
                    Service and is hereby excluded.
                  </li>
                </ol>
              </div>

              <div className='support-article-wrap mb-5' id='term'>
                <h1 className='display-5 mb-4 fw-bold'>
                  15. Term and Termination
                </h1>
                <ol>
                  <li>
                    The duration of these Terms of Service will commence on the date of your completed registration for Service usage and will remain in effect until terminated by either party, as 
                    specified below (the <strong>"Term"</strong>).
                  </li>
                  <li>
                    You have the option to deactivate your Account and conclude the Terms of Service at your discretion. To do so, simply get in touch with SmartSeller Support via email at 
                    support@sseller.io and proceed with the specific instructions provided in SmartSeller's response.
                  </li>
                  <li>
                    In addition to other available remedies, we reserve the right to suspend or conclude your Account or the Terms of Service for any cause, without prior notice, and at our discretion 
                    (unless otherwise mandated by law). This includes situations where we have reason to believe, whether through conviction, settlement, insurance or escrow inquiry, or other means, that 
                    you have been involved in fraudulent activities in relation to the use of the Services. Termination of the Terms of Service will not affect any rights or responsibilities that were in 
                    effect prior to the termination date.
                  </li>
                  <li>
                    Upon the termination of the Services by either party, regardless of the cause:
                    <ol>
                      <li>SmartSeller will discontinue the provision of Services, and you will lose access to your Account.</li>
                      <li>Unless otherwise stipulated in the Terms of Service, you will not be eligible for any refunds of Fees, whether pro rata or otherwise.</li>
                      <li>Any outstanding balance that you owe to SmartSeller for your use of the Services up to the effective termination date will be immediately due and payable in full.</li>
                      <li>Your SmartSeller Store will be deactivated and no longer accessible online.</li>
                    </ol>
                  </li>
                  <li>
                    If you acquired a domain name via SmartSeller, upon cancellation, your domain will no longer undergo automatic renewal. Subsequent to termination, it becomes your exclusive 
                    responsibility to manage all aspects concerning your domain directly with the domain provider.
                  </li>
                  <li>
                    In the event that there are any unpaid Fees on your account at the time of Service termination, you will receive a final invoice by email. Upon settling this invoice in its entirety, 
                    no further charges will be incurred.
                  </li>
                </ol>
              </div>

              <div className='support-article-wrap mb-5' id='modifications'>
                <h1 className='display-5 mb-4 fw-bold'>
                  16. Modifications
                </h1>
                <ol>
                  <li>
                    We retain the right, at our sole and absolute discretion, to revise or modify any portion of the Terms of Service at any time. In cases where such changes substantially impact your 
                    use of the Services or your rights under the Terms of Service, we will offer reasonable advance notice. This notice will be provided through means such as an email sent to your Primary 
                    Email Address, notifications within the SmartSeller administrative console, or similar methods. However, SmartSeller may enact immediate changes that significantly affect your use of 
                    the Services or your rights under the Terms of Service under the following circumstances:
                    <ol>
                      <li>Legal, regulatory, fraud prevention, or security-related reasons.</li>
                      <li>To restrict products or activities that we consider unsafe, inappropriate, or offensive.</li>
                    </ol>
                    Unless stated otherwise in our notice (if applicable), any alterations to the Terms of Service will take effect immediately upon the posting of these updated terms at the specified 
                    location. Your continued use of the Services following the notice (if applicable) or the posting of updated terms indicates your acceptance of these changes and your consent to be 
                    bound by the amended Terms of Service. If you disagree with the revised Terms of Service, you must discontinue your use of the Services.
                  </li>
                  <li>
                    SmartSeller may also adjust the Fees for the Services periodically. To notify you of any Fee changes, we will provide you with a 30-day advanced notice through means such as an email 
                    sent to your Primary Email Account, notices within the SmartSeller administrative console, or similar methods. SmartSeller will not be held liable to you or any third party for 
                    modifications, price adjustments, suspension, or discontinuation of the Services, or any part thereof.
                  </li>
                </ol>
              </div>

              <div className='support-article-wrap mb-5' id='general'>
                <h1 className='display-5 mb-4 fw-bold'>
                  17. General Conditions
                </h1>
                <ol>
                  <li>
                    The Terms of Service, along with the documents it refers to, form the complete agreement governing your use of the Services and your Account between you and SmartSeller. This agreement 
                    supersedes any previous agreements between you and SmartSeller, which may include earlier versions of the Terms of Service.
                  </li>
                  <li>
                    SmartSeller's failure to exercise or enforce any right or provision outlined in the Terms of Service will not be interpreted as a waiver of that particular right or provision. In the 
                    event that a court of competent jurisdiction deems any part of the Terms of Service, which includes all terms, conditions, and referenced documents, to be in conflict with the law, that 
                    specific provision will be modified and construed in a manner that best fulfills the original intention of the provision within the limits allowed by the law. The remaining provisions 
                    of the Terms of Service will remain fully valid and effective.
                  </li>
                  <li>
                    Except for SmartSeller and its affiliates, no individual or entity, unless explicitly stated within these Terms of Service, shall possess the authority to enforce any provision outlined 
                    in these Terms of Service. This applies regardless of whether such an individual or entity has been specifically identified by name, as a member of a specific category, or described in 
                    a particular manner. It is important to note that this provision does not impede the rights of any authorized assignee or transferee of these Terms.
                  </li>
                  <li>
                    The Terms of Service shall be subject to and construed in accordance with the laws of the State of Florida and the United States, applicable in that jurisdiction, without consideration 
                    of conflict of laws principles.
                  </li>
                  <li>                  
                    The Terms of Service may be accessible in languages other than English. In the event of any discrepancies or conflicts between these English Terms of Service and SmartSeller's Terms of 
                    Service offered in another language, the latest English version of the Terms of Service, accessible at https://www.sseller.io/terms-of-service, shall take precedence. Any disputes 
                    arising from these Terms of Service will be addressed in English, unless otherwise decided by SmartSeller (exercising its sole discretion) or as mandated by relevant law.
                  </li>
                  <li>
                    All terms and provisions within the Terms of Service shall be binding on and advantageous to the parties involved in the Terms of Service, as well as their heirs, successors, 
                    authorized assigns, and legal representatives. SmartSeller may assign these Terms of Service without prior notice or your consent. You are not entitled to assign or transfer the Terms 
                    of Service, or any of your associated rights or obligations, to any third party without the prior written consent of SmartSeller, which shall be granted or withheld at SmartSeller's 
                    sole discretion.
                  </li>
                  <li>
                    In the event that any provision or a part thereof in these Terms of Service is determined to be invalid, illegal, or unenforceable in any manner, such determination shall not impact 
                    any other provision (or the unaffected part of the provision) of the Terms of Service. The Terms of Service will be interpreted as though the invalidated, illegal, or unenforceable 
                    provision, or portion of the provision, had never been included in the Terms of Service.
                  </li>
                  <li>
                    Upon termination, all associated rights and obligations under the Terms of Service will cease immediately. However, the following exceptions apply: (a) You will continue to be 
                    responsible for fulfilling your obligations related to transactions initiated prior to termination and any liabilities that arose before or as a result of termination. Additionally, 
                    Sections 2 (Account Terms), 6 (Payment of Fees), 7 (Confidentiality), 8 (Limitation of Liability and Indemnification), 9.1 (Intellectual Property and Your Materials), 10.5 (Third Party 
                    Services), 11 (Feedback and Reviews), 13 (Privacy and Data Protection), 14 (SmartSeller Contracting Party), 15 (Cancellation and Termination), 16 (Modifications), and 17 (General 
                    Conditions) will endure beyond the termination or expiration of these Terms of Service.
                  </li>
                </ol>
                <p>
                  <strong>SmartSeller, Inc.</strong><br></br>
                  ATTN: Chief Privacy Officer<br></br>
                  66 W Flagler St, 9th Floor,<br></br>
                  Miami, FL, 33130,<br></br>
                  United States<br></br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};


export default TermsOfUseDetails;
