import React from 'react';
import BlogGrid from '../../components/blog/BlogGrid';
import BlogHeader from '../../components/blog/BlogHeader';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const Blog = () => {
  return (
    <Layout>
      <PageMeta title="Blog | SmartSeller" />
      <Navbar navDark />
      <BlogHeader title="Latest Blog Posts"/>
      <BlogGrid />
      <FooterOne
        style={{
          background:
            "url('/assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default Blog;
