import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const AUPPolicyDefinitionsDetails = () => {
  return (
    <>
      <section className='support-content ptb-60'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-8 p-lg-8'>

              <div className='support-article-wrap mb-5'>
                <p>                
                  The following definitions (<strong>"AUP Definitions"</strong>) pertain to SmartSeller's <Link to="/aup-policy"> Acceptable Use Policy</Link> (<strong>"AUP"</strong>). SmartSeller 
                  retains the right to revise and modify the AUP Definitions as necessary.
                </p>
                <p id='materials'>
                  <strong>"Materials"</strong> means any photo, image, video, graphic, written content, audio file, code, information, data, or other content that is uploaded, collected, generated, stored, displayed, distributed, transmitted, or presented on or in relation to your Account.
                </p>
                <p id='restricted'>
                  <strong>"Restricted Items"</strong> means:
                </p>
                <p>
                  <strong>Certain Firearms</strong>
                </p>
                <ul>
                  <li>An automatic firearm that remains operable.</li>
                  <li>
                    A semi-automatic firearm capable of accepting a detachable magazine, equipped with any of the following:
                    <ul>
                      <li>A magazine capable of accepting more than 10 rounds.</li>
                      <li>Bump stock.</li>
                      <li>Rapid fire trigger activator or trigger crank.</li>
                      <li>Barrel shroud.</li>
                      <li>Thumbhole stock.</li>
                      <li>Threaded barrel capable of accepting a flash suppressor, sound suppressor, or silencer.</li>
                      <li>Grenade or rocket launcher.</li>
                      <li>Flash suppressor, sound suppressor, or silencer.</li>
                      <li>Pistol grip (or, in the case of a pistol, a second pistol grip).</li>
                      <li>Forward pistol grip.</li>                      
                    </ul>
                  </li>
                  <li>A semi-automatic firearm with a fixed magazine having the capacity to accept more than 10 rounds.</li>
                  <li>Firearms lacking serial numbers.</li>
                  <li>Ghost guns and 3D printed guns, including blueprints for such firearms.</li>
                  <li>Any part, component, or kit designed for any of the firearms or guns mentioned above.</li>
                </ul>
                <p>
                  <strong>Certain Firearm Parts</strong>
                </p>
                <ul>
                  <li>80% or unfinished lower receivers.</li>
                  <li>Magazine capable of accepting more than 10 rounds.</li>
                  <li>Bump stock.</li>
                  <li>Grenade or rocket launcher.</li>
                  <li>Pistol grip (or, in the case of a pistol, a second pistol grip).</li>
                  <li>Forward pistol grip.</li>
                  <li>Barrel shroud*.</li>
                  <li>Thumbhole stock*.</li>
                  <li>Threaded barrel capable of accepting a flash suppressor, sound suppressor, or silencer*.</li>
                  <li>Flash suppressor, sound suppressor, or silencer*.</li>
                  <li>Rapid fire trigger activator or trigger crank.</li>
                  <li>Any part, component, or kit related to a firearm part or inclusive of a firearm part mentioned above.</li>
                </ul>
                <p>
                  *only if intended for use with a semi-automatic firearm
                </p>
                <p>
                  "Services" is defined as per the description provided in the <Link to="/terms-of-service"> SmartSeller Terms of Service</Link>.
                </p>              
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};


export default AUPPolicyDefinitionsDetails;
