import React from 'react';
import ShippingTermsHeader from '../../components/shipping-terms-of-service/ShippingTermsHeader';
import PageMeta from '../../components/common/PageMeta';
import ShippingTermsDetails from '../../components/shipping-terms-of-service/ShippingTermsDetails';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const ShippingTerms = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar navDark />
      <ShippingTermsHeader />
      <ShippingTermsDetails />
      <FooterOne
        style={{
          background:
            "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default ShippingTerms;