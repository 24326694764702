import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PageMeta from '../../common/PageMeta.js';
import BlogPostHeader from '../BlogPostHeader.js';
import FooterOne from '../../../layout/Footer/FooterOne.js';
import Navbar from '../../../layout/Header/Navbar.js';
import Layout from '../../../layout/Layout.js';
import { blogPosts } from '../../../data/blogPosts.js'
import NeedStorefrontHelp from '../../cta/NeedStorefrontHelp.js';
import { Helmet } from 'react-helmet';


const BlogPost006 = () => {

  const slug = 'creating-a-toolkit-for-google-analytics'

  const blogPost = blogPosts.find(obj => {
    return obj.slug === slug
  })

  return (
    <React.Fragment>  

      <Layout>
        <PageMeta title={`${blogPost.title} | SmartSeller Blog`}/>
        <Helmet>
          {/* Essential META Tags */}
          <meta property="og:title" content={`${blogPost.title} | SmartSeller Blog`} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={`https://sseller.io${blogPost.images[0]}`} />
          <meta property="og:url" content={`https://sseller.io${blogPost.images[0]}`} />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Non-Essential, But Recommended */}
          <meta name="description" content={blogPost.excerpt} />
          <meta property="og:description" content={blogPost.excerpt} />
          <meta property="og:site_name" content="SmartSeller"/>
          <meta name="twitter:image:alt" content={`${blogPost.title} | SmartSeller Blog`} />
          {/* Non-Essential, But Required for Analytics */}
          {/* <meta property="fb:app_id" content="your_app_id" />
          <meta name="twitter:site" content="@website-username" /> */}
        </Helmet>
        <Navbar navDark posAbsolute />
        <BlogPostHeader title={blogPost.title} categories={blogPost.categories} readTime={blogPost.readTime} updatedDate={blogPost.updatedDateString}/>
        <section className='blog-details pt-60 pb-120'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-lg-8 pe-5'>
                <div className='blog-details-wrap'>
                  <img
                    src={blogPost.images[0]}
                    className='img-fluid mb-4 rounded-custom'
                    alt='apply'
                  />
                  <p>
                    Whether you're interested in a quick overview of statistics or aiming to delve into the intricacies of your social media strategy, delving into analytics can provide valuable 
                    insights into your website.
                  </p>
                  <p>
                    <Link to='https://analytics.withgoogle.com/' target='_blank'>Google Analytics</Link> stands out as the most commonly utilized and user-friendly web analytics tool. Its extensive range 
                    of options may appear overwhelming initially, with numerous sections and features laden with jargon and graphs. In this guide, we'll assist you in comprehending some fundamental 
                    tools and features of Google Analytics, enabling you to optimize your operations seamlessly.
                  </p>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='significant'>Why This Is Significant</h2>
                    <p>
                      Analytics play a crucial role because they provide answers to vital questions about the usage patterns of your online store: Which pages do visitors view? Where do they invest their 
                      time? What links capture their attention? How do they discover your site?
                    </p>
                    <p>
                      By addressing these inquiries and others like them, you gain valuable insights into your audience and their interaction with your site. Identifying the sources and searches that 
                      drive traffic helps you comprehend what resonates with your audience. Analyzing the pages they navigate to reveals the most enticing aspects of your offerings. Additionally, it 
                      may unveil potential hurdles or reasons for visitors not completing a purchase. Armed with this knowledge, you can streamline the user experience, emphasize popular content, and 
                      engage with both existing fans and potential customers.
                    </p>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='getting-started'>Getting Started With Google Analytics</h2>
                    <p>
                      Google Analytics might seem overwhelming due to its size, but don't be intimidated. Whether you're new to analytics or just need a quick refresher, we'll guide you through some 
                      essential terms to kickstart your journey. Let's begin by covering key terms you'll encounter in your Audience Overview.
                    </p>
                    <p>
                      <strong>User</strong> - This term is roughly equivalent to a person who has visited your site. Google stores a small file known as a cookie with each browser that accesses a site, 
                      containing an ID to identify when the same person returns.
                    </p>
                    <p>
                      <strong>Session</strong> - A session aims to capture a single instance of a person viewing your site. If your website were a physical store, a session would initiate when a person 
                      enters and conclude when they exit. Just as someone might browse various items in a store, they could explore multiple pages on your site during a session. Determining this from 
                      internet activity is a bit more complex, so a session typically concludes after a set period since the user clicked a link within your site or when they click a link redirecting them 
                      away from your site.
                    </p>
                    <p>
                      <strong>Bounce</strong> - This occurs when a person visits a page on your site, doesn't interact with anything on the page, and then leaves.
                    </p>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='charts'>Deciphering The Charts</h2>
                    <p>
                      Ever wondered about those bewildering charts on this page? All those numbers and graphs are dedicated to analyzing your traffic over a specific date range. By default, this range is 
                      set to the last seven days, and that's what we'll delve into.
                    </p>
                    <p>
                      You can easily spot the current date range or opt for a different one from the dropdown in the upper right corner.
                    </p>
                    <p>
                      The primary chart takes the form of a line graph illustrating sessions per day.
                    </p>
                    <p>
                      Consider this as a tally of daily entries into your store. Observe fluctuations across different days of the week or pinpoint spikes on specific days. What can you glean from this 
                      data? Did that Instagram post attract a surge of new visitors? Is there a tendency for fewer visits on Mondays?
                    </p>
                    <p>
                      Beneath the primary chart, you'll encounter seven smaller line graphs on the left.
                    </p>
                    <p>
                      These compact line graphs depict the weekly totals for each metric, offering a quick overview of your site's performance.
                    </p>
                    <p>
                      <strong>Sessions</strong> consolidates daily sessions into a cumulative total.
                    </p>
                    <p>
                      <strong>Users</strong> will always be less than (or equal to) sessions since a single individual may visit your site multiple times—a positive trend!                    
                    </p>
                    <p>
                      <strong>Pageviews</strong> will always be more than (or equal to) sessions, as a user may view multiple pages in a session—an encouraging sign!                      
                    </p>
                    <p>
                      <strong>Pages/Session</strong> endeavors to gauge user engagement by indicating the average number of pages viewed during a visit.                      
                    </p>
                    <p>
                      <strong>Average Session Duration</strong> serves as a metric for engagement—how long visitors spend on your site.
                    </p>
                    <p>
                      <strong>Bounce Rate</strong> is an instance where lower is better, signifying that users are exploring your site rather than leaving immediately.                      
                    </p>
                    <p>
                      <strong>% New Sessions</strong> identifies users who have never visited your site before, providing insights into recent discoveries. Remember, Google tracks this 
                      information in a little cookie file. Adjacent to these graphs, you'll find a pie chart.                      
                    </p>
                    <p>
                      This chart delineates the division between <strong>Returning Visitors</strong> and <strong>New Visitors</strong>. Returning Visitors have frequented your site before, while New 
                      Visitors are exploring it for the first time this week. This breakdown informs you about the influx of new visitors. If you've been featured in a blog or executed a successful 
                      social media campaign, the New Visitors percentage will likely increase—celebrate those achievements!
                    </p>
                  </div>                                    
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='pause'>Take A Moment</h2>
                    <p>
                      We've covered quite a bit, and there's so much more we could explore. However, establishing a solid understanding of the basics will serve as a foundation for deeper insights.
                    </p>
                    <p>
                      To be honest, Google attempts to address numerous common questions right here in this overview. On many days, this might be the only page you need to consult to gauge your site's 
                      performance. Still, it's a substantial amount of information to grasp. The positive aspect is that, when you're prepared, Google Analytics enables you to find answers to queries 
                      like:
                    </p>
                    <ul>
                      <li>
                        How do visitors navigate to a specific page on your site?
                      </li>
                      <li>
                        From which countries do your users originate?
                      </li>
                      <li>
                        Which pages capture the most user attention?
                      </li>
                      <li>
                        How does this month's performance compare to the previous month?
                      </li>
                    </ul>
                    <p>
                      When you're ready to delve deeper, explore <Link to='https://www.youtube.com/googleanalytics' target='_blank'>Google Analytics' YouTube page</Link> for useful tutorials, learn how 
                      to leverage Google Analytics to understand user acquisition, and read our <Link to='/blog-post/an-introduction-into-search-engine-optimization'>introduction into search engine optimization</Link> 
                      to implement these insights. But before diving into more, take a moment to commend yourself for investing time in understanding your customers and how they perceive your shop. 
                    </p>                 
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='author-wrap  bg-light p-5 sticky-sidebar rounded-custom mt-5 mt-lg-0'>
                  <div className='mb-4 text-left'>
                    <div className='author-info'>
                      <h5 className='mb-0'>In This Post</h5>
                    </div>
                    <ul>
                      {blogPost.menu.map((data) => (
                        <li key={`sidebar-${blogPost.id}-${data}`}>
                          <HashLink to={`#${data.id}`} className='text-muted text-decoration-none py-1 d-block'>{data.label}</HashLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <hr className='mb-4'></hr>
                  <div className='text-center'>
                    <img
                      src={blogPost.author.image}
                      alt='author'
                      width='100'
                      className='img-fluid shadow-sm rounded-circle mb-4'
                    />
                    <div className='author-info'>
                      <h5 className='mb-0'>{blogPost.author.name}</h5>
                      <span className='small'>{blogPost.author.title}</span>
                    </div>
                    <ul className='list-unstyled author-social-list list-inline mb-0'>
                      {
                        blogPost.author.social.facebook 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.facebook} target='_blank'>
                                  <i className='fab fa-facebook'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.instagram
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.instagram} target='_blank'>
                                  <i className='fab fa-instagram'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.linkedIn 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.linkedIn} target='_blank'>
                                  <i className='fab fa-linkedin-in'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.gitHub 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.gitHub} target='_blank'>
                                  <i className='fab fa-github'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.gitLab
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.gitLab} target='_blank'>
                                  <i className='fab fa-gitlab'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.twitter
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.twitter} target='_blank'>
                                  <i className='fab fa-twitter'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NeedStorefrontHelp />
        <FooterOne
          style={{
            background:
              "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
          }}
        />
      </Layout>
    </React.Fragment>
  );
};

export default BlogPost006;