import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import GetStartedForm from '../../components/contact/GetStartedForm';
import GetStartedContent from '../get-started/GetStartedContent';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const GetStarted = () => {
  return (
    <Layout>
      <PageMeta title='Get started with SmartSeller' />
      <Navbar navDark posAbsolute />
      <section
        className='sign-up-in-section bg-dark ptb-120'
        style={{
          background:
            "url('/assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <GetStartedForm />
            <GetStartedContent reqPage/>
          </div>
        </div>
      </section>
      <FooterOne />
    </Layout>
  );
};

export default GetStarted;
