import React, { Component } from 'react';
import SectionTitle from '../common/SectionTitle';
import TestimonialModal from '../testimonial/TestimonialModal';
import Rating from '../review/Rating';

class CustomerStories extends Component {
  render() {
    const { darkBg } = this.props;
    return (
      <>
        <section
          // className={`customer-review-tab ptb-120 ${
          //   darkBg ? 'bg-gradient text-white' : 'bg-light'
          // } position-relative z-2`}
          className={`customer-review-tab pt-100 pb-120 position-relative z-2`}
        >
          <div className='container'>
            <div className='row justify-content-center align-content-center'>
              <div className='col-md-10 col-lg-8'>
                {darkBg ? (
                  <SectionTitle
                    subtitle=''
                    title='See how SmartSeller companies become star stories.'
                    description='Learn how companies of every type are operating in a whole new way.'
                    dark
                    centerAlign
                  />
                ) : (
                  <SectionTitle
                    subtitle=''
                    title='See how SmartSeller companies become star stories.'
                    description='Learn how companies of every type are operating in a whole new way.'
                    centerAlign
                  />
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='tab-content' id='testimonial-tabContent'>

                  <div
                    className='tab-pane fade active show'
                    id='testimonial-tab-2'
                    role='tabpanel'
                  >
                    <div className='row align-items-center justify-content-between'>
                      <div className='col-lg-6 col-md-6'>
                        <div className='testimonial-tab-content mb-5 mb-lg-0 mb-md-0'>
                          <img
                            src='/assets/img/testimonial/quotes-left.svg'
                            alt='testimonial quote'
                            width='65'
                            className='img-fluid mb-32'
                          />
                          <div className='blockquote-title-review mb-4'>
                            <h3 className='mb-0 h4 fw-semi-bold'>
                              It completely changed the way we work!
                            </h3>
                            <Rating />
                          </div>
                          <blockquote className='blockquote'>
                            <p>
                              We went from managing data across several spreadsheets 
                              to using just one platform for all our operations.
                              This let us re-focus our labor efforts on revenue
                              generating activities.
                            </p>
                          </blockquote>
                          <div className='author-info mt-4'>
                            <h6 className='mb-0'>MercadoEUA</h6>
                            <span>Lucas de Castro, Product Manager</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-6'>
                        <div className='author-img-wrap pt-5 ps-5'>
                          <div className='position-relative'>
                            <img
                              src='/assets/img/testimonial/mercadoeua.png'
                              className='img-fluid rounded-custom shadow-lg'
                              alt='testimonial author'
                            />
                            {/* <TestimonialModal /> */}
                            <div className='position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className='tab-pane fade'
                    id='testimonial-tab-3'
                    role='tabpanel'
                  >
                    <div className='row align-items-center justify-content-between'>
                      <div className='col-lg-6 col-md-6'>
                        <div className='testimonial-tab-content mb-5 mb-lg-0 mb-md-0'>
                          <img
                            src='/assets/img/testimonial/quotes-left.svg'
                            alt='testimonial quote'
                            width='65'
                            className='img-fluid mb-32'
                          />
                          <div className='blockquote-title-review mb-4'>
                            <h3 className='mb-0 h4 fw-semi-bold'>
                              Fulfilling orders is now a breeze!
                            </h3>
                            <Rating />
                          </div>
                          <blockquote className='blockquote'>
                            <p>
                              SmartSeller helped us optimize our order fulfillment.
                              Our team is able to pick and pack orders then print shipping
                              labels with ease. Our handling time is cut in half.
                            </p>
                          </blockquote>
                          <div className='author-info mt-4'>
                            <h6 className='mb-0'>Nutrewish</h6>
                            <span>Ed Guerra, Business Development</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-6'>
                        <div className='author-img-wrap pt-5 ps-5'>
                          <div className='position-relative'>
                            <img
                              src='/assets/img/testimonial/nutrewish.jpg'
                              className='img-fluid rounded-custom shadow-lg'
                              alt='testimonial author'
                            />
                            <div className='position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className='tab-pane fade'
                    id='testimonial-tab-1'
                    role='tabpanel'
                  >
                    <div className='row align-items-center justify-content-between'>
                      <div className='col-lg-6 col-md-6'>
                        <div className='testimonial-tab-content mb-5 mb-lg-0 mb-md-0'>
                          <img
                            src='/assets/img/testimonial/quotes-left.svg'
                            alt='testimonial quote'
                            width='65'
                            className='img-fluid mb-32'
                          />
                          <div className='blockquote-title-review mb-4'>
                            <h3 className='mb-0 h4 fw-semi-bold'>
                              Expanding to global markets is so easy!
                            </h3>
                            <Rating />
                          </div>
                          <blockquote className='blockquote'>
                            <p>
                              We love how easy it is to manage our listings.
                              We simply add product data and SmartSeller 
                              calculates listing prices and publishes data
                              across our sales channels. We went from
                              selling in one market to selling globally.
                            </p>
                          </blockquote>
                          <div className='author-info mt-4'>
                            <h6 className='mb-0'>BraBox Megastore</h6>
                            <span>Raymond Wells, Operations Manager</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-6'>
                        <div className='author-img-wrap pt-5 ps-5'>
                          {/* <div className='testimonial-video-wrapper position-relative'> */}
                          <div className='position-relative'>
                            <img
                              src='/assets/img/testimonial/brabox_megastore.png'
                              className='img-fluid rounded-custom shadow-lg'
                              alt='testimonial author'
                            />
                            {/* <TestimonialModal /> */}
                            <div className='position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className='tab-pane fade'
                    id='testimonial-tab-4'
                    role='tabpanel'
                  >
                    <div className='row align-items-center justify-content-between'>
                      <div className='col-lg-6 col-md-6'>
                        <div className='testimonial-tab-content mb-5 mb-lg-0 mb-md-0'>
                          <img
                            src='/assets/img/testimonial/quotes-left.svg'
                            alt='testimonial quote'
                            width='65'
                            className='img-fluid mb-32'
                          />
                          <div className='blockquote-title-review mb-4'>
                            <h3 className='mb-0 h4 fw-semi-bold'>
                              Best Template for SAAS Company!
                            </h3>
                            <Rating />
                          </div>
                          <blockquote className='blockquote'>
                            <p>
                              Competently repurpose cost effective strategic
                              theme areas and customer directed meta-services.
                              Objectively orchestrate orthogonal initiatives
                              after enterprise-wide bandwidth. Dynamically
                              generate extensive through cooperative channels
                              time partnerships.{' '}
                            </p>
                          </blockquote>
                          <div className='author-info mt-4'>
                            <h6 className='mb-0'>Hanry Luice</h6>
                            <span>App Developer</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-6'>
                        <div className='author-img-wrap pt-5 ps-5'>
                          <div className='testimonial-video-wrapper position-relative'>
                            <img
                              src='/assets/img/testimonial/t-4.jpg'
                              className='img-fluid rounded-custom shadow-lg'
                              alt='testimonial author'
                            />
                            <TestimonialModal />
                            <div className='position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div
                    className='tab-pane fade'
                    id='testimonial-tab-5'
                    role='tabpanel'
                  >
                    <div className='row align-items-center justify-content-between'>
                      <div className='col-lg-6 col-md-6'>
                        <div className='testimonial-tab-content mb-5 mb-lg-0 mb-md-0'>
                          <img
                            src='/assets/img/testimonial/quotes-left.svg'
                            alt='testimonial quote'
                            width='65'
                            className='img-fluid mb-32'
                          />
                          <div className='blockquote-title-review mb-4'>
                            <h3 className='mb-0 h4 fw-semi-bold'>
                              It is Undeniably Good!
                            </h3>
                            <Rating />
                          </div>
                          <blockquote className='blockquote'>
                            <p>
                              Appropriately disintermediate one-to-one vortals
                              through cross functional infomediaries.
                              Collaboratively pursue multidisciplinary systems
                              through stand-alone architectures. Progressively
                              transition covalent architectures whereas vertical
                              applications procrastinate professional.
                            </p>
                          </blockquote>
                          <div className='author-info mt-4'>
                            <h6 className='mb-0'>Ami Nijai</h6>
                            <span>Customer Support</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-6'>
                        <div className='author-img-wrap pt-5 ps-5'>
                          <div className='testimonial-video-wrapper position-relative'>
                            <img
                              src='/assets/img/testimonial/t-5.jpg'
                              className='img-fluid rounded-custom shadow-lg'
                              alt='testimonial author'
                            />
                            <TestimonialModal />
                            <div className='position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>*/}

                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <ul
                  className='nav nav-pills testimonial-tab-menu mt-60'
                  id='testimonial'
                  role='tablist'
                >
                  <li className='nav-item' role='presentation'>
                    <div
                      className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link active'
                      data-bs-toggle='pill'
                      data-bs-target='#testimonial-tab-2'
                      role='tab'
                      aria-selected='false'
                    >
                      <div className='testimonial-thumb me-3'>
                        <img
                          src='/assets/img/brand-logo/mercadoeua.jpg'
                          width='100'
                          className='rounded-circle'
                          alt='MercadoEUA logo'
                        />
                      </div>
                      <div className='author-info'>
                        <h6 className='mb-0'>MercadoEUA</h6>
                        <span>Discount Retailer</span>
                      </div>
                    </div>
                  </li>

                  <li className='nav-item' role='presentation'>
                    <div
                      className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link'
                      data-bs-toggle='pill'
                      data-bs-target='#testimonial-tab-3'
                      role='tab'
                      aria-selected='false'
                    >
                      <div className='testimonial-thumb me-3'>
                        <img
                          src='/assets/img/brand-logo/nutrewish.png'
                          width='100'
                          className='rounded-circle'
                          alt='Nutrewish logo'
                        />
                      </div>
                      <div className='author-info'>
                        <h6 className='mb-0'>Nutrewish</h6>
                        <span>Health Products Retailer</span>
                      </div>
                    </div>
                  </li>

                  <li className='nav-item' role='presentation'>
                    <div
                      className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link'
                      data-bs-toggle='pill'
                      data-bs-target='#testimonial-tab-1'
                      role='tab'
                      aria-selected='false'
                    >
                      <div className='testimonial-thumb me-3'>
                        <img
                          src='/assets/img/brand-logo/brabox.png'
                          width='100'
                          className='rounded-circle '
                          alt='BraBox Megastore logo'
                        />
                      </div>
                      <div className='author-info'>
                        <h6 className='mb-0'>BraBox Megastore</h6>
                        <span>Beauty Retailer</span>
                      </div>
                    </div>
                  </li>

                  {/* <li className='nav-item' role='presentation'>
                    <div
                      className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link'
                      data-bs-toggle='pill'
                      data-bs-target='#testimonial-tab-4'
                      role='tab'
                      aria-selected='false'
                    >
                      <div className='testimonial-thumb me-3'>
                        <img
                          src='/assets/img/testimonial/4.jpg'
                          width='50'
                          className='rounded-circle'
                          alt='testimonial thumb'
                        />
                      </div>
                      <div className='author-info'>
                        <h6 className='mb-0'>Hanry Luice</h6>
                        <span>App Developer</span>
                      </div>
                    </div>
                  </li> */}

                  {/* <li className='nav-item' role='presentation'>
                    <div
                      className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link'
                      data-bs-toggle='pill'
                      data-bs-target='#testimonial-tab-5'
                      role='tab'
                      aria-selected='true'
                    >
                      <div className='testimonial-thumb me-3'>
                        <img
                          src='/assets/img/testimonial/5.jpg'
                          width='50'
                          className='rounded-circle'
                          alt='testimonial thumb'
                        />
                      </div>
                      <div className='author-info'>
                        <h6 className='mb-0'>Ami Nijai</h6>
                        <span>Customer Support</span>
                      </div>
                    </div>
                  </li> */}

                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default CustomerStories;
