import React from 'react';
import { useParams } from 'react-router-dom';
import BlogDetails from '../../components/blog/BlogDetails';
import PageMeta from '../../components/common/PageMeta';
import NewsLetter from '../../components/cta/NewsLetterOne';
import BlogPostHeader from '../../components/blog/BlogPostHeader';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import LatestBlog from '../../components/blog/LatestBlog';
import CtaTwo from '../../components/cta/CtaTwo';
import { blogPosts } from '../../data/blogPosts.js';


const BlogPost = (props) => {

  console.log('blogPosts')
  console.log(blogPosts)


  const { id } = useParams();

  console.log(`id: ${id}`)

  return (
    <Layout>
      <PageMeta title="Blog Details - Software &amp; IT Solutions HTML Template" />
      <Navbar navDark posAbsolute />
      {/* <BlogPostHeader title="Google Analytics" /> */}
      <BlogDetails />
      <NewsLetter />
      {/* <LatestBlog /> */}
      <FooterOne
        style={{
          background:
            "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />

    </Layout>
  );
};

export default BlogPost;
