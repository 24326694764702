import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const PrivacyPolicyDetails = () => {
  return (
    <>
      <section className='support-content ptb-60'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-4 col-md-4 d-none d-md-block d-lg-block'>
              <div className='support-article-sidebar sticky-sidebar'>
                <div className='nav flex-column nav-pills support-article-tab bg-light rounded-custom p-5'>
                  {/* <h5>Related Support Articles</h5> */}
                  <HashLink to='#introduction' className='text-muted text-decoration-none py-2 d-block'>Introduction</HashLink>
                  <HashLink to='#our-values' className='text-muted text-decoration-none py-2 d-block'>Our Values</HashLink>
                  <HashLink to='#why' className='text-muted text-decoration-none py-2 d-block'>Why We Process Your Information</HashLink>
                  <HashLink to='#your-rights' className='text-muted text-decoration-none py-2 d-block'>Your Rights Over Your Information</HashLink>
                  <HashLink to='#where' className='text-muted text-decoration-none py-2 d-block'>Where We Send Your Information</HashLink>
                  <HashLink to='#how-long' className='text-muted text-decoration-none py-2 d-block'>How Long Do We Retain Your Information</HashLink>
                  <HashLink to='#protect' className='text-muted text-decoration-none py-2 d-block'>How We Protect Your Information</HashLink>
                  <HashLink to='#track' className='text-muted text-decoration-none py-2 d-block'>"Cookies" and Tracking Technologies</HashLink>
                  <HashLink to='#contact' className='text-muted text-decoration-none py-2 d-block'>How You Can Contact Us</HashLink>
                  <HashLink to='#us' className='text-muted text-decoration-none py-2 d-block'>United States Privacy Notice</HashLink>
                </div>
              </div>
            </div>
            <div className='col-lg-8 col-md-8 p-lg-5'>

              <div className='support-article-wrap mb-5' id='introduction'>
                <h1 className='display-5 mb-4 fw-bold'>
                  Introduction
                </h1>
                <p>At SmartSeller, our mission is to enhance commerce for all by collecting and utilizing information about various parties, which includes:</p>
                <div className='job-details-info'>
                  <ul className='content-list list-unstyled'>
                    <li>Merchants who utilize SmartSeller to power their businesses</li>
                    <li>Customers who shop at businesses powered by SmartSeller</li>
                    <li>Partners who build storefronts for merchants, refer potential leads to SmartSeller, or otherwise aid merchants in managing their SmartSeller-powered businesses</li>
                    <li>Visitors to SmartSeller's websites and individuals who contact SmartSeller support</li>
                  </ul>
                </div>
                <p>
                  This Privacy Policy will help you better understand how we collect, use, and share your personal information. We update our
                  privacy policy periodically and without prior notice to you. We will post a prominent notice on our website, or email you, 
                  to notify you of any significant changes to our Privacy Policy.
                </p>
              </div>

              <div className='support-article-wrap mb-5' id='our-values'>
                <h1 className='display-5 mb-4 fw-bold'>
                  Our Values
                </h1>
                <p>
                  Transparency is the cornerstone of the SmartSeller platform, emphasizing trust in our handling of your information. Three core values guide us as we develop our products and 
                  services to ensure your privacy:
                </p>
                <div className='job-details-info my-4'>
                  <h3 className='h5'>
                    <i className='fa-solid fa-clipboard-user me-2'></i>
                    Your information is yours
                  </h3>
                  <p>                  
                    We conscientiously assess the necessity of the information we collect to provide our services, striving to collect only essential data. We delete or anonymize information when 
                    it is no longer required. We work closely with our privacy and security teams to build privacy into our products, ensuring your information is used for your benefit.
                  </p>
                  <h3 className='h5'>
                    <i className='fa-solid fa-shield me-2'></i>
                    We safeguard your information
                  </h3>
                  <p>                  
                    We do not share your personal information with third parties unless you have given us permission or when legally required. In cases of legal obligations, we will inform you in 
                    advance, unless legally prohibited.
                  </p>
                  <h3 className='h5'>
                    <i className='fa-solid fa-rectangle-list me-2'></i>
                    Assisting merchants and partners with privacy obligations:
                  </h3>
                  <p>                  
                    Many merchants and partners using SmartSeller may not have dedicated privacy teams. We aim to help them meet their privacy requirements by building products and services that 
                    can be used in a privacy-friendly manner. We also provide comprehensive FAQs and documentation on important privacy topics.
                  </p>
                </div>
              </div>

              <div className='support-article-wrap mb-5' id='why'>
                <h1 className='display-5 mb-4 fw-bold'>
                  Why We Process Your Information
                </h1>
                <p>
                  We process your information when necessary to fulfill contractual obligations, such as processing subscription payments for the SmartSeller platform or providing shipping services. 
                  These reasons are known as "legitimate interests" in the European Economic Area ("EEA") and the United Kingdom ("UK"). These interests encompass:
                </p>                  
                <div className='job-details-info'>
                  <ul className='content-list list-unstyled'>
                    <li>Preventing risk and fraud</li>
                    <li>Providing support and answering questions</li>
                    <li>Assisting merchants in finding service partners</li>
                    <li>Enhancing our products and services</li>
                    <li>Providing reporting and analytics</li>
                    <li>Testing features or additional services</li>
                    <li>Aiding with marketing, advertising, and communications</li>
                  </ul>
                </div>
                <p>
                  We process personal information for these "legitimate interests" while considering potential privacy risks and implementing measures to protect your information.
                </p>
                <p>
                  We may also process your personal information with your consent, in cases where no alternative legal basis applies, when you direct us to transfer information to a third party, 
                  when we receive data from a third party with consent, or when required by law. You can withdraw your consent at any time.
                </p>
                <p>
                  Depending on your role (merchant, customer, partner, user, or visitor), refer to our supplemental privacy policies to understand the specific purposes, categories of recipients, 
                  and legal bases for processing different types of personal data.
                </p>
              </div>

              <div className='support-article-wrap mb-5' id='your-rights'>
                <h1 className='display-5 mb-4 fw-bold'>
                  Your Rights Over Your Information
                </h1>
                <p>
                  We believe you should have access to and control over your personal information, regardless of your location. Depending on your use of SmartSeller, you may have the right to request 
                  access, correction, amendment, deletion, portability, restriction, or objection to certain uses of your personal information. We will not charge you differently for exercising these 
                  rights. Note that certain rights may be limited by law.
                </p>
                <p>
                  If you've interacted with a SmartSeller-powered store and want to exercise these rights regarding your purchase or interaction, you should contact the specific merchant, as we act as 
                  a processor on their behalf. We will assist merchants in fulfilling these requests as required by law.
                </p>
                <p>
                  If you have a direct relationship with SmartSeller as a merchant, partner, SmartSeller employee, website visitor, or other individual, submit your data subject request by email to 
                  support@sseller.io. We may verify your identity using a third party if necessary. You also have the right to lodge a complaint with your local data protection or privacy authority.
                </p>
                <p>
                  Please note that we do not respond to <Link to="https://en.wikipedia.org/wiki/Do_Not_Track" target="_blank"> “Do Not Track”</Link> signals, as there is no common understanding of their 
                  intended meaning.
                </p>
              </div>

              <div className='support-article-wrap mb-5' id='where'>
                <h1 className='display-5 mb-4 fw-bold'>
                  Where We Send Your Information
                </h1>
                <p>
                  While we are based in the United States, we work with and process data about individuals worldwide. To conduct our business, we may transfer your personal information outside your 
                  state, province, or country. We take steps to protect your information when transferring it across borders.
                </p>
                <p>
                  For specific details based on your role, refer to our supplemental privacy policies.
                </p>
                <h3 className='h5'>
                  Transfers Outside of Europe and Switzerland
                </h3>
                <p>            
                  If you are in the EEA, the UK, or Switzerland, your personal information is controlled by SmartSeller, Inc. in the United States. Your information may be sent to service providers 
                  located in other regions. We adhere to applicable laws when transferring your personal information outside the EEA, UK, or Switzerland.
                </p>
              </div>

              <div className='support-article-wrap mb-5' id='how-long'>
                <h1 className='display-5 mb-4 fw-bold'>
                  How Long Do We Retain Your Information
                </h1>
                <p>
                  We keep your personal data only as long as necessary to fulfill the purposes for which we collected it. The retention period depends on various factors, including data sensitivity, 
                  potential risks, and legal requirements. We also retain information to comply with legal obligations, resolve disputes, and enforce policies. If you cease using our services or delete 
                  your account, we will either delete your information or store it in an aggregated and anonymized format.
                </p>
                <p>
                  For specific retention details based on your role, refer to our supplemental privacy policies.
                </p>
              </div>

              <div className='support-article-wrap mb-5' id='protect'>
                <h1 className='display-5 mb-4 fw-bold'>
                  How We Protect Your Information
                </h1>
                <p>
                  Our teams work diligently to safeguard your information and ensure the security and integrity of our platform. We undergo independent audits to assess data storage and processing systems' 
                  security. However, no method of data transmission over the Internet or electronic storage is completely secure, so we cannot guarantee absolute security for your personal information.
                </p>
              </div>
              
              <div className='support-article-wrap mb-5' id='track'>
                <h1 className='display-5 mb-4 fw-bold'>
                  "Cookies" and Tracking Technologies
                </h1>
                <p>
                  We use <Link to="https://allaboutcookies.org/" target="_blank"> cookies</Link> and similar tracking technologies when operating our website and providing our services.
                </p>
              </div>

              <div className='support-article-wrap mb-5' id='contact'>
                <h1 className='display-5 mb-4 fw-bold'>
                  How You Can Contact Us
                </h1>
                <p>
                  If you have questions, requests, or concerns regarding your personal information processing, please contact SmartSeller Support or use the contact information below. For legally binding 
                  requests for someone else's personal information, contact us at support@sseller.io.
                </p>
                <p>
                  Depending on your relationship with SmartSeller, you can submit data subject requests via email to support@sseller.io, and we may need to verify your identity.
                </p>
                <p>
                  If you have questions about how a merchant or store processes your personal information, you should contact the merchant or visit their privacy policy.
                </p>
                <p>
                  For more information about privacy rights available to US residents, see the "United States Regional Privacy Notice" section.
                </p>
                <p>
                  If you are dissatisfied with our response to a request, you can contact us to resolve the issue. If you are located in the EEA or UK, you also have the right to lodge a complaint with your 
                  local data protection or privacy authority.
                </p>
                <p>
                  <strong>SmartSeller, Inc.</strong><br></br>
                  ATTN: Chief Privacy Officer<br></br>
                  66 W Flagler St, 9th Floor,<br></br>
                  Miami, FL, 33130,<br></br>
                  United States<br></br>
                </p>
              </div>

              <div className='support-article-wrap mb-5' id='us'>
                <h1 className='display-5 mb-4 fw-bold'>
                  United States Privacy Notice
                </h1>
                <p>
                  This United States Regional Privacy Notice ("US Notice") supplements our Privacy Policy and all supplemental privacy policies on www.sseller.io, collectively known as the "SmartSeller Privacy 
                  Policies."
                </p>
                <p>
                  This US Notice is intended for residents in specific US states and is designed to help you comprehend how we collect, use, and disclose your personal information, as well as how to 
                  exercise available rights under various US privacy laws, including the California Consumer Privacy Act, the Colorado Privacy Act, the Connecticut Act Concerning Personal Data Privacy and 
                  Online Monitoring, the Utah Consumer Privacy Act, and the Virginia Consumer Data Protection Act (collectively referred to as the "US Privacy Laws").
                </p>
                <div className='job-details-info my-4'>
                  <h3 className='h5'>
                    What Information We Collect and Share About You
                  </h3>
                  <p>  
                    To provide our apps and services, we process information about you, including personal information. We do not "sell" your personal information as defined under US Privacy Laws.
                  </p>
                  <p>                    
                    Here is a summary of the categories of personal information we may have collected about you over the past 12 months and with whom we may have shared that information, depending on your 
                    use of SmartSeller:
                  </p>
                  <div className='job-details-info'>
                    <h5 className='h5'>
                      Categories of Personal Information Collected
                    </h5>
                    <ul className='content-list list-unstyled'>
                      <li>Identifiers: Name, email address, mailing address, phone number</li>
                      <li>Personal information categories listed in the California Customer Records statute: Name, mailing and billing address, phone number, credit or debit card information</li>
                      <li>Commercial information: Products you purchase, place in your shopping cart, favorite, or review (if you are a customer) and information you provide about you and your 
                        business (if you are a merchant)</li>
                      <li>Photos and videos: Including face imagery if you choose to provide them</li>
                      <li>Internet or other electronic network activity information: Details about the device and browser you use, network connection, IP address, and how you navigate our apps and sites</li>
                      <li>Geolocation data: Mailing and billing address</li>
                      <li>Inferences: Information derived from other personal information, such as preferences and interests</li>
                      <li>Other information you provide</li>
                      <li>Sensitive personal information, including government-issued identifiers, account access credentials, device's precise location (if you allow access), and voluntarily disclosed information 
                        revealing characteristics like racial or ethnic origin or sexual orientation</li>
                      <li>Contents of email messages in connected email inboxes, and information from email messages transferred to the app for order history</li>
                    </ul>
                  </div>
                  <div className='job-details-info'>
                    <h5 className='h5'>
                      Recipients of Personal Information
                    </h5>
                    <ul className='content-list list-unstyled'>
                      <li>Companies assisting in providing services, such as cloud storage providers, payment processors, fulfillment partners, security vendors, email providers, marketplaces, and data 
                        analytics vendors</li>
                      <li>Advertisers and marketing vendors</li>
                      <li>Merchants you visit or purchase from</li>
                      <li>Partners offering services to merchants, such as app development, theme creation, and affiliate referrals</li>
                      <li>Law enforcement or third parties in response to legal requests or compliance with applicable laws</li>
                    </ul>
                  </div>                  
                  <h3 className='h5'>
                    Why We Collect and Share Your Personal Information
                  </h3>
                  <p>  
                    We utilize and share your personal information for the purposes outlined in the SmartSeller Privacy Policies. For sensitive personal information categories, we only use or disclose 
                    them with your specific consent or as allowed by law.
                  </p>
                  <h3 className='h5'>
                    Sources of Personal Information
                  </h3>
                  <p>To enhance commerce at SmartSeller, we collect personal information provided by:</p>
                  <ul>
                    <li>You: Information you provide when using our platform, signing up as a merchant, visiting a SmartSeller-powered store, filling in order information, browsing SmartSeller websites, 
                      and contacting SmartSeller support</li>
                    <li>Your devices: Information from the devices you use, including computers, phones, and web-connected devices</li>
                    <li>Third parties: Information from partners helping us deliver services, email providers, service providers, marketplaces, subprocessors, analytics, and cookie providers</li>
                  </ul>
                  <h3 className='h5'>
                    How Long We Keep Your Information
                  </h3>
                  <p>
                    We generally retain your personal information, including sensitive personal information, as long as you use SmartSeller products or services or until you request its deletion. We 
                    may also retain personal information to meet legal obligations or protect interests.
                  </p>
                  <p>
                    If you are a merchant operating a SmartSeller-powered store, and you close the store, cease paying subscription fees, or have your account terminated, we retain store information for 
                    two years before deletion begins.
                  </p>
                  <p>                  
                    When visiting or making purchases from a merchant's SmartSeller-powered store, the merchant, not SmartSeller, determines the duration for which your information is retained.
                  </p>
                  <h3 className='h5'>
                    Your Rights Over Your Information
                  </h3>
                  <p>
                    Depending on your location and use of SmartSeller, you may have various rights, such as the right to know, request correction, or request deletion of your personal information. 
                    To exercise your rights, including the "right to know" and "right to delete," contact us via email at support@sseller.io.
                  </p>                  
                  <p>
                    If you have interacted with a SmartSeller-powered store, please contact the merchant directly. We will assist merchants in fulfilling your requests as required by law.
                  </p>
                  <p>
                    Please note that we may need to verify your identity before processing your request. In some cases, additional information may be required to protect your data.
                  </p>
                  <p>
                    Under US Privacy Laws, you may designate an authorized agent to make requests on your behalf.
                  </p>
                  <p>
                    We will not discriminate against you for exercising any of these rights.
                  </p>
                  <div className='job-details-info'>
                    <h5 className='h5'>
                      How You Can Reach Us
                    </h5>
                    <p>
                      For inquiries or concerns about your personal information processing, contact SmartSeller Support. To make a request related to your personal information, use the contact methods 
                      provided above.
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicyDetails;
