import React from 'react';
import { Link } from 'react-router-dom';

const Covid19ProductsPolicyDetails = () => {
  return (
    <>
      <section className='support-content ptb-60'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-8 p-lg-8'>

              <div className='support-article-wrap mb-5'>
                <p>      
                  SmartSeller stands behind the sale of products aimed at mitigating the spread of COVID-19 during this extraordinary global health crisis.          
                </p>
                <p>
                  Our dedication to aiding communities in obtaining essential supplies during this critical period is unwavering. However, it's imperative that any COVID-19 related products listed 
                  for sale on SmartSeller's platform adhere to our <Link to="/terms-of-service"> Terms of Service</Link>, which encompass our <Link to="/aup-policy"> Acceptable Use Policy</Link> and 
                  the following Rules of Engagement. Non-compliance with these Rules of Engagement may lead to the removal of your products from your store or the suspension or termination of your 
                  account.
                </p>
                <p>
                  As the pandemic situation continues to evolve, the information on this page may be subject to change. We encourage you to revisit it periodically to ensure that your products remain 
                  in accordance with our guidelines.
                </p>
                <p>
                  <strong>Rules of Engagement</strong>
                </p>
                <p>
                  <strong>Price Gouging:</strong> You are prohibited from setting exorbitant prices or engaging in misleading pricing tactics when it comes to essential COVID-19 related products, 
                  such as medical-grade masks, toilet paper, and hand sanitizer.
                </p>
                <p>
                  <strong>Claims:</strong> All medical, scientific, or any other claims must be accurate and substantiated by documented evidence. When required by law, they should undergo proper 
                  and adequate testing. Any products claiming to prevent, treat, or cure COVID-19 will be removed from the SmartSeller platform.
                </p>
                <p>
                  <strong>Compliance with laws:</strong> It is mandatory that you adhere to all pertinent laws and regulatory requirements pertaining to the sale of COVID-19 related products, both 
                  in your jurisdiction and in your customers' jurisdictions.
                </p>
                <p>
                  <strong>Appropriate licensing or permissions:</strong> You are responsible for obtaining all relevant licenses or permissions for the products you sell. This encompasses any 
                  licenses, approvals, or authorizations necessary for the sale of medical or health-related items.
                </p>
                <p>
                  <strong>Selling regulated products:</strong> Prior notification and/or approval from SmartSeller is required for the sale of regulated products on our platform. In specific cases 
                  (e.g., selling government-approved test kits), you may be asked to provide information or documentation to verify your compliance with these Rules of Engagement and enter into an 
                  additional agreement. Please reach out to us at support@sseller.io.
                </p>
                <p>
                  <strong>Non-Delivery:</strong> It is essential that buyers receive any COVID-19 related products they purchase from your store.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};


export default Covid19ProductsPolicyDetails;
