import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PageMeta from '../../common/PageMeta.js';
import BlogPostHeader from '../BlogPostHeader.js';
import FooterOne from '../../../layout/Footer/FooterOne.js';
import Navbar from '../../../layout/Header/Navbar.js';
import Layout from '../../../layout/Layout.js';
import { blogPosts } from '../../../data/blogPosts.js'
import NeedStorefrontHelp from '../../cta/NeedStorefrontHelp.js';
import { Helmet } from 'react-helmet';


const BlogPost009 = () => {

  const slug = 'introduction-to-split-testing'

  const blogPost = blogPosts.find(obj => {
    return obj.slug === slug
  })

  return (
    <React.Fragment>  

      <Layout>
        <PageMeta title={`${blogPost.title} | SmartSeller Blog`}/>
        <Helmet>
          {/* Essential META Tags */}
          <meta property="og:title" content={`${blogPost.title} | SmartSeller Blog`} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={`https://sseller.io${blogPost.images[0]}`} />
          <meta property="og:url" content={`https://sseller.io${blogPost.images[0]}`} />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Non-Essential, But Recommended */}
          <meta name="description" content={blogPost.excerpt} />
          <meta property="og:description" content={blogPost.excerpt} />
          <meta property="og:site_name" content="SmartSeller"/>
          <meta name="twitter:image:alt" content={`${blogPost.title} | SmartSeller Blog`} />
          {/* Non-Essential, But Required for Analytics */}
          {/* <meta property="fb:app_id" content="your_app_id" />
          <meta name="twitter:site" content="@website-username" /> */}
        </Helmet>
        <Navbar navDark posAbsolute />
        <BlogPostHeader title={blogPost.title} categories={blogPost.categories} readTime={blogPost.readTime} updatedDate={blogPost.updatedDateString}/>
        <section className='blog-details pt-60 pb-120'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-lg-8 pe-5'>
                <div className='blog-details-wrap'>
                  <img
                    src={blogPost.images[0]}
                    className='img-fluid mb-4 rounded-custom'
                    alt='apply'
                  />
                  <p>
                    To identify the elements that yield the best results on a web page, turn to split testing, also known as A/B testing. This technique involves simultaneously testing two versions 
                    of a web page, each with distinct features such as color schemes or headlines, to determine which performs better.
                  </p>
                  <p>
                    Typically, the original web page serves as A (the control), and the new design becomes B. In the realm of web design, split testing provides valuable insights into viewer behavior 
                    and web page performance.
                  </p>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='getting-started'>Getting Started</h2>
                    <p>
                      To effectively conduct split testing, your website should attract a substantial amount of traffic. Like any experiment, having a significant sample size is crucial for reliable 
                      results. A larger sample size reduces the likelihood that your findings are merely due to random chance. While there's no specific magic number for the required web traffic, 
                      generally, accurate results can be obtained with a few thousand subjects. In cases of lower web traffic, extending the test duration becomes necessary to ensure accuracy in the 
                      results.
                    </p>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='what'>What To Test?</h2>
                    <p>
                      The elements of a web page to test largely hinge on the specific goals of that page. For instance, if the aim is to encourage newsletter sign-ups, potential areas to experiment 
                      with include the wording of the web copy, varying the number of fields, and deciding between a pop-up or a static sign-up form. Other critical elements that can impact website 
                      success encompass:
                    </p>
                    <ul>
                      <li>
                        <strong>Page layout:</strong> Determine the optimal placement of images, such as whether they should be on the left or right of the text. Assess whether a double-column layout 
                        is more effective than a single column.
                      </li>
                      <li>
                        <strong>Images:</strong> Evaluate the performance of different images and discern the best way to use product pictures compared to stock images.                        
                      </li>
                      <li>
                        <strong>Call to action:</strong> Experiment with changes to the wording, size, or color of the call-to-action button.                    
                      </li>
                      <li>
                        <strong>Product pricing:</strong> Test different price points and assess the effectiveness of various promotional offers.                        
                      </li>
                      <li>
                        <strong>Amount of text on the page:</strong> Understand whether visitors prefer longer or shorter text.                        
                      </li>
                      <li>
                        <strong>Headings and subheadings:</strong> Test different text options and explore variations in size or color.                        
                      </li>
                    </ul>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='number'>Determining The Number Of Elements To Test Simultaneously</h2>
                    <p>
                      Multivariate testing, a variation of split testing, offers the advantage of obtaining quicker results in a shorter time frame. Given that virtually any element of a web page 
                      can be subjected to split testing, you can simultaneously alter multiple components, such as a sign-up button, different images, and a distinct headline.
                    </p>
                    <p>
                      The drawback, however, is that if the split test yields unfavorable results, pinpointing the specific elements responsible for the outcome becomes challenging. Additionally, 
                      conducting multivariate testing requires a substantial amount of traffic to ensure accuracy in results. Ultimately, the decision of whether to test one or multiple elements 
                      simultaneously rests with you.
                    </p>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='create'>Create Your Test</h2>
                    <p>
                      Now that you've achieved a substantial web traffic volume and identified the elements for testing, it's time to launch your first split test.
                    </p>
                    <p>
                      To begin, secure a split testing tool. If you possess a Google Analytics account, you can utilize the Content Experiments tool. Log in to your Google Analytics account, 
                      navigate to Standard Reporting, and under Content, select Experiments. There are two approaches to split testing:
                    </p>
                    <ol>
                      <li>
                        Designate two elements for testing (e.g., two versions of sales copy), and your tool will randomly substitute the control page with the new page each time a visitor accesses it.
                      </li>
                      <li>
                        Redirect a portion of your visitors to an entirely new web page, typically when testing a larger element like a new color scheme or layout. To execute this, you must create a new 
                        version of the web page and upload it to your tool.
                      </li>
                    </ol>
                    <p>
                      After crafting all your pages and/or variations, specify a conversion goal. For instance, if your objective is to encourage newsletter sign-ups, designate the goal as reaching the 
                      "Thank you for confirming your subscription" page. Indicate your conversion goal by embedding a small piece of code on that page. As visitors reach the conversion goal, your tool 
                      records which version facilitated the conversion for each visitor. You can monitor results in real-time or wait until a substantial number of conversions have occurred to analyze your 
                      findings.
                    </p>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='completion'>Determining The Completion Of Your Testing</h2>
                    <p>
                      How can you ascertain when you've accumulated enough results to draw a conclusive outcome? As mentioned earlier, typically, a few thousand visitors provide sufficient data to 
                      determine the effectiveness of A or B. If you're utilizing Google's Content Experiments tool, the program will automatically indicate when you've reached statistical significance.
                    </p>
                    <p>
                      For those using a different tool, you might need to assess the results independently. Various statistical formulas, such as the Chi-Square statistic, can be employed for this purpose. 
                    </p>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='tips'>Tips For Effective Split Testing</h2>
                    <ul>
                      <li>
                        Patience is key; wait until you've garnered a substantial number of converted visitors before drawing conclusions. While it may be tempting to base decisions on a few hundred 
                        visitors, more accurate results emerge with a larger sample size.
                      </li>
                      <li>
                        Conduct tests simultaneously rather than sequentially. Ensure your pages are tested on the same stream of traffic.
                      </li>
                      <li>
                        Avoid fixating on minute details. Small changes, such as using 12-point Helvetica instead of 12-point Times New Roman, are unlikely to significantly impact traffic. Concentrate 
                        on larger modifications that are more likely to yield improved results.
                      </li>
                    </ul>
                    <p>
                      While you can implement various recommendations for boosting web traffic conversion, the most effective approach is to experiment and observe the outcomes for yourself. Initiating 
                      and conducting split tests is a straightforward and proactive method for optimizing your website's conversion rates without relying on chance.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='author-wrap  bg-light p-5 sticky-sidebar rounded-custom mt-5 mt-lg-0'>
                  <div className='mb-4 text-left'>
                    <div className='author-info'>
                      <h5 className='mb-0'>In This Post</h5>
                    </div>
                    <ul>
                      {blogPost.menu.map((data) => (
                        <li key={`sidebar-${blogPost.id}-${data}`}>
                          <HashLink to={`#${data.id}`} className='text-muted text-decoration-none py-1 d-block'>{data.label}</HashLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <hr className='mb-4'></hr>
                  <div className='text-center'>
                    <img
                      src={blogPost.author.image}
                      alt='author'
                      width='100'
                      className='img-fluid shadow-sm rounded-circle mb-4'
                    />
                    <div className='author-info'>
                      <h5 className='mb-0'>{blogPost.author.name}</h5>
                      <span className='small'>{blogPost.author.title}</span>
                    </div>
                    <ul className='list-unstyled author-social-list list-inline mb-0'>
                      {
                        blogPost.author.social.facebook 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.facebook} target='_blank'>
                                  <i className='fab fa-facebook'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.instagram
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.instagram} target='_blank'>
                                  <i className='fab fa-instagram'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.linkedIn 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.linkedIn} target='_blank'>
                                  <i className='fab fa-linkedin-in'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.gitHub 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.gitHub} target='_blank'>
                                  <i className='fab fa-github'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.gitLab
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.gitLab} target='_blank'>
                                  <i className='fab fa-gitlab'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.twitter
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.twitter} target='_blank'>
                                  <i className='fab fa-twitter'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NeedStorefrontHelp />
        <FooterOne
          style={{
            background:
              "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
          }}
        />
      </Layout>
    </React.Fragment>
  );
};

export default BlogPost009;