import React from 'react';
import DigiBlog from '../../components/blog/DigiBlog';
import DigiContact from '../../components/contact/DigiContact';
import NewsLetter from '../../components/cta/NewsLetter';
import CustomerLogo from '../../components/customer/CustomerLogo';
import CustomerStories from '../../components/customer/CustomerStories';
import DigiIntegration from '../../components/integration/DigiIntegration';
import DigiWhyChoose from '../../components/others/DigiWhyChoose';
import DigiSerives from '../../components/services/DigiSerives';
import FeatureHighlight from '../../components/features/FeatureHighlight';
import DigiWorkProcess from '../../components/work-process/DigiWorkProcess';
import WhatIs from '../../components/work-process/WhatIs';
import Solutions from '../../components/work-process/Solutions';
import WhatIsSmartSeller from '../../components/work-process/WhatIsSmartSeller';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import HeroFourteen from './HeroFourteen';
import ServiceHighlight from "../../components/services/ServiceHighlight";
import IntegrationOne from '../../components/integration/IntegrationOne';
import PricingPlan from '../../components/prices/PricingPlan';


const HomeTest = () => {
  return (
    <Layout>
      <Navbar />
      <HeroFourteen />
      <ServiceHighlight />
      <FeatureHighlight />
      <WhatIsSmartSeller />
      <IntegrationOne />
      <CustomerStories />
      <PricingPlan />
      {/* <NewsLetter /> */}
      <FooterOne
        style={{
          background:
            "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default HomeTest;
