import React from 'react';
import Covid19ProductsPolicyDetails from '../../components/covid-19-products-policy/Covid19ProductsDetails';
import PageMeta from '../../components/common/PageMeta';
import Covid19ProductsPolicyHeader from '../../components/covid-19-products-policy/Covid19ProductsHeader';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const Covid19ProductsPolicy = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar navDark />
      <Covid19ProductsPolicyHeader />
      <Covid19ProductsPolicyDetails />
      <FooterOne
        style={{
          background:
            "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default Covid19ProductsPolicy;