import React from 'react';
import { Link } from 'react-router-dom';

const IntegrationOne = () => {
  return (
    <>
      <section className='integration-section pt-80 pb-120 bg-light'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-3'>
              <div className='integration-list-wrap'>
                <a href='#' className='integration-1' data-bs-toggle='tooltip' data-bs-placement='top' title='Bringer Parcel Services'>
                  <img src='/assets/img/integrations/bps.png' alt='BPS integration' className='img-fluid rounded-circle' />
                </a>
                <a href='#' className='integration-2' data-bs-toggle='tooltip' data-bs-placement='top' title='eBay'>
                  <img src='/assets/img/integrations/ebay.png' alt='eBay integration' className='img-fluid rounded-circle' />
                </a>
                <a href='#' className='integration-3' data-bs-toggle='tooltip' data-bs-placement='top' title='Stripe'>
                  <img src='/assets/img/integrations/stripe.svg' alt='Stripe integration' className='img-fluid rounded-circle' />
                </a>
                <a href='#' className='integration-4' data-bs-toggle='tooltip' data-bs-placement='top' title='Amazon'>
                  <img src='/assets/img/integrations/amazon.png' alt='Amazon integration' className='img-fluid rounded-circle' />
                </a>
                <a href='#' className='integration-5' data-bs-toggle='tooltip' data-bs-placement='top' title='Quickbooks'>
                  <img src='/assets/img/integrations/qb.png' alt='Quickbooks integration' className='img-fluid rounded-circle' />
                </a>
                <a href='#' className='integration-6' data-bs-toggle='tooltip' data-bs-placement='top' title='FedEx'>
                  <img src='/assets/img/integrations/fedex.png' alt='FedEx integration' className='img-fluid rounded-circle' />
                </a>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='section-heading text-center my-5 my-lg-0 my-xl-0' id="integrations">
                {/* <h4 className='text-primary h5'>Integration</h4> */}
                <h2>Integrate with your favorite partners.</h2>
                {/* <Link to='/integrations' className='mt-4 btn btn-primary'>
                  Learn more
                </Link> */}
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='col-lg-4'>
                <div className='integration-list-wrap'>
                  <a href='#' className='integration-7' data-bs-toggle='tooltip' data-bs-placement='top' title='UPS'>
                    <img src='/assets/img/integrations/ups.png' alt='UPS integration' className='img-fluid rounded-circle' />
                  </a>
                  <a href='#' className='integration-8' data-bs-toggle='tooltip' data-bs-placement='top' title='PayPal'>
                    <img src='/assets/img/integrations/paypal.png' alt='PayPal integration' className='img-fluid rounded-circle' />
                  </a>
                  <a href='#' className='integration-9' data-bs-toggle='tooltip' data-bs-placement='top' title='USPS'>
                    <img src='/assets/img/integrations/usps.png' alt='USPS integration' className='img-fluid rounded-circle' />
                  </a>
                  <a href='#' className='integration-10' data-bs-toggle='tooltip' data-bs-placement='top' title='Mercado Libre'>
                    <img src='/assets/img/integrations/mercado-libre.png' alt='Mercado Libre integration' className='img-fluid rounded-circle' />
                  </a>
                  <a href='#' className='integration-11' data-bs-toggle='tooltip' data-bs-placement='top' title='Americanas'>
                    <img src='/assets/img/integrations/americanas.png' alt='Americanas integration' className='img-fluid rounded-circle' />
                  </a>
                  <a href='#' className='integration-12' data-bs-toggle='tooltip' data-bs-placement='top' title='Walmart'>
                    <img src='/assets/img/integrations/walmart.png' alt='Walmart integration' className='img-fluid rounded-circle' />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='row justify-content-center mt-100'>
            <div className='col-lg-5 col-md-12'>
              <a
                href='integration-single.html'
                className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-2 rounded-custom d-block overflow-hidden p-5'
              >
                <div className='position-relative connected-app-content'>
                  <div className='integration-logo bg-custom-light rounded-circle p-2 d-inline-block'>
                    <img
                      src='/assets/img/integrations/4.png'
                      width='40'
                      alt='integration'
                      className='img-fluid'
                    />
                  </div>
                  <h5>Google Drive</h5>
                  <p className='mb-0 text-body'>
                    Competently generate unique e-services and client-based
                    models. Globally engage tactical niche
                  </p>
                </div>
                <span className='position-absolute integration-badge badge px-3 py-2 bg-primary-soft text-primary'>
                  Connect
                </span>
              </a>
            </div>

            <div className='col-lg-5 col-md-12'>
              <a
                href='integration-single.html'
                className='position-relative text-decoration-none connected-app-single border border-light border-2 rounded-custom d-block overflow-hidden p-5'
              >
                <div className='position-relative connected-app-content'>
                  <div className='integration-logo bg-custom-light rounded-circle p-2 d-inline-block'>
                    <img
                      src='/assets/img/integrations/9.png'
                      width='40'
                      alt='integration'
                      className='img-fluid'
                    />
                  </div>
                  <h5>Google Drive</h5>
                  <p className='mb-0 text-body'>
                    Globally engage tactical niche markets rather than
                    client-based competently generate services
                  </p>
                </div>
                <span className='position-absolute integration-badge badge px-3 py-2 bg-danger-soft text-danger'>
                  Connected
                </span>
              </a>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default IntegrationOne;
