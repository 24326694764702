import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PrivacyPolicyHeader from '../../components/privacy-policy/PrivacyPolicyHeader';
import PageMeta from '../../components/common/PageMeta';
import PrivacyPolicyDetails from '../../components/privacy-policy/PrivacyPolicyDetails';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar navDark />
      <PrivacyPolicyHeader />
      <PrivacyPolicyDetails />
      <FooterOne
        style={{
          background:
            "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default PrivacyPolicy;