import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { Link } from 'react-router-dom';

export default function DigiIntegration() {
  return (
    <section
      className="digi-integration bg-dark position-relative"
      style={{
        background:
          "url('/assets/img/page-header-bg.svg')no-repeat center bottom",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-3">
            <div className="int-icons position-relative">
              <ul className="list-unstyled mb-0 d-none d-lg-block">
                <li>
                  <img
                    src="/assets/img/digital-marketing/int-icon/fa.svg"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src="/assets/img/digital-marketing/int-icon/fi.svg"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src="/assets/img/digital-marketing/int-icon/slack.svg"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src="/assets/img/digital-marketing/int-icon/inst.svg"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src="/assets/img/digital-marketing/int-icon/er.svg"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src="/assets/img/digital-marketing/int-icon/google.svg"
                    alt="icon"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="text-center">
              <h2 className="mb-4">
                Integrate with your favorite partners
              </h2>
              <div>
                <Link
                  to="/contact-us"
                  className="btn rounded-pill bg-orange me-3 mb-3"
                >
                  Get in touch
                </Link>
                <Link
                  to="/integrations"
                  className="btn rounded-pill btn-outline-light mb-3"
                >
                  Browse all integration
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="int-icons position-relative">
              <ul className="list-unstyled mb-0 d-none d-lg-block">
                <li>
                  <img
                    src="/assets/img/digital-marketing/int-icon/slash.svg"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src="/assets/img/digital-marketing/int-icon/tree.svg"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src="/assets/img/digital-marketing/int-icon/drive.svg"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src="/assets/img/digital-marketing/int-icon/in.svg"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src="/assets/img/digital-marketing/int-icon/star.svg"
                    alt="icon"
                  />
                </li>
                <li>
                  <img
                    src="/assets/img/digital-marketing/int-icon/twi.svg"
                    alt="icon"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
