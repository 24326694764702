import React from "react";

const PricingPlan = () => {
  return (
    <section className="price-two pt-60 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10" id="pricing">
            <div className="section-heading text-center">
              <h2>
                Plans for every stage of business.
              </h2>
              <p>
                Spend less time, operate faster, and save more money with SmartSeller.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-primary d-block">Starter Plan</h3>
                <h3 className="fw-semi-bold">
                  $99
                </h3>
                <span>Billed monthly</span>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    1 Market
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    1 Language
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    3 Staff Users
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    3 Sales Channels
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    1,000 Shipments per month
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Inventory Management
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Shared server
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Email support, 24/7
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Access to integrations
                  </li>
                  <li>
                    <i className="far fa-times-circle text-muted me-2"></i>
                    Phone support, 9am-7pm Mon-Sat, ET
                  </li>
                  <li>
                    <i className="far fa-times-circle text-muted me-2"></i>
                    Shipping Strategies, Bundling Algorithms 
                  </li>
                  <li>
                    <i className="far fa-times-circle text-muted me-2"></i>
                    Access to REST API
                  </li>
                </ul>
              </div>
              <a href="https://calendly.com/smartseller/demo" target="_blank" className="btn btn-outline-primary mt-2">
                Get Started
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-gradient text-white p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-warning d-block">Growth Plan</h3>
                <h3 className="fw-semi-bold">
                  2% of gross sales
                </h3>
                <span className="text-white">$599/month minimum, billed monthly</span>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    20 Markets
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    5 Languages
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    10 Staff Users
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    20 Sales Channels
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    10,000 Shipments per month
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Inventory Management
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Dedicated server
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Email support, 24/7
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Access to integrations
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Phone support, 9am-7pm Mon-Sat, ET
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Shipping Strategies, Bundling Algorithms 
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Access to REST API, rate limited
                  </li>
                </ul>
              </div>
              <a href="https://calendly.com/smartseller/demo" target="_blank" className="btn btn-primary mt-2">
                Get Started
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-primary d-block">High Volume Plan</h3>
                <h3 className="fw-semi-bold">
                  Quote
                </h3>
                <span>Contact us for a custom quote</span>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Unlimited Markets
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Unlimited Languages
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    20 Staff Users
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Unlimited Sales Channels
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Unlimited Shipments
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Inventory Management
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Dedicated server
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Email support, 24/7
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Access to integrations
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Phone support, 9am-7pm Mon-Sat, ET
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Shipping Strategies, Bundling Algorithms 
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Access to REST API, rate limited
                  </li>
                </ul>
              </div>
              <a href="https://calendly.com/smartseller/demo" target="_blank" className="btn btn-outline-primary mt-2">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingPlan;
