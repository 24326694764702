import React from 'react';
import { Link } from 'react-router-dom';

export default function DigiSerives() {
  return (
    <section className="digi-services pb-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="text-center mb-5">
              {/* <div>
                <span className="span-arrow">Best Digital Agency</span>
                <img src="/assets/img/arro-right.svg" alt="arrow" />
              </div> */}
              <h2>
                Learn how SmartSeller helps you work smarter and scale faster.
                {/* <span className="text-orange">Media Marketing</span> */}
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6 col-xl-3">
            <div className="digi-service-single mb-4 mb-xl-0">
              <div className="text-center">
                <img src="/assets/img/screen/widget-1.png" alt="icon" />
              </div>
              <h3 className="h5">Ecommerce</h3>
              <p>
                Sell online and manage your operations in a single application.
              </p>
              <Link
                to="/services"
                className="read-more-link text-decoration-none"
              >
                Watch demos <i className="far fa-arrow-right ms-2"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xl-3">
            <div className="digi-service-single mb-4 mb-xl-0">
              <div className="text-center">
                <img src="/assets/img/screen/widget-1.png" alt="icon" />
              </div>
              <h3 className="h5">Sales Channels</h3>
              <p>
                Enable multiple sales channels to ramp up growth.
              </p>
              <Link
                to="/services"
                className="read-more-link text-decoration-none"
              >
                Watch demos <i className="far fa-arrow-right ms-2"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xl-3">
            <div className="digi-service-single mb-4 mb-xl-0">
              <div className="text-center">
                <img src="/assets/img/screen/widget-1.png" alt="icon" />
              </div>
              <h3 className="h5">Order Fulfillment</h3>
              <p>
                Pick items, fulfill orders, and print shipping labels.
              </p>
              <Link
                to="/services"
                className="read-more-link text-decoration-none"
              >
                Watch demos <i className="far fa-arrow-right ms-2"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xl-3">
            <div className="digi-service-single mb-4 mb-xl-0">
              <div className="text-center">
                <img src="/assets/img/screen/widget-1.png" alt="icon" />
              </div>
              <h3 className="h5">Product Management</h3>
              <p>
                Track inventory and sync product data across listings.
              </p>
              <Link
                to="/services"
                className="read-more-link text-decoration-none"
              >
                Watch demos <i className="far fa-arrow-right ms-2"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
