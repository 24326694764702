import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PageMeta from '../../common/PageMeta.js';
import BlogPostHeader from '../BlogPostHeader.js';
import FooterOne from '../../../layout/Footer/FooterOne.js';
import Navbar from '../../../layout/Header/Navbar.js';
import Layout from '../../../layout/Layout.js';
import { blogPosts } from '../../../data/blogPosts.js'
import NeedStorefrontHelp from '../../cta/NeedStorefrontHelp.js';
import { Helmet } from 'react-helmet';


const BlogPost004 = () => {

  const slug = 'utilize-a-personalized-domain'

  const blogPost = blogPosts.find(obj => {
    return obj.slug === slug
  })

  return (
    <React.Fragment>  

      <Layout>
        <PageMeta title={`${blogPost.title} | SmartSeller Blog`}/>
        <Helmet>
          {/* Essential META Tags */}
          <meta property="og:title" content={`${blogPost.title} | SmartSeller Blog`} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={`https://sseller.io${blogPost.images[0]}`} />
          <meta property="og:url" content={`https://sseller.io${blogPost.images[0]}`} />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Non-Essential, But Recommended */}
          <meta name="description" content={blogPost.excerpt} />
          <meta property="og:description" content={blogPost.excerpt} />
          <meta property="og:site_name" content="SmartSeller"/>
          <meta name="twitter:image:alt" content={`${blogPost.title} | SmartSeller Blog`} />
          {/* Non-Essential, But Required for Analytics */}
          {/* <meta property="fb:app_id" content="your_app_id" />
          <meta name="twitter:site" content="@website-username" /> */}
        </Helmet>
        <Navbar navDark posAbsolute />
        <BlogPostHeader title={blogPost.title} categories={blogPost.categories} readTime={blogPost.readTime} updatedDate={blogPost.updatedDateString}/>
        <section className='blog-details pt-60 pb-120'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-lg-8 pe-5'>
                <div className='blog-details-wrap'>
                  <img
                    src={blogPost.images[0]}
                    className='img-fluid mb-4 rounded-custom'
                    alt='apply'
                  />
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='what'>What Constitutes A Personalized Domain?</h2>
                    <p>
                      A personalized domain refers to a distinct URL tailored to your brand, guiding customers directly to your online content upon entering it into the address bar. When creating 
                      your storefronts, you have the option to select a shorter and more memorable domain, like myawesomestore.com. This significantly facilitates customers in discovering, recalling, 
                      and sharing your shop and its products.
                    </p>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='subdomain'>Choosing the Right Time For A Subdomain</h2>
                    <p>
                      Deciding when to employ a subdomain is crucial for those with a well-established website aiming to incorporate an online shop seamlessly. A subdomain serves as a prefix to your 
                      primary domain, aiding in the systematic organization of your online content. For instance, you can house essential brand content at myawesomeshop.com and establish a subdomain, 
                      such as shop.myawesomestore.com, exclusively dedicated to selling your products. This approach allows for the seamless integration of your shop into your existing website while 
                      ensuring clarity and organization for your customers.
                    </p>
                    <p>
                      Whether you're in the process of registering a domain or already possess one, we've simplified the task of setting up your custom domain or subdomain for your SmartSeller store.
                    </p>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='need'>In Need Of A Domain?</h2>
                    <p>
                      If you currently lack a personalized domain, worry not, we're here to assist you in establishing a new domain! To acquire a domain, purchase one through a SmartSeller <Link to='/blog-post/compatible-domain-service-providers' target='_blank'>compatible domain
                      service provider</Link>.
                    </p>
                    <ol>
                      <li>
                        Navigate to a domain service provider's website.
                      </li>
                      <li>
                        Purchase a personalized domain.
                      </li>
                      <li>
                        <Link to='/contact-us'>Contact</Link> us to help you connect the personalized domain to your store.
                      </li>
                    </ol>
                    <p>
                      <strong>Expert Tips for Choosing a Domain Name:</strong>
                    </p>
                    <ul>
                      <li>
                        Opt for brevity, uniqueness, and memorability.
                      </li>
                      <li>
                        Prefer top-level domain extensions such as .com, unless a specialized extension like .shop strongly aligns with your brand.
                      </li>
                      <li>
                        Steer clear of numbers and special characters to enhance recall.
                      </li>
                      <li>
                        Ensure alignment with your brand and incorporate relevant keywords for easy memorization.
                      </li>
                      <li>
                        Conduct thorough research on your chosen domain name to preemptively avoid potential trademark or branding issues in the future.
                      </li>
                    </ul>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='have'>Already Have A Domain?</h2>
                    <p>
                      Linking your existing domain to your SmartSeller store is a breeze, even if it's currently associated with another website. Our  configuration seamlessly accommodates the 
                      <Link to='/blog-post/compatible-domain-service-providers' target='_blank'> most popular domain registrars</Link>, requiring just a few clicks to set you in motion! <Link to='/contact-us'>Contact</Link> us to help you connect the personalized domain to your store.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='author-wrap  bg-light p-5 sticky-sidebar rounded-custom mt-5 mt-lg-0'>
                  <div className='mb-4 text-left'>
                    <div className='author-info'>
                      <h5 className='mb-0'>In This Post</h5>
                    </div>
                    <ul>
                      {blogPost.menu.map((data) => (
                        <li key={`sidebar-${blogPost.id}-${data}`}>
                          <HashLink to={`#${data.id}`} className='text-muted text-decoration-none py-1 d-block'>{data.label}</HashLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <hr className='mb-4'></hr>
                  <div className='text-center'>
                    <img
                      src={blogPost.author.image}
                      alt='author'
                      width='100'
                      className='img-fluid shadow-sm rounded-circle mb-4'
                    />
                    <div className='author-info'>
                      <h5 className='mb-0'>{blogPost.author.name}</h5>
                      <span className='small'>{blogPost.author.title}</span>
                    </div>
                    <ul className='list-unstyled author-social-list list-inline mb-0'>
                      {
                        blogPost.author.social.facebook 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.facebook} target='_blank'>
                                  <i className='fab fa-facebook'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.instagram
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.instagram} target='_blank'>
                                  <i className='fab fa-instagram'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.linkedIn 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.linkedIn} target='_blank'>
                                  <i className='fab fa-linkedin-in'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.gitHub 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.gitHub} target='_blank'>
                                  <i className='fab fa-github'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.gitLab
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.gitLab} target='_blank'>
                                  <i className='fab fa-gitlab'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.twitter
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.twitter} target='_blank'>
                                  <i className='fab fa-twitter'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NeedStorefrontHelp />
        <FooterOne
          style={{
            background:
              "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
          }}
        />
      </Layout>
    </React.Fragment>
  );
};

export default BlogPost004;