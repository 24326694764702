import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';


const GetStartedForm = () => {

  const [showPlatforms, setShowPlatforms] = useState(false);

  const { formState: {errors}, handleSubmit, register } = useForm({
    defaultValues: {
      companyName: "",
      country: "",
      email: "",
      employees: "",
      firstName: "",
      jobTitle: "",
      lastName: "",
      website: "",
    },
    resolver: zodResolver(z.object({
      companyName: z.string()
        .nonempty('This field is required'),
      country: z.string()
        .nonempty('This field is required'),
      email: z.string()
        .email('Enter a valid email address')
        .nonempty('This field is required'),
      employees: z.string()
        .nonempty('This field is required'),
      firstName: z.string()
        .nonempty('This field is required'),
      jobTitle: z.string()
        .nonempty('This field is required'),
      lastName: z.string()
        .nonempty('This field is required'),
      website: z.string()
        .url('Enter a valid website')
        .nonempty('This field is required')
    })),
  });

  const onSubmit = (data) => {
    console.log(data)
  };

  return (
    <>
      <div className='col-xl-6 col-lg-6 col-md-12 order-0 order-lg-1'>
        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h3 className='fw-medium h4'>
            Fill out the form and our team will be in touch to help you get started.
          </h3>

          <Form className="mt-4 register-form" action="#" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col sm={4}>
                <div className="mb-3">
                  <div>
                    <Form.Label htmlFor="firstName">First Name <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      id="firstName"
                      placeholder="Enter first name"
                      {
                        ...register('firstName', {
                          //onChange: e => {},
                          //onBlur: e => {}
                        })
                      }
                    />
                    {
                      errors.firstName &&
                      <span className="text-danger">{errors.firstName.message}</span>
                    }
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="mb-3">
                  <div>
                    <Form.Label htmlFor="lastName">Last Name <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      id="lastName"
                      placeholder="Enter last name"
                      {
                        ...register('lastName', {
                          //onChange: e => {},
                          //onBlur: e => {}
                        })
                      }
                    />
                    {
                      errors.lastName &&
                      <span className="text-danger">{errors.lastName.message}</span>
                    }
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="mb-3">
                  <div>
                    <Form.Label htmlFor="email">Email <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      placeholder="Enter email"
                      {
                        ...register('email', {
                          //onChange: e => {},
                          //onBlur: e => {}
                        })
                      }
                    />
                    {
                      errors.email &&
                      <span className="text-danger">{errors.email.message}</span>
                    }
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="mb-3">
                  <div>
                    <Form.Label htmlFor="companyName">Company Name <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      id="companyName"
                      placeholder="Enter company name"
                      {
                        ...register('companyName', {
                          //onChange: e => {},
                          //onBlur: e => {}
                        })
                      }
                    />
                    {
                      errors.companyName &&
                      <span className="text-danger">{errors.companyName.message}</span>
                    }
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="mb-3">
                  <div>
                    <Form.Label htmlFor="country">Your Country <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      as="select"
                      id="country"
                      placeholder="Country"
                      {
                        ...register('country', {
                          //onChange: e => {}
                          //onBlur: e => {}
                        })
                      }
                    >
                      <option value="">Select country</option>
                      <option value='AF'>Afghanistan</option>
                      <option value='AX'>Åland Islands</option>
                      <option value='AL'>Albania</option>
                      <option value='DZ'>Algeria</option>
                      <option value='AS'>American Samoa</option>
                      <option value='AD'>Andorra</option>
                      <option value='AO'>Angola</option>
                      <option value='AI'>Anguilla</option>
                      <option value='AQ'>Antarctica</option>
                      <option value='AG'>Antigua and Barbuda</option>
                      <option value='AR'>Argentina</option>
                      <option value='AM'>Armenia</option>
                      <option value='AW'>Aruba</option>
                      <option value='AU'>Australia</option>
                      <option value='AT'>Austria</option>
                      <option value='AZ'>Azerbaijan</option>
                      <option value='BS'>Bahamas</option>
                      <option value='BH'>Bahrain</option>
                      <option value='BD'>Bangladesh</option>
                      <option value='BB'>Barbados</option>
                      <option value='BY'>Belarus</option>
                      <option value='BE'>Belgium</option>
                      <option value='BZ'>Belize</option>
                      <option value='BJ'>Benin</option>
                      <option value='BM'>Bermuda</option>
                      <option value='BT'>Bhutan</option>
                      <option value='BO'>Bolivia, Plurinational State of</option>
                      <option value='BQ'>Bonaire, Sint Eustatius and Saba</option>
                      <option value='BA'>Bosnia and Herzegovina</option>
                      <option value='BW'>Botswana</option>
                      <option value='BV'>Bouvet Island</option>
                      <option value='BR'>Brazil</option>
                      <option value='IO'>British Indian Ocean Territory</option>
                      <option value='BN'>Brunei Darussalam</option>
                      <option value='BG'>Bulgaria</option>
                      <option value='BF'>Burkina Faso</option>
                      <option value='BI'>Burundi</option>
                      <option value='KH'>Cambodia</option>
                      <option value='CM'>Cameroon</option>
                      <option value='CA'>Canada</option>
                      <option value='CV'>Cape Verde</option>
                      <option value='KY'>Cayman Islands</option>
                      <option value='CF'>Central African Republic</option>
                      <option value='TD'>Chad</option>
                      <option value='CL'>Chile</option>
                      <option value='CN'>China</option>
                      <option value='CX'>Christmas Island</option>
                      <option value='CC'>Cocos (Keeling) Islands</option>
                      <option value='CO'>Colombia</option>
                      <option value='KM'>Comoros</option>
                      <option value='CG'>Congo</option>
                      <option value='CD'>Congo, the Democratic Republic of the</option>
                      <option value='CK'>Cook Islands</option>
                      <option value='CR'>Costa Rica</option>
                      <option value='CI'>Côte d'Ivoire</option>
                      <option value='HR'>Croatia</option>
                      <option value='CU'>Cuba</option>
                      <option value='CW'>Curaçao</option>
                      <option value='CY'>Cyprus</option>
                      <option value='CZ'>Czech Republic</option>
                      <option value='DK'>Denmark</option>
                      <option value='DJ'>Djibouti</option>
                      <option value='DM'>Dominica</option>
                      <option value='DO'>Dominican Republic</option>
                      <option value='EC'>Ecuador</option>
                      <option value='EG'>Egypt</option>
                      <option value='SV'>El Salvador</option>
                      <option value='GQ'>Equatorial Guinea</option>
                      <option value='ER'>Eritrea</option>
                      <option value='EE'>Estonia</option>
                      <option value='ET'>Ethiopia</option>
                      <option value='FK'>Falkland Islands (Malvinas)</option>
                      <option value='FO'>Faroe Islands</option>
                      <option value='FJ'>Fiji</option>
                      <option value='FI'>Finland</option>
                      <option value='FR'>France</option>
                      <option value='GF'>French Guiana</option>
                      <option value='PF'>French Polynesia</option>
                      <option value='TF'>French Southern Territories</option>
                      <option value='GA'>Gabon</option>
                      <option value='GM'>Gambia</option>
                      <option value='GE'>Georgia</option>
                      <option value='DE'>Germany</option>
                      <option value='GH'>Ghana</option>
                      <option value='GI'>Gibraltar</option>
                      <option value='GR'>Greece</option>
                      <option value='GL'>Greenland</option>
                      <option value='GD'>Grenada</option>
                      <option value='GP'>Guadeloupe</option>
                      <option value='GU'>Guam</option>
                      <option value='GT'>Guatemala</option>
                      <option value='GG'>Guernsey</option>
                      <option value='GN'>Guinea</option>
                      <option value='GW'>Guinea-Bissau</option>
                      <option value='GY'>Guyana</option>
                      <option value='HT'>Haiti</option>
                      <option value='HM'>Heard Island and McDonald Islands</option>
                      <option value='VA'>Holy See (Vatican City State)</option>
                      <option value='HN'>Honduras</option>
                      <option value='HK'>Hong Kong</option>
                      <option value='HU'>Hungary</option>
                      <option value='IS'>Iceland</option>
                      <option value='IN'>India</option>
                      <option value='ID'>Indonesia</option>
                      <option value='IR'>Iran, Islamic Republic of</option>
                      <option value='IQ'>Iraq</option>
                      <option value='IE'>Ireland</option>
                      <option value='IM'>Isle of Man</option>
                      <option value='IL'>Israel</option>
                      <option value='IT'>Italy</option>
                      <option value='JM'>Jamaica</option>
                      <option value='JP'>Japan</option>
                      <option value='JE'>Jersey</option>
                      <option value='JO'>Jordan</option>
                      <option value='KZ'>Kazakhstan</option>
                      <option value='KE'>Kenya</option>
                      <option value='KI'>Kiribati</option>
                      <option value='KP'>Korea, Democratic People's Republic of</option>
                      <option value='KR'>Korea, Republic of</option>
                      <option value='KW'>Kuwait</option>
                      <option value='KG'>Kyrgyzstan</option>
                      <option value='LA'>Lao People's Democratic Republic</option>
                      <option value='LV'>Latvia</option>
                      <option value='LB'>Lebanon</option>
                      <option value='LS'>Lesotho</option>
                      <option value='LR'>Liberia</option>
                      <option value='LY'>Libya</option>
                      <option value='LI'>Liechtenstein</option>
                      <option value='LT'>Lithuania</option>
                      <option value='LU'>Luxembourg</option>
                      <option value='MO'>Macao</option>
                      <option value='MK'>Macedonia, the former Yugoslav Republic of</option>
                      <option value='MG'>Madagascar</option>
                      <option value='MW'>Malawi</option>
                      <option value='MY'>Malaysia</option>
                      <option value='MV'>Maldives</option>
                      <option value='ML'>Mali</option>
                      <option value='MT'>Malta</option>
                      <option value='MH'>Marshall Islands</option>
                      <option value='MQ'>Martinique</option>
                      <option value='MR'>Mauritania</option>
                      <option value='MU'>Mauritius</option>
                      <option value='YT'>Mayotte</option>
                      <option value='MX'>Mexico</option>
                      <option value='FM'>Micronesia, Federated States of</option>
                      <option value='MD'>Moldova, Republic of</option>
                      <option value='MC'>Monaco</option>
                      <option value='MN'>Mongolia</option>
                      <option value='ME'>Montenegro</option>
                      <option value='MS'>Montserrat</option>
                      <option value='MA'>Morocco</option>
                      <option value='MZ'>Mozambique</option>
                      <option value='MM'>Myanmar</option>
                      <option value='NA'>Namibia</option>
                      <option value='NR'>Nauru</option>
                      <option value='NP'>Nepal</option>
                      <option value='NL'>Netherlands</option>
                      <option value='NC'>New Caledonia</option>
                      <option value='NZ'>New Zealand</option>
                      <option value='NI'>Nicaragua</option>
                      <option value='NE'>Niger</option>
                      <option value='NG'>Nigeria</option>
                      <option value='NU'>Niue</option>
                      <option value='NF'>Norfolk Island</option>
                      <option value='MP'>Northern Mariana Islands</option>
                      <option value='NO'>Norway</option>
                      <option value='OM'>Oman</option>
                      <option value='PK'>Pakistan</option>
                      <option value='PW'>Palau</option>
                      <option value='PS'>Palestinian Territory, Occupied</option>
                      <option value='PA'>Panama</option>
                      <option value='PG'>Papua New Guinea</option>
                      <option value='PY'>Paraguay</option>
                      <option value='PE'>Peru</option>
                      <option value='PH'>Philippines</option>
                      <option value='PN'>Pitcairn</option>
                      <option value='PL'>Poland</option>
                      <option value='PT'>Portugal</option>
                      <option value='PR'>Puerto Rico</option>
                      <option value='QA'>Qatar</option>
                      <option value='RE'>Réunion</option>
                      <option value='RO'>Romania</option>
                      <option value='RU'>Russian Federation</option>
                      <option value='RW'>Rwanda</option>
                      <option value='BL'>Saint Barthélemy</option>
                      <option value='SH'>Saint Helena, Ascension and Tristan da Cunha</option>
                      <option value='KN'>Saint Kitts and Nevis</option>
                      <option value='LC'>Saint Lucia</option>
                      <option value='MF'>Saint Martin (French part)</option>
                      <option value='PM'>Saint Pierre and Miquelon</option>
                      <option value='VC'>Saint Vincent and the Grenadines</option>
                      <option value='WS'>Samoa</option>
                      <option value='SM'>San Marino</option>
                      <option value='ST'>Sao Tome and Principe</option>
                      <option value='SA'>Saudi Arabia</option>
                      <option value='SN'>Senegal</option>
                      <option value='RS'>Serbia</option>
                      <option value='SC'>Seychelles</option>
                      <option value='SL'>Sierra Leone</option>
                      <option value='SG'>Singapore</option>
                      <option value='SX'>Sint Maarten (Dutch part)</option>
                      <option value='SK'>Slovakia</option>
                      <option value='SI'>Slovenia</option>
                      <option value='SB'>Solomon Islands</option>
                      <option value='SO'>Somalia</option>
                      <option value='ZA'>South Africa</option>
                      <option value='GS'>South Georgia and the South Sandwich Islands</option>
                      <option value='SS'>South Sudan</option>
                      <option value='ES'>Spain</option>
                      <option value='LK'>Sri Lanka</option>
                      <option value='SD'>Sudan</option>
                      <option value='SR'>Suriname</option>
                      <option value='SJ'>Svalbard and Jan Mayen</option>
                      <option value='SZ'>Swaziland</option>
                      <option value='SE'>Sweden</option>
                      <option value='CH'>Switzerland</option>
                      <option value='SY'>Syrian Arab Republic</option>
                      <option value='TW'>Taiwan, Province of China</option>
                      <option value='TJ'>Tajikistan</option>
                      <option value='TZ'>Tanzania, United Republic of</option>
                      <option value='TH'>Thailand</option>
                      <option value='TL'>Timor-Leste</option>
                      <option value='TG'>Togo</option>
                      <option value='TK'>Tokelau</option>
                      <option value='TO'>Tonga</option>
                      <option value='TT'>Trinidad and Tobago</option>
                      <option value='TN'>Tunisia</option>
                      <option value='TR'>Turkey</option>
                      <option value='TM'>Turkmenistan</option>
                      <option value='TC'>Turks and Caicos Islands</option>
                      <option value='TV'>Tuvalu</option>
                      <option value='UG'>Uganda</option>
                      <option value='UA'>Ukraine</option>
                      <option value='AE'>United Arab Emirates</option>
                      <option value='GB'>United Kingdom</option>
                      <option value='US'>United States</option>
                      <option value='UM'>United States Minor Outlying Islands</option>
                      <option value='UY'>Uruguay</option>
                      <option value='UZ'>Uzbekistan</option>
                      <option value='VU'>Vanuatu</option>
                      <option value='VE'>Venezuela</option>
                      <option value='VN'>Viet Nam</option>
                      <option value='VG'>Virgin Islands, British</option>
                      <option value='VI'>Virgin Islands, U.S.</option>
                      <option value='WF'>Wallis and Futuna</option>
                      <option value='EH'>Western Sahara</option>
                      <option value='YE'>Yemen</option>
                      <option value='ZM'>Zambia</option>
                      <option value='ZW'>Zimbabwe</option>
                    </Form.Control>
                    {
                      errors.country &&
                      <span className="text-danger">{errors.country.message}</span>
                    }
                  </div>
                </div>
              </Col>             
            </Row>
            <Row>
              <Col sm={6}>
                <div className="mb-3">
                  <div>
                    <Form.Label htmlFor="jobTitle">Job Title <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      id="jobTitle"
                      placeholder="Enter job title"
                      {
                        ...register('jobTitle', {
                          //onChange: e => {},
                          //onBlur: e => {}
                        })
                      }
                    />
                    {
                      errors.jobTitle &&
                      <span className="text-danger">{errors.jobTitle.message}</span>
                    }
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="mb-3">
                  <div>
                    <Form.Label htmlFor="website">Website <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      id="website"
                      placeholder="Enter website"
                      {
                        ...register('website', {
                          //onChange: e => {},
                          //onBlur: e => {}
                        })
                      }
                    />
                    {
                      errors.website &&
                      <span className="text-danger">{errors.website.message}</span>
                    }
                  </div>
                </div>
              </Col>
            </Row>          
            <Row>
              <Col sm={12}>
                <div className="mb-3">
                  <div>
                    <Form.Label htmlFor="employees">Size of your organization <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      as="select"
                      id="employees"
                      placeholder="Employees"
                      {
                        ...register('employees', {
                          //onChange: e => {}
                          //onBlur: e => {}
                        })
                      }
                    >
                      <option value="">Select the number of employees</option>
                      <option value="1 - 20 employees">1 - 20 employees</option>
                      <option value="21 - 200 employees">21 - 200 employees</option>
                      <option value="201 - 10,000 employees">201 - 10,000 employees</option>
                      <option value="10,001+ employees">10,001+ employees</option>
                    </Form.Control>
                    {
                      errors.employees &&
                      <span className="text-danger">{errors.employees.message}</span>
                    }
                  </div>
                </div>
              </Col>              
            </Row>
            <Row>
              <Col sm={12}>
                <div className="mb-3">
                  <div>
                    <Form.Label htmlFor="salesChannels">What platforms are you currently selling on? <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      as="select"
                      multiple={true}
                      id="salesChannels"
                      {
                        ...register('salesChannels', {
                        })
                      }
                    >
                      <option value="I'm not selling online yet">I'm not selling online yet</option>
                      <option value="Square">Square</option>
                      <option value="Amazon">Amazon</option>
                      <option value="Mercado Libre">Mercado Libre</option>
                      <option value="Walmart">Walmart</option>
                      <option value="Etsy">Etsy</option>
                      <option value="eBay">eBay</option>
                      <option value="Wix">Wix</option>
                      <option value="WordPress">WordPress</option>
                      <option value="WooCommerce">WooCommerce</option>
                      <option value="Squarespace">Squarespace</option>
                      <option value="GoDaddy">GoDaddy</option>
                      <option value="Big Cartel">Big Cartel</option>
                      <option value="BigCommerce">BigCommerce</option>
                      <option value="Lightspeed">Lightspeed</option>
                      <option value="Clover">Clover</option>
                      <option value="Magento">Magento</option>
                      <option value="PrestaShop">PrestaShop</option>
                      <option value="OpenCart">OpenCart</option>
                      <option value="Vend">Vend</option>
                      <option value="ShopKeep">ShopKeep</option>
                      <option value="Other Platform">Other Platform</option>
                    </Form.Control>
                    {
                      errors.salesChannels &&
                      <span className="text-danger">{errors.salesChannels.message}</span>
                    }
                  </div>
                </div>
              </Col>              
            </Row>
            <Row>
              <Col sm={12}>
                <div className="mb-3">
                  <div>
                    <Form.Label htmlFor="salesLocations">Where would you like to sell? <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      as="select"
                      multiple={true}
                      id="salesLocations"
                      {
                        ...register('salesLocations', {
                        })
                      }
                    >
                      <option value="">Select the channels</option>
                      <option value="An online store">An online store</option>
                      <option value="Online marketplaces">Online marketplaces</option>
                    </Form.Control>
                    {
                      errors.salesLocations &&
                      <span className="text-danger">{errors.salesLocations.message}</span>
                    }
                  </div>
                </div>
              </Col>              
            </Row>
            <Row>
              <Col sm={12}>
                <div className="mb-3">
                  <div>
                    <Form.Label htmlFor="products">What will you sell? <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      as="select"
                      multiple={true}
                      rows={1}
                      id="products"
                      {
                        ...register('products', {
                        })
                      }
                    >
                      <option value="">Select the type of products</option>
                      <option value="Products I make or buy">Products I make or buy</option>
                      <option value="Products drop shipped by another party">Products drop shipped by another party</option>
                    </Form.Control>
                    {
                      errors.products &&
                      <span className="text-danger">{errors.products.message}</span>
                    }
                  </div>
                </div>
              </Col>              
            </Row>
            <div className="text-end">
              <Button type="submit" className="btn btn-primary mt-4 d-block w-100">Submit</Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default GetStartedForm;
