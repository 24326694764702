import React from 'react';
import { Link } from 'react-router-dom';

const LatestBlog = () => {
  return (
    <>
      <section className='related-blog-list pt-60 pb-80'>
        <div className='container'>
          <div className="row justify-content-center" id="features">
            <div className="col-lg-8">
              <div className="text-center mb-5">
                <h2>
                  Learn how SmartSeller helps you work smarter and scale faster.
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='single-article rounded-custom mb-4 mb-lg-0'>
                <Link to='#' className='article-img'>
                  <img
                    src='/assets/img/home/ecommerce.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <Link to='#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Ecommerce
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Sell online and manage operations in a single application.
                  </p>
                  {/* <Link
                    to="/services"
                    className="read-more-link text-decoration-none"
                  >
                    Watch demos <i className="far fa-arrow-right ms-2"></i>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-article rounded-custom mb-4 mb-lg-0'>
                <Link to='#' className='article-img'>
                  <img
                    src='/assets/img/home/sales_channels.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <Link to='#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Sales Channels
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Enable multiple sales channels to ramp up growth.
                  </p>
                  {/* <Link
                    to="/services"
                    className="read-more-link text-decoration-none"
                  >
                    Watch demos <i className="far fa-arrow-right ms-2"></i>
                  </Link> */}
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6'>
              <div className='single-article rounded-custom mb-4 mb-lg-0'>
                <Link to='#' className='article-img'>
                  <img
                    src='/assets/img/home/order_fulfillment.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <Link to='#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Order Fulfillment
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Pick items, fulfill orders, and print shipping labels.
                  </p>
                  {/* <Link
                    to="/services"
                    className="read-more-link text-decoration-none"
                  >
                    Watch demos <i className="far fa-arrow-right ms-2"></i>
                  </Link> */}
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6'>
              <div className='single-article rounded-custom mb-4 mb-lg-0 mb-md-0'>
                <Link to='#' className='article-img'>
                  <img
                    src='/assets/img/home/product_management.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <Link to='#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Product Management
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Track inventory and sync product data across listings.
                  </p>
                  {/* <Link
                    to="/services"
                    className="read-more-link text-decoration-none"
                  >
                    Watch demos <i className="far fa-arrow-right ms-2"></i>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestBlog;
