import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const AUPPolicyDetails = () => {
  return (
    <>
      <section className='support-content ptb-60'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-8 p-lg-8'>

              <div className='support-article-wrap mb-5'>
                <p>                
                  SmartSeller serves as a commerce platform that equips merchants with the necessary tools and technology to establish online stores and offer products and services through their own 
                  websites, various marketplaces, and beyond. While we strongly support the principles of free and open exchange within commerce, there are certain activities that do not align with 
                  SmartSeller's mission to enhance commerce for all. This Acceptable Use Policy (<strong>"AUP"</strong>) outlines activities that are not allowed in connection with your utilization of our Services.
                </p>
                <p>
                  For definitions of capitalized terms used in this AUP, please refer to the SmartSeller AUP definitions page, accessible at https://www.sseller.io/aup-policy-definitions.
                </p>
                <p>
                  The following activities are strictly prohibited:
                </p>
                <ol>
                  <li>
                    <strong>Child exploitation:</strong> It is strictly prohibited to provide goods or services, or to share or upload <HashLink to='/aup-policy-definitions#materials'> Materials</HashLink> 
                    that exploits or endangers children, including, but not limited to, images or representations of child abuse or sexual abuse, or any content that portrays children in a sexual manner.
                  </li>
                  <li>
                    <strong>COVID-19:</strong> When offering products or services related to COVID-19, you must adhere to the <Link to='/covid-19-products-policy'> Rules of Engagement for the Sale of 
                    COVID-19 Related Products</Link>.
                  </li>
                  <li>
                    <strong>Harassment, bullying, defamation and threats:</strong> You are not allowed to offer products or services, or share or upload Materials that harasses, bullies, defames, or threatens 
                    any specific individual.           
                  </li>
                  <li>
                    <strong>Hateful content:</strong> The Services may not be used to endorse or support hate or violence towards individuals based on factors such as race, ethnicity, color, national 
                    origin, religion, age, gender, sexual orientation, disability, medical condition, veteran status, or other forms of discriminatory intolerance. Furthermore, you may not employ the 
                    Services to promote or endorse organizations, platforms, or individuals that (i) advocate or condone such hate, or (ii) incite or endorse violence to advance a cause.
                  </li>
                  <li>
                    <strong>Illegal activities:</strong> You may not provide goods or services, or share or upload Materials that violates, or facilitates or encourages activities that violate, the laws of 
                    the jurisdictions in which you operate or conduct business.
                  </li>
                  <li>
                    <strong>Intellectual property:</strong> It is not permissible to offer goods or services, or to share or upload Materials that infringes upon the copyrights or trademarks of others.
                  </li>
                  <li>
                    <strong>Malicious and deceptive practices:</strong> You may not utilize the Services to transmit malware or host phishing pages. Activities or Materials that cause harm or disruption to 
                    the functionality of the Services or other SmartSeller infrastructure or third-party providers are not allowed. Deceptive commercial practices or any other unlawful or deceitful 
                    activities are also prohibited.
                  </li>
                  <li>
                    <strong>Personal, confidential, and protected health information:</strong> Posting or uploading any Materials that contains personally identifiable information, sensitive personal data, 
                    or confidential information, such as credit card numbers, confidential national ID numbers, or account passwords, is not allowed unless you have obtained consent from the individual to 
                    whom the information belongs or who is otherwise authorized to provide such consent. The Services may not be used to collect, store, or process protected health information governed by 
                    the Health Insurance Portability and Accountability Act (“HIPAA”), any relevant health privacy regulation, or any other applicable law regarding the handling, use, or disclosure of 
                    protected health information.
                  </li>
                  <li>
                    <strong>Restricted Items:</strong> You may not provide goods or services that are, or appear to be, <HashLink to='/aup-policy-definitions#restricted'> Restricted Items</HashLink>.
                  </li>
                  <li>
                    <strong>Self-harm:</strong> It is not permissible to offer goods or services, or share or upload Materials that promotes self-harm.
                  </li>
                  <li>
                    <strong>Spam:</strong> You may not utilize the Services to send unsolicited commercial electronic messages.
                  </li>
                  <li>
                    <strong>Terrorist organizations:</strong> Providing goods or services, or sharing or uploading Materials that implies or promotes support or funding of, or membership in, a terrorist organization is prohibited.
                  </li>                  
                </ol>
                <p>
                  We reserve the right to remove any Materials, suspend, or terminate your Account or access to the Services at any time, without prior notice, if you engage in activities that contravene 
                  the guidelines outlined in this AUP, including actions outside the scope of your Service usage.
                </p>
                <p>
                  SmartSeller retains the right, though not the obligation, to monitor or investigate any Materials and your utilization of the Services at any given time to ensure compliance with this 
                  AUP and the SmartSeller Terms of Service, or any other agreement governing your use of the Services (collectively, the <strong>"Terms"</strong>). Our determination regarding the violation 
                  of this AUP will be considered final and binding, and any action taken to enforce this AUP, including the decision to take no action, is solely at our discretion.
                </p>
                <p>
                  SmartSeller may update this AUP, including the list of Restricted Items, at any time by publishing a revised version at https://www.sseller.io/aup-policy. By continuing to use the 
                  Services or access your Account after the posting of a revised AUP, you agree to adhere to the most recent version of the AUP. In case of any conflict between the AUP and the Terms, this 
                  AUP will take precedence, but only to the extent required to resolve such a conflict. Terms that are capitalized but not defined in this AUP will have the meanings specified in the Terms.
                </p>
                <p>
                  If you suspect that a user of the Services has violated this AUP, please contact us via email at support@sseller.io.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};


export default AUPPolicyDetails;
