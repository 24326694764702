import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PageMeta from '../../../components/common/PageMeta';
import BlogPostHeader from '../../../components/blog/BlogPostHeader';
import FooterOne from '../../../layout/Footer/FooterOne';
import Navbar from '../../../layout/Header/Navbar';
import Layout from '../../../layout/Layout';
import { blogPosts } from '../../../data/blogPosts.js'
import NeedStorefrontHelp from '../../../components/cta/NeedStorefrontHelp';
import { Helmet } from 'react-helmet';


const BlogPost001 = () => {

  const slug = 'google-analytics'

  const blogPost = blogPosts.find(obj => {
    return obj.slug === slug
  })

  return (
    <React.Fragment>  

      <Layout>
        <PageMeta title={`${blogPost.title} | SmartSeller Blog`}/>
        <Helmet>
          {/* Essential META Tags */}
          <meta property="og:title" content={`${blogPost.title} | SmartSeller Blog`} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={`https://sseller.io${blogPost.images[0]}`} />
          <meta property="og:url" content={`https://sseller.io${blogPost.images[0]}`} />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Non-Essential, But Recommended */}
          <meta name="description" content={blogPost.excerpt} />
          <meta property="og:description" content={blogPost.excerpt} />
          <meta property="og:site_name" content="SmartSeller"/>
          <meta name="twitter:image:alt" content={`${blogPost.title} | SmartSeller Blog`} />
          {/* Non-Essential, But Required for Analytics */}
          {/* <meta property="fb:app_id" content="your_app_id" />
          <meta name="twitter:site" content="@website-username" /> */}
        </Helmet>
        <Navbar navDark posAbsolute />
        <BlogPostHeader title={blogPost.title} categories={blogPost.categories} readTime={blogPost.readTime} updatedDate={blogPost.updatedDateString}/>
        <section className='blog-details pt-60 pb-120'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-lg-8 pe-5'>
                <div className='blog-details-wrap'>
                  <img
                    src={blogPost.images[0]}
                    className='img-fluid mb-4 rounded-custom'
                    alt='apply'
                  />
                  <p>
                    Google Analytics, a complimentary service provided by Google, furnishes comprehensive statistics regarding your store's clientele. It provides insights into their origin, 
                    highlights their preferences, and, most importantly, sheds light on their browsing and purchasing behaviors. Armed with this valuable information, you can make informed 
                    decisions about your website's content, its structure and presentation, and the most effective marketing strategies. Embracing real data empowers your decision-making process 
                    like nothing else!
                  </p>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='setup'>Setup</h2>
                    <p>
                      Setting up Google Analytics is a straightforward process, and you won't have to delve into any code! Just adhere to these steps:  
                    </p>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h3 className='h5'>Create and link your account</h3>
                    <ol>
                      <li>
                        <Link to='https://marketingplatform.google.com/about/analytics/' target='_blank'>Create your Google Analytics account.</Link>
                      </li>
                      <li>
                        Follow the steps to create your property and <strong>copy the tracking ID.</strong> Ex: 
                        <pre style={{ 'backgroundColor': '#eee', 'borderRadius': '3px', 'border': '1px ', 'display': 'block', 'padding': '10px'}}>
                          <code>
                            G-XXXXXXXXXX
                         </code>
                        </pre>
                      </li>
                      <li>
                        Navigate to the <strong>Store Configuration</strong> section in your SmartSeller Admin.
                      </li>
                      <li>
                        Paste the Google Analytics tracking ID into the <strong>Google Analytics Tracking Id</strong> field. 
                      </li>
                      <li>
                        Click <strong>Save</strong>.
                      </li>
                    </ol>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h3 className='h5'>Set up cross-domain measurement</h3>
                    <iframe src={blogPost.videos[0]}
                      frameBorder='0'
                      allow='autoplay; encrypted-media'
                      allowFullScreen
                      title='video'
                      width="100%"
                      height="453"
                      className='rounded-custom'
                    />
                    <ol>
                      <li>
                        Navigate to the <strong>Admin</strong> section of your Google Analytics account.
                      </li>
                      <li>
                        Click on <strong>Data Streams</strong> under the Property settings.
                      </li>
                      <li>
                        Select the data stream corresponding to your SmartSeller storefront.
                      </li>
                      <li>
                        Select <strong>Configure tag settings</strong> &gt; <strong>Configure your domains</strong> and then click <strong>Add Condition</strong>.
                      </li>
                      <li>
                        Add your domain by setting <strong>Exactly matches</strong> and entering your storefront's domain (e.g., www.mydomain.com) in the Domain field.
                      </li>
                      <li>
                        Click Save.
                      </li>
                    </ol>
                    <p>
                      Congratulations! Your store's statistics will now be automatically sent to Google Analytics.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='author-wrap  bg-light p-5 sticky-sidebar rounded-custom mt-5 mt-lg-0'>
                  <div className='mb-4 text-left'>
                    <div className='author-info'>
                      <h5 className='mb-0'>In This Post</h5>
                    </div>
                    <ul>
                      {blogPost.menu.map((data) => (
                        <li key={`sidebar-${blogPost.id}-${data}`}>
                          <HashLink to={`#${data.id}`} className='text-muted text-decoration-none py-1 d-block'>{data.label}</HashLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <hr className='mb-4'></hr>
                  <div className='text-center'>
                    <img
                      src={blogPost.author.image}
                      alt='author'
                      width='100'
                      className='img-fluid shadow-sm rounded-circle mb-4'
                    />
                    <div className='author-info'>
                      <h5 className='mb-0'>{blogPost.author.name}</h5>
                      <span className='small'>{blogPost.author.title}</span>
                    </div>
                    <ul className='list-unstyled author-social-list list-inline mb-0'>
                      {
                        blogPost.author.social.facebook 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.facebook} target='_blank'>
                                  <i className='fab fa-facebook'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.instagram
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.instagram} target='_blank'>
                                  <i className='fab fa-instagram'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.linkedIn 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.linkedIn} target='_blank'>
                                  <i className='fab fa-linkedin-in'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.gitHub 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.gitHub} target='_blank'>
                                  <i className='fab fa-github'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.gitLab
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.gitLab} target='_blank'>
                                  <i className='fab fa-gitlab'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.twitter
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.twitter} target='_blank'>
                                  <i className='fab fa-twitter'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NeedStorefrontHelp />
        <FooterOne
          style={{
            background:
              "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
          }}
        />
      </Layout>
    </React.Fragment>
  );
};

export default BlogPost001;
