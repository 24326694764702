const Eduardo = {
    name: 'Eduardo Guerra de Castro',
    title: 'Team Member',
    image: '/assets/img/authors/author_eduardo.jpeg',
    social: {
        facebook: null,
        instagram: null,
        linkedIn: 'https://www.linkedin.com/in/eduardogdc',
        gitHub: 'https://github.com/eduardogdc',
        gitLab: 'https://gitlab.com/eduardogdc',   
        twitter: null,
    }
};


const Richard = {
    name: 'Richard Larancuente',
    title: 'Team Member',
    image: '/assets/img/authors/author_richard.jpeg',
    social: {
        facebook: null,
        instagram: null,
        linkedIn: 'https://www.linkedin.com/in/richard-larancuente',
        gitHub: 'https://github.com/turtleboy00',
        gitLab: 'https://gitlab.com/turtleboy00',    
        twitter: null,
    }
};


const SmartSeller = {
    name: 'SmartSeller',
    title: 'Team Member',
    image: '/assets/img/authors/author_smartseller.png',
    social: {
        facebook: null,
        instagram: 'https://www.instagram.com/smartseller.io/',
        linkedIn: 'https://www.linkedin.com/company/98511991/',
        gitHub: null,
        gitLab: null,    
        twitter: null,
    }
};


export { Eduardo, Richard, SmartSeller };