import React from 'react';
import SubprocessorListHeader from '../../components/subprocessor-list/SubprocessorListHeader';
import PageMeta from '../../components/common/PageMeta';
import SubprocessorListDetails from '../../components/subprocessor-list/SubprocessorListDetails';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const SubprocessorList = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar navDark />
      <SubprocessorListHeader />
      <SubprocessorListDetails />
      <FooterOne
        style={{
          background:
            "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default SubprocessorList;