import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PageMeta from '../../common/PageMeta.js';
import BlogPostHeader from '../BlogPostHeader.js';
import FooterOne from '../../../layout/Footer/FooterOne.js';
import Navbar from '../../../layout/Header/Navbar.js';
import Layout from '../../../layout/Layout.js';
import { blogPosts } from '../../../data/blogPosts.js'
import NeedStorefrontHelp from '../../cta/NeedStorefrontHelp.js';
import { Helmet } from 'react-helmet';


const BlogPost005 = () => {

  const slug = 'why-you-need-a-personalized-domain'

  const blogPost = blogPosts.find(obj => {
    return obj.slug === slug
  })

  return (
    <React.Fragment>  

      <Layout>
        <PageMeta title={`${blogPost.title} | SmartSeller Blog`}/>
        <Helmet>
          {/* Essential META Tags */}
          <meta property="og:title" content={`${blogPost.title} | SmartSeller Blog`} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={`https://sseller.io${blogPost.images[0]}`} />
          <meta property="og:url" content={`https://sseller.io${blogPost.images[0]}`} />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Non-Essential, But Recommended */}
          <meta name="description" content={blogPost.excerpt} />
          <meta property="og:description" content={blogPost.excerpt} />
          <meta property="og:site_name" content="SmartSeller"/>
          <meta name="twitter:image:alt" content={`${blogPost.title} | SmartSeller Blog`} />
          {/* Non-Essential, But Required for Analytics */}
          {/* <meta property="fb:app_id" content="your_app_id" />
          <meta name="twitter:site" content="@website-username" /> */}
        </Helmet>
        <Navbar navDark posAbsolute />
        <BlogPostHeader title={blogPost.title} categories={blogPost.categories} readTime={blogPost.readTime} updatedDate={blogPost.updatedDateString}/>
        <section className='blog-details pt-60 pb-120'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-lg-8 pe-5'>
                <div className='blog-details-wrap'>
                  <img
                    src={blogPost.images[0]}
                    className='img-fluid mb-4 rounded-custom'
                    alt='apply'
                  />
                  <p>
                    Your personalized URL, known as a custom domain, serves as a unique web address for showcasing your shop. When establishing a timeless and memorable brand, this detail is crucial 
                    to ponder. It's noteworthy that all SmartSeller stores have the ability to connect a custom domain to their store.
                  </p>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='authentic'>Be Distinctive, Be Authentic</h2>
                    <p>
                      Utilizing a custom domain stands out as one of the most effective strategies for claiming your brand's presence on the internet. It not only simplifies the process for customers 
                      to locate, revisit, and share the fantastic products in your shop but also provides the opportunity to enhance professionalism through email services. Imagine customers receiving 
                      emails from you@yourstore.com instead of celebritysuperfan42@hotmail.com - it adds a more trustworthy touch.
                    </p>
                    <p>
                      Moreover, a custom domain can be featured on business cards, thereby elevating your brand identity. If you already possess a domain, consider using a subdomain, such as 
                      store.mydomain.com, seamlessly integrating your store without affecting your existing website. Shoppers won't even realize it's a separate entity. In the event that your current 
                      domain provider falls short of your expectations, a transfer is possible with a bit of prior notice.
                    </p>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='quality'>Affordability Doesn't Always Guarantee Quality</h2>
                    <p>
                      When it comes to custom domain providers, the market offers numerous options. Before committing to a service, conducting some research is essential. Take a close look at the 
                      features provided with domain registration, the transfer policy (in case you consider switching to a new provider later), and the ability to edit DNS records for setting up a 
                      redirect. To use your custom domain with your SmartSeller shop, it's crucial to be able to establish a redirect.
                    </p>
                    <p>
                      While opting for a cheaper option may seem appealing, especially when just starting out, it's important to consider that it could lead to more challenges and additional costs 
                      in the future. Exercise prudence in choosing a Domain Name Provider to ensure your hard-earned money is wisely spent.
                    </p>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='preferred'>Our Preferred Domain Name Providers</h2>
                    <p>
                      <Link to='https://www.namecheap.com/' target='_blank'>Namecheap</Link> stands out as a go-to choice for excellent service and customer support, and we can personally attest to 
                      the ease of their setup process.
                    </p>
                    <p>
                      For quick and easy setup integrations, <Link to='https://iwantmyname.com/' target='_blank'>IWantMyName</Link> and <Link to='https://www.hover.com/' target='_blank'>Hover</Link> are 
                      also worth considering. Both are reliable options that we've had positive experiences with them.
                    </p>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='choosing'>Choosing An Exceptional Custom Domain</h2>
                    <p>
                      The decision is yours, but here are a few tips to keep in mind when selecting your unique custom domain:
                    </p>
                    <ul>
                      <li>
                        Keep it concise, memorable, and straightforward.
                      </li>
                      <li>
                        Exercise caution with abbreviations, acronyms, or unconventional spellings. What might seem stylish in your store header could appear confusing in the browser address bar, 
                        so it's advisable to test it yourself to ensure it looks good.
                      </li>
                      <li>
                        If your desired URL is already taken, don't worry - most domain providers will suggest alternative options that may be available, such as .net, .co, .org, and numerous others.
                      </li>
                      <li>
                        If the top-level domain (TLD) you initially wanted is unavailable, consider appending "shop" or "store" to your domain. For example, opt for www.exampleshop.com instead of www.example.com.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='author-wrap  bg-light p-5 sticky-sidebar rounded-custom mt-5 mt-lg-0'>
                  <div className='mb-4 text-left'>
                    <div className='author-info'>
                      <h5 className='mb-0'>In This Post</h5>
                    </div>
                    <ul>
                      {blogPost.menu.map((data) => (
                        <li key={`sidebar-${blogPost.id}-${data}`}>
                          <HashLink to={`#${data.id}`} className='text-muted text-decoration-none py-1 d-block'>{data.label}</HashLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <hr className='mb-4'></hr>
                  <div className='text-center'>
                    <img
                      src={blogPost.author.image}
                      alt='author'
                      width='100'
                      className='img-fluid shadow-sm rounded-circle mb-4'
                    />
                    <div className='author-info'>
                      <h5 className='mb-0'>{blogPost.author.name}</h5>
                      <span className='small'>{blogPost.author.title}</span>
                    </div>
                    <ul className='list-unstyled author-social-list list-inline mb-0'>
                      {
                        blogPost.author.social.facebook 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.facebook} target='_blank'>
                                  <i className='fab fa-facebook'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.instagram
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.instagram} target='_blank'>
                                  <i className='fab fa-instagram'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.linkedIn 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.linkedIn} target='_blank'>
                                  <i className='fab fa-linkedin-in'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.gitHub 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.gitHub} target='_blank'>
                                  <i className='fab fa-github'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.gitLab
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.gitLab} target='_blank'>
                                  <i className='fab fa-gitlab'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.twitter
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.twitter} target='_blank'>
                                  <i className='fab fa-twitter'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NeedStorefrontHelp />
        <FooterOne
          style={{
            background:
              "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
          }}
        />
      </Layout>
    </React.Fragment>
  );
};

export default BlogPost005;