import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PageMeta from '../../common/PageMeta.js';
import BlogPostHeader from '../BlogPostHeader.js';
import FooterOne from '../../../layout/Footer/FooterOne.js';
import Navbar from '../../../layout/Header/Navbar.js';
import Layout from '../../../layout/Layout.js';
import { blogPosts } from '../../../data/blogPosts.js'
import NeedStorefrontHelp from '../../cta/NeedStorefrontHelp.js';
import { Helmet } from 'react-helmet';


const BlogPost007 = () => {

  const slug = 'elevate-your-stores-potential-with-google-domains-and-analytics'

  const blogPost = blogPosts.find(obj => {
    return obj.slug === slug
  })

  return (
    <React.Fragment>  

      <Layout>
        <PageMeta title={`${blogPost.title} | SmartSeller Blog`}/>
        <Helmet>
          {/* Essential META Tags */}
          <meta property="og:title" content={`${blogPost.title} | SmartSeller Blog`} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={`https://sseller.io${blogPost.images[0]}`} />
          <meta property="og:url" content={`https://sseller.io${blogPost.images[0]}`} />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Non-Essential, But Recommended */}
          <meta name="description" content={blogPost.excerpt} />
          <meta property="og:description" content={blogPost.excerpt} />
          <meta property="og:site_name" content="SmartSeller"/>
          <meta name="twitter:image:alt" content={`${blogPost.title} | SmartSeller Blog`} />
          {/* Non-Essential, But Required for Analytics */}
          {/* <meta property="fb:app_id" content="your_app_id" />
          <meta name="twitter:site" content="@website-username" /> */}
        </Helmet>
        <Navbar navDark posAbsolute />
        <BlogPostHeader title={blogPost.title} categories={blogPost.categories} readTime={blogPost.readTime} updatedDate={blogPost.updatedDateString}/>
        <section className='blog-details pt-60 pb-120'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-lg-8 pe-5'>
                <div className='blog-details-wrap'>
                  <img
                    src={blogPost.images[0]}
                    className='img-fluid mb-4 rounded-custom'
                    alt='apply'
                  />
                  <p>
                    Presenting a polished and professional image for your business is crucial. This doesn't entail sacrificing your personality or pretending to be larger than you are. Instead, it 
                    involves incorporating a touch of professionalism into your presentation and utilizing data-driven insights to inform decisions. Google provides tools seamlessly integrated with 
                    your SmartSeller store, empowering you with enhanced capabilities. Ready to elevate your online shop to new heights?
                  </p>

                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='identity'>Unique Domain Identity</h2>
                    <p>
                      A standout feature of SmartSeller as an ecommerce platform is the autonomy it offers in shaping how your business presents itself to the world. Unlike confining your shop within 
                      the rigid structure of a marketplace, we encourage your customers to proudly associate their purchases with your shop's name, not a platform.
                    </p>
                    <p>
                      One effective way to enhance brand recognition is by <Link to='/blog-post/utilize-a-personalized-domain'>utilizing a custom domain</Link>. This not only simplifies and shortens the URL but also places your brand at the forefront. 
                    </p>
                  </div>
                  <div className='blog-details-info mb-4'>
                    <h2 className='h2' id='analytics'>Analytics</h2>
                    <p>
                      Understanding your customers - where they discover you, their preferred hangouts, and the products that capture their interest—is crucial for informed business decisions. 
                      Connecting to Google Analytics facilitates data-driven decision-making for your SmartSeller store.
                    </p>
                    <p>
                      Upon successfully linking your shop to Google Analytics <Link to='/blog-post/google-analytics'>(instructions provided here)</Link>, you can leverage the data to guide your decisions. 
                      For instance, if Wednesdays see the highest traffic to your store, it might be the optimal day for your next product launch. If a specific website drives significant traffic to your 
                      store, consider testing advertising on that platform. Identifying products with high views but lower purchases? Experiment with remixing the design or trying it on a different item 
                      to gauge the impact.
                    </p>
                    <p>
                      While Google Analytics offers a wealth of information, it's common to feel overwhelmed initially. <Link to='/blog-post/creating-a-toolkit-for-google-analytics'>This toolkit</Link> 
                      assists you in examining a few key data points to start. Focus on smaller, iterative changes to better understand the effects of each tweak, as growing a business is a gradual process.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='author-wrap  bg-light p-5 sticky-sidebar rounded-custom mt-5 mt-lg-0'>
                  <div className='mb-4 text-left'>
                    <div className='author-info'>
                      <h5 className='mb-0'>In This Post</h5>
                    </div>
                    <ul>
                      {blogPost.menu.map((data) => (
                        <li key={`sidebar-${blogPost.id}-${data}`}>
                          <HashLink to={`#${data.id}`} className='text-muted text-decoration-none py-1 d-block'>{data.label}</HashLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <hr className='mb-4'></hr>
                  <div className='text-center'>
                    <img
                      src={blogPost.author.image}
                      alt='author'
                      width='100'
                      className='img-fluid shadow-sm rounded-circle mb-4'
                    />
                    <div className='author-info'>
                      <h5 className='mb-0'>{blogPost.author.name}</h5>
                      <span className='small'>{blogPost.author.title}</span>
                    </div>
                    <ul className='list-unstyled author-social-list list-inline mb-0'>
                      {
                        blogPost.author.social.facebook 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.facebook} target='_blank'>
                                  <i className='fab fa-facebook'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.instagram
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.instagram} target='_blank'>
                                  <i className='fab fa-instagram'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.linkedIn 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.linkedIn} target='_blank'>
                                  <i className='fab fa-linkedin-in'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.gitHub 
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.gitHub} target='_blank'>
                                  <i className='fab fa-github'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.gitLab
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.gitLab} target='_blank'>
                                  <i className='fab fa-gitlab'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                      {
                        blogPost.author.social.twitter
                          ? (
                              <li className='list-inline-item'>
                                <Link to={blogPost.author.social.twitter} target='_blank'>
                                  <i className='fab fa-twitter'></i>
                                </Link>
                              </li>    
                            )
                          : (
                              <></>
                            )
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NeedStorefrontHelp />
        <FooterOne
          style={{
            background:
              "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
          }}
        />
      </Layout>
    </React.Fragment>
  );
};

export default BlogPost007;