import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const SubprocessorListDetails = () => {
  return (
    <>
      <section className='support-content ptb-60'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-8 p-lg-8'>

              <div className='support-article-wrap mb-5'>
                <h1 className='display-5 mb-4 fw-bold'>
                  Subprocessors
                </h1>
                <p>
                  Customer personal data is initially processed by SmartSeller, Inc. (United States). SmartSeller involves subprocessors as per the terms outlined in our <Link to='/data-processing-addendum'> 
                  Data Processing Addendum</Link>. For more details, please reach out to support@sseller.io.
                </p>
                <p>
                  Core subprocessors are indispensable for providing our services. Additional subprocessors may handle Customer personal data if a merchant utilizes supplementary services. 
                </p>
              </div>

              <div className='support-article-wrap mb-5'>
                <h4 className='mb-4 fw-bold'>
                  Core SmartSeller subprocessors
                </h4>
                <div className="table-responsive table-card">
                  <table className="table table-centered align-middle mb-0">
                    <thead className="text-muted table-light">
                      <tr>
                        <th scope="col">Subprocessor</th>
                        <th scope="col">Services Provided</th>
                        <th scope="col">Corporate Headquarters</th>
                        <th scope="col">Data Processed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>SmartSeller, Inc.</td>
                        <td>Providing and optimizing the SmartSeller platform along with its associated services.</td>
                        <td>USA</td>
                        <td>Data from the platform is accessed as required to deliver platform functionality and customer service, in accordance with the details outlined in the 
                          <Link to='/privacy-policy'> Privacy Policy</Link>.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='support-article-wrap mb-5'>
                <h1 className='display-5 mb-4 fw-bold'>
                  Third-party subprocessors
                </h1>
                <p>
                  SmartSeller may enlist third-party subprocessors to facilitate our services. Core subprocessors are indispensable for our service provision, while additional subprocessors may come 
                  into play when extra services are utilized. The transfer of data to third-party subprocessors is executed in accordance with contractual agreements.
                </p>
              </div>

              <div className='support-article-wrap mb-5'>
                <h4 className='mb-4 fw-bold'>
                  Core third-party subprocessors
                </h4>
                <div className="table-responsive table-card">
                  <table className="table table-centered align-middle mb-0">
                    <thead className="text-muted table-light">
                      <tr>
                        <th scope="col">Subprocessor</th>
                        <th scope="col">Services Provided</th>
                        <th scope="col">Corporate Headquarters</th>
                        <th scope="col">Data Processed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Digital Ocean</td>
                        <td>Cloud hosting</td>
                        <td>USA</td>
                        <td>All platform data</td>
                      </tr>
                      <tr>
                        <td>Google Maps</td>
                        <td>Mapping</td>
                        <td>USA</td>
                        <td>Personal data necessary for mapping</td>
                      </tr>
                      <tr>
                        <td>Google Cloud Platform</td>
                        <td>Cloud hosting</td>
                        <td>USA</td>
                        <td>All platform data</td>
                      </tr>
                      <tr>
                        <td>Mapbox</td>
                        <td>Mapping</td>
                        <td>USA</td>
                        <td>Personal data necessary for mapping</td>
                      </tr>
                      <tr>
                        <td>PayPal</td>
                        <td>Payments</td>
                        <td>USA</td>
                        <td>Personal data necessary for processing payments</td>
                      </tr>
                      <tr>
                        <td>Stripe</td>
                        <td>Payments</td>
                        <td>USA</td>
                        <td>Personal data necessary for processing payments</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='support-article-wrap mb-5'>
                <h4 className='mb-4 fw-bold'>
                  Additional third-party subprocessors
                </h4>
                <div className="table-responsive table-card">
                  <table className="table table-centered align-middle mb-0">
                    <thead className="text-muted table-light">
                      <tr>
                        <th scope="col">Subprocessor</th>
                        <th scope="col">Services Provided</th>
                        <th scope="col">Corporate Headquarters</th>
                        <th scope="col">Data Processed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Bringer Parcel Services</td>
                        <td>Fulfillment and shipping services</td>
                        <td>USA</td>
                        <td>Personal data to provide shipping and fulfillment services</td>
                      </tr>
                      <tr>
                        <td>EasyPost</td>
                        <td>Fulfillment and shipping services</td>
                        <td>USA</td>
                        <td>Personal data to provide shipping and fulfillment services</td>
                      </tr>
                      <tr>
                        <td>SendGrid</td>
                        <td>External communications</td>
                        <td>USA</td>
                        <td>Personal data necessary to provide email functionality</td>
                      </tr>
                      <tr>
                        <td>Twilio</td>
                        <td>External communications</td>
                        <td>USA</td>
                        <td>Personal data necessary to provide SMS functionality</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};


export default SubprocessorListDetails;
