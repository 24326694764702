import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const FooterOne = ({ footerLight, style, footerGradient }) => {
  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${
            footerGradient ? 'bg-gradient' : ''
          }  text-white ptb-60`}
          style={style}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
                <div className='footer-single-col'>
                  <div className='footer-single-col mb-4'>
                    <img
                      src='/assets/img/logo-white.png'
                      alt='logo'
                      className='img-fluid logo-white'
                    />
                    <img
                      src='/assets/img/logo-color.png'
                      alt='logo'
                      className='img-fluid logo-color'
                    />
                  </div>
                  <p>
                    Contact us at support@sseller.io
                  </p>

                  {/* <form className='newsletter-form position-relative d-block d-lg-flex d-md-flex'>
                    <input
                      type='text'
                      className='input-newsletter form-control me-2'
                      placeholder='Enter your email'
                      name='email'
                      required=''
                      autoComplete='off'
                    />
                    <input
                      type='submit'
                      value='Subscribe'
                      data-wait='Please wait...'
                      className='btn btn-primary mt-3 mt-lg-0 mt-md-0'
                    />
                  </form> */}
                  {/* <div className='ratting-wrap mt-4'>
                    <h6 className='mb-0'>10/10 Overall rating</h6>
                    <ul className='list-unstyled rating-list list-inline mb-0'>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className='col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0'>
                <div className='row'>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>New to SmartSeller?</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        {/* <li>
                          <Link to='/' className='text-decoration-none'>
                            What is SmartSeller?
                          </Link>
                        </li>
                        <li>
                          <Link to='/about-us' className='text-decoration-none'>
                            Explore All Modules
                          </Link>
                        </li>
                        <li>
                          <Link to='/services' className='text-decoration-none'>
                            Customer Success
                          </Link>
                        </li> */}
                        <li>
                          <HashLink to='/#pricing' className='text-decoration-none'>Pricing</HashLink>
                        </li>
                        <li>
                          <Link to='/integrations' className='text-decoration-none'>Integrations</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>About SmartSeller</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        {/* <li>
                          <HashLink to='/#pricing' className='text-decoration-none'>
                            Our Story
                          </HashLink>
                      </li> */}
                        <li>
                          <Link to='/blog' className='text-decoration-none'>Blog</Link>
                        </li>
                        {/*<li>
                          <Link
                            to='/blog-single'
                            className='text-decoration-none'
                          >
                            Investors
                          </Link>
                        </li> */}
                        <li>
                          <Link to='/privacy-policy' className='text-decoration-none'>Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to='/terms-of-use' className='text-decoration-none'>Terms of Use</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Popular Links</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <HashLink to='/#features' className='text-decoration-none'>Features</HashLink>
                        </li>
                        <li>
                          <HashLink to='/#solutions' className='text-decoration-none'>Solutions</HashLink>
                        </li>

                        {/* <li>
                          <Link
                            to='/contact-us'
                            className='text-decoration-none'
                          >
                            New Release Features
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/help-center'
                            className='text-decoration-none'
                          >
                            Become a Partner
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/help-center-single'
                            className='text-decoration-none'
                          >
                            ?
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/coming-soon'
                            className='text-decoration-none'
                          >
                            ?
                          </Link>
                        </li>
                        <li>
                          <Link to='/login' className='text-decoration-none'>
                            Newsletter Sign-Up
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${
            footerLight ? 'footer-light' : 'bg-dark'
          } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
        >
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    &copy; 2023 SmartSeller, Inc. All Rights Reserved.
                  </p>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='footer-single-col text-start text-lg-end text-md-end'>
                  <ul className='list-unstyled list-inline footer-social-list mb-0'>
                    <li className='list-inline-item'>
                      <Link to='/#'>
                        <i className='fab fa-facebook-f'></i>
                      </Link>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='https://www.instagram.com/smartseller.io/' target='_blank'>
                        <i className='fab fa-instagram'></i>
                      </Link>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='https://www.linkedin.com/company/sseller' target='_blank'>
                        <i className='fab fa-linkedin'></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
