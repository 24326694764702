import React from 'react';
import TermsOfUseHeader from '../../components/terms-of-use/TermsOfUseHeader';
import PageMeta from '../../components/common/PageMeta';
import TermsOfUseDetails from '../../components/terms-of-use/TermsOfUseDetails';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const TermsOfUse = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar navDark />
      <TermsOfUseHeader />
      <TermsOfUseDetails />
      <FooterOne
        style={{
          background:
            "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default TermsOfUse;