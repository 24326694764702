import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BlogPagination from './BlogPagination';
import { blogPosts } from '../../data/blogPosts.js'


const BlogGrid = () => {


  const blogPostsPerPage = 24
  const [currentPage, setCurrentPage] = useState(1)
  const [blogPostStartIndex, setBlogPostStartIndex] = useState(0)
  const [blogPostEndIndex, setBlogPostEndIndex] = useState(blogPostStartIndex + blogPostsPerPage)

  const [blogPostList, setBlogPostList] = useState(blogPosts.slice(blogPostStartIndex, blogPostEndIndex))

  return (
    <React.Fragment>
      <section className='masonary-blog-section pt-60 pb-120'>
        <div className='container'>

          <div className='row'>
            {blogPostList.map((blogPost) => (

              <div className='col-lg-4 col-md-6' key={`post-${blogPost.id}`}>
                <div className='single-article rounded-custom my-3'>
                  <Link to={`/blog-post/${blogPost.slug}`} className='article-img'>
                    <img
                      src={blogPost.images[0]}
                      alt='article'
                      className='img-fluid'
                    />
                  </Link>
                  <div className='article-content p-4'>
                    {blogPost.categories ? (
                      <div className='article-category mb-4 d-block'>
                        {blogPost.categories.map((data) => (
                          <Link
                            key={`${blogPost.id}-${data}`}
                            to='#'
                            className='d-inline-block text-dark badge bg-primary-soft me-2'
                          >
                            {data}
                          </Link>
                        ))}
                    </div>
                    ) : (
                      ''
                    )}          
                    <Link to={`/blog-post/${blogPost.slug}`}>
                      <h2 className='h5 article-title limit-1-line-text'>
                        {blogPost.title}
                      </h2>
                    </Link>
                    {/* <p className='limit-3-line-text'>
                      {blogPost.excerpt}
                    </p> */}

                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src={blogPost.author.image}
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>{blogPost.author.name}</h6>
                        <span className='small fw-medium text-muted'>
                          {blogPost.updatedDateString}
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            ))}

          </div>
          {/* <BlogPagination /> */}
        </div>
      </section>
    </React.Fragment>
  );
};

export default BlogGrid;
