import React from 'react';
import AuthorCard from './AuthorCard';

// {
//   id: 1,
//   author: SmartSeller,
//   categories: [
//       'Store Management',
//       'Setup',
//       'Analytics'
//   ],
                  // src='/assets/img/tab-feature-img-4.png'
//   updatedDate: '2023-12-14',
//   readLength: '4 min',
//   title: 'Google Analytics',
//   slug: 'google-analytics',
//   excerpt: 'Some excerpt',

//   content: [
//       {
//           id: 1,
//           type: 'text',
//           value: `<h4>Setup</h4><p>Setting up Google Analytics isn\'t too tricky and you won\'t need to mess with any code! Simply follow these steps:</p>`
//       },
//       {
//           id: 2,
//           type: 'text',
//           value: `<h4>Create and link your account</h4>
//           <ol>
//           <li>Create your Google Analytics account</li>
//           <li>
//               Follow the steps to create your property and copy the tracking ID. Ex: 
//               <code>G-XXXXXXXXXX</code>
//           </li>`
//       },
//   ],

//   menu: [
//       {
//           id: 'setup',
//           label: 'Setup',
//       }
//   ]
// }


// const BlogDetails = ({blogPost}) => {
const BlogDetails = () => {

  const blogPost = {
    id: 1,
    author: {
      name: 'SmartSeller Team',
      title: '',
      social: {
        linkedIn: null,
        gitHub: null,
        gitLab: null,  
      }
    },
    categories: [
        'Store Management',
        'Setup',
        'Analytics'
    ],
    images: [
      '/assets/img/blog/google-analytics.jpg'
    ],
    updatedDate: '2023-12-14',
    readLength: '4 min',
    title: 'Google Analytics',
    slug: 'google-analytics',
    excerpt: 'Some excerpt',  
    content: [
      {
          id: 1,
          type: 'text',
          value: `<h4>Setup</h4><p>Setting up Google Analytics isn\'t too tricky and you won\'t need to mess with any code! Simply follow these steps:</p>`
      },
      {
          id: 2,
          type: 'text',
          value: `<h4>Create and link your account</h4>
          <ol>
          <li>Create your Google Analytics account</li>
          <li>
              Follow the steps to create your property and copy the tracking ID. Ex: 
              <code>G-XXXXXXXXXX</code>
          </li>`
      },
    ],
    menu: [
      {
          id: 'setup',
          label: 'Setup',
      }
    ]
  }

  return (
    <>
      <section className='blog-details pt-60 pb-120'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-8 pe-5'>
              <div className='blog-details-wrap'>
                <img
                  src={blogPost.images[0]}
                  className='img-fluid mb-4 rounded-custom'
                  alt='apply'
                />
                <p>
                  Google Analytics is a free service offered by Google that gives detailed stats about your store's customers, where they're coming from, and more importantly what 
                  they're looking at and buying. Having this info allows you to make key choices about your site's content, how it's organized and displayed, and how to best market it. 
                  There's nothing quite like real data to back the decisions you make!
                </p>
                <div className='blog-details-info mb-4'>
                  <h2 className='h2' id='setup'>Setup</h2>
                  <p>
                    Setting up Google Analytics isn't too tricky and you won't need to mess with any code! Simply follow these steps:
                  </p>
                </div>
                <div className='blog-details-info mb-4'>
                  <h3 className='h5'>Create and link your account</h3>
                  <ol>
                    <li>
                      Create your Google Analytics account. https://marketingplatform.google.com/about/analytics/
                    </li>
                    <li>
                      Follow the steps to create your property and <strong>copy the tracking ID.</strong> Ex: 
                      <pre style={{ 'backgroundColor': '#eee', 'borderRadius': '3px', 'border': '1px ', 'display': 'block', 'padding': '10px'}}>
                          <code>
                            G-XXXXXXXXXX
                         </code>
                      </pre>
                    </li>
                    <li>
                      Head over to the Account page in your Big Cartel admin, and click to add Google Analytics in the Your shop section.
                    </li>
                    <li>
                      Paste that tracking ID into the <strong>Google Analytics</strong> field. Hit <strong>Save</strong>.
                    </li>
                  </ol>
                </div>
                <div className='blog-details-info mb-4'>
                  <h3 className='h5'>Set up cross-domain measurement</h3>
                  <iframe src='https://www.youtube.com/embed/E7wJTI-1dvQ'
                    frameBorder='0'
                    allow='autoplay; encrypted-media'
                    allowFullScreen
                    title='video'
                    width="100%"
                    height="453"
                    className='rounded-custom'
                    />
                  <ol>
                    <li>
                      From the <strong>Admin</strong> section of your GA account, click <strong>Data Streams</strong> under the Property setting and select the data stream for your Big Cartel shop.
                    </li>
                    <li>
                      Select <strong>Configure tag settings</strong> - <strong>Configure your domains</strong> and then click <strong>Add Condition</strong>.
                    </li>
                    <li>
                      Now you'll add your conditions:
                        <ul>
                          <li>
                            If you are using your Big Cartel URL (e.g.- myshop.bigcartel.com), enter Exactly matches in the Match type field and then your Big Cartel URL in the Domain field.
                          </li>
                          <li>
                            If you are using a custom domain, you'll add two conditions. First, enter Exactly matches in the Match type field and then your Big Cartel URL in the Domain field. Then you'll add another domain by entering Exactly matches in the Match type field and then your custom domain (www.mycustomdomain.com) in the Domain field.
                          </li>
                        </ul>
                    </li>
                    <li>
                      Click Save.
                    </li>
                  </ol>
                  <p>
                    Done! All of your store's stats will now be sent to Google Analytics automatically.
                  </p>
                </div>


              </div>
            </div>
            <div className='col-lg-4'>
              <AuthorCard author={blogPost.author} menu={blogPost.menu}/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetails;
