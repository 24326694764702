import React from 'react';

const GetStartedContent = ({ reqPage }) => {
  return (
    <>
      <div className='col-xl-5 col-lg-5 col-md-12 order-1 order-lg-0'>
        <div className='testimonial-tab-slider-wrap'>
          {reqPage ? (
            <h1 className='fw-bold text-white display-5'>
              Start Your Project with Us
            </h1>
          ) : (
            <h2 className='text-white'>Start Your Project with Us</h2>
          )}
          <p>
            Our team will help you get started with:
          </p>
          <hr />
          <div className="position-relative single-pricing-wrap rounded-custom">
            <ul className="pricing-feature-list list-unstyled">
              <li>
                <i className="far fa-check-circle text-success me-2"></i>
                Configure your storefronts and sales channels
              </li>
              <li>
                <i className="far fa-check-circle text-success me-2"></i>
                Create your staff users
              </li>
              <li>
                <i className="far fa-check-circle text-success me-2"></i>
                Upload your products and inventory data
              </li>
              <li>
                <i className="far fa-check-circle text-success me-2"></i>
                Enabling markets for you to sell in
              </li>
              <li>
                <i className="far fa-check-circle text-success me-2"></i>
                Configure your carrier accounts
              </li>
              <li>
                <i className="far fa-check-circle text-success me-2"></i>
                Configure your integrations
              </li>
              <li>
                <i className="far fa-check-circle text-success me-2"></i>
                Provide training and onboard your team
              </li>
            </ul>
          </div> 
        </div>
      </div>
    </>
  );
};

export default GetStartedContent;
