import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const FeatureHighlight = () => {
  return (
    <>
      <section className='feature-promo pt-100 pb-80 bg-light'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-10 col-md-10'>
              <SectionTitle
                title='Find innovations for your type of ecommerce.'
                description='No matter what you sell, we offer solutions to modernize your business, optimize processes, and lower costs.'
                centerAlign
              />
            </div>
          </div>
          <div className='row justify-content-center mt-n5'>
            <div className='col-lg-2 col-md-6 text-center'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
                <div className='feature-icon icon-center pb-5 rounded-custom bg-primary'>
                  <i className='fal fa-tshirt fa-2x text-white'></i>
                </div>
                <div className='feature-info-wrap'>
                  <h3 className='h5'>Apparel</h3>
                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-6'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
                <div className='feature-icon icon-center pb-5 rounded-custom bg-primary'>
                  <i className='fal fa-pills fa-2x text-white'></i>
                </div>
                <div className='feature-info-wrap'>
                  <h3 className='h5'>Vitamins</h3>
                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-6'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
                <div className='feature-icon icon-center pb-5 rounded-custom bg-primary'>
                  <i className='fal fa-gamepad fa-2x text-white'></i>
                </div>
                <div className='feature-info-wrap'>
                  <h3 className='h5'>Toys</h3>
                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-6'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
                <div className='feature-icon icon-center pb-5 rounded-custom bg-primary'>
                  <i className='fal fa-basketball-ball fa-2x text-white'></i>
                </div>
                <div className='feature-info-wrap'>
                  <h3 className='h5'>Sports</h3>
                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-6'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
                <div className='feature-icon icon-center pb-5 rounded-custom bg-primary'>
                  <i className='fal fa-laptop fa-2x text-white'></i>
                </div>
                <div className='feature-info-wrap'>
                  <h3 className='h5'>Electronics</h3>
                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-6'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
                <div className='feature-icon icon-center pb-5 rounded-custom bg-primary'>
                  <i className='fal fa-screwdriver-wrench fa-2x text-white'></i>
                </div>
                <div className='feature-info-wrap'>
                  <h3 className='h5'>Tools</h3>
                </div>
              </div>
            </div>
          </div>

          {/* <div className='row pt-60'>
            <div className='col-lg-6 mt-4'>
              <div className='position-relative d-flex flex-column h-100 flex-wrap bg-danger-soft p-5 rounded-custom'>
                <div className='cta-left-info mb-2'>
                  <h5>Utilize your software data</h5>
                  <p>
                    Progressively reinvent models and niche revolutionary
                    benefits for integrated niches.{' '}
                  </p>
                </div>
                <div className='action-btns mt-auto'>
                  <Link
                    to='/request-for-demo'
                    className='btn btn-outline-primary btn-sm'
                  >
                    Start For Free
                  </Link>
                </div>
                <div className='cta-img position-absolute right-0 bottom-0'>
                  <img
                    src='/assets/img/cta-img-1.png'
                    alt='cta'
                    className='img-fluid'
                  />
                </div>
              </div>
            </div>
            <div className='col-lg-6 mt-4'>
              <div className='position-relative d-flex flex-column h-100 flex-wrap bg-primary-soft p-5 rounded-custom'>
                <div className='cta-left-info mb-2'>
                  <h5>Get real time updated anytime</h5>
                  <p>
                    Progressively reinvent models and niche revolutionary
                    benefits for integrated niches.{' '}
                  </p>
                </div>
                <div className='action-btns mt-auto'>
                  <Link
                    to='/request-for-demo'
                    className='btn btn-outline-primary btn-sm'
                  >
                    Start For Free
                  </Link>
                </div>
                <div className='cta-img position-absolute right-0 bottom-0'>
                  <img
                    src='/assets/img/cta-img-2.png'
                    alt='cta img'
                    className='img-fluid'
                  />
                </div>
              </div>
            </div>
          </div> */}

        </div>
      </section>
    </>
  );
};

export default FeatureHighlight;
