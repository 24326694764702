import React from 'react';
import { Link } from 'react-router-dom';

export default function WhatIsSmartSeller() {
  return (
    <section className="digi-how-works bg-white pt-80 pb-120">
      {/* <div className="container pb-60">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="digi-how-works-left">
              <h2>
                What is SmartSeller ?
              </h2>
              <p>
                Get to know the all-in-one platform to manage all of your ecommerce operations.
              </p>
              <Link
                to="/request-for-demo"
                className="btn btn-primary mt-3"
              >
                Discover SmartSeller
              </Link>
            </div>
          </div>
          <div className="col-lg-6">

            <div className="mt-5 mt-lg-0 mb-5 ms-5">
              <div className="dig-how-steps">
                  <img
                    src='/assets/img/home/what_is.png'
                    alt='article'
                    className='img-fluid rounded-custom'
                    style={{maxWidth: "800px", maxHeight: "auto"}}
                  />

              </div>
            </div>

          </div>
        </div>
      </div> */}
      <div className='container'>
        <div className="row justify-content-center" id="solutions">
          <div className="col-lg-8">
            <div className="text-center mb-5">
              <h2>
                Discover the right solutions for your business.
              </h2>
            </div>
          </div>
        </div>

        {/* <div className='row'>
          <div className='col-lg-3 col-md-6'>
            <div className='single-article rounded-custom mb-4 mb-lg-0'>
              <Link to='/blog-single' className='article-img'>
                <img
                  src='/assets/img/blog/blog-1.jpg'
                  alt='article'
                  className='img-fluid'
                />
              </Link>
              <div className='article-content p-4'>
                <Link to='blog-single'>
                  <h2 className='h5 article-title limit-2-line-text'>
                    Ecommerce
                  </h2>
                </Link>
                <p className='limit-2-line-text'>
                  Sell online and manage your operations in a single app.
                </p>
                <Link
                  to="/services"
                  className="read-more-link text-decoration-none"
                >
                  Watch demos <i className="far fa-arrow-right ms-2"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-6'>
            <div className='single-article rounded-custom mb-4 mb-lg-0'>
              <Link to='/blog-single' className='article-img'>
                <img
                  src='/assets/img/blog/blog-2.jpg'
                  alt='article'
                  className='img-fluid'
                />
              </Link>
              <div className='article-content p-4'>
                <Link to='/blog-single'>
                  <h2 className='h5 article-title limit-2-line-text'>
                    Sales Channels
                  </h2>
                </Link>
                <p className='limit-2-line-text'>
                  Enable multiple sales channels to ramp up growth.
                </p>
                <Link
                  to="/services"
                  className="read-more-link text-decoration-none"
                >
                  Watch demos <i className="far fa-arrow-right ms-2"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-md-6'>
            <div className='single-article rounded-custom mb-4 mb-lg-0'>
              <Link to='/blog-single' className='article-img'>
                <img
                  src='/assets/img/blog/blog-2.jpg'
                  alt='article'
                  className='img-fluid'
                />
              </Link>
              <div className='article-content p-4'>
                <Link to='/blog-single'>
                  <h2 className='h5 article-title limit-2-line-text'>
                    Order Fulfillment
                  </h2>
                </Link>
                <p className='limit-2-line-text'>
                  Pick items, fulfill orders, and print shipping labels.
                </p>
                <Link
                  to="/services"
                  className="read-more-link text-decoration-none"
                >
                  Watch demos <i className="far fa-arrow-right ms-2"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-md-6'>
            <div className='single-article rounded-custom mb-4 mb-lg-0 mb-md-0'>
              <Link to='/blog-single' className='article-img'>
                <img
                  src='/assets/img/blog/blog-3.jpg'
                  alt='article'
                  className='img-fluid'
                />
              </Link>
              <div className='article-content p-4'>
                <Link to='/blog-single'>
                  <h2 className='h5 article-title limit-2-line-text'>
                    Product Management
                  </h2>
                </Link>
                <p className='limit-2-line-text'>
                  Track inventory and sync product data across listings.
                </p>
                <Link
                  to="/services"
                  className="read-more-link text-decoration-none"
                >
                  Watch demos <i className="far fa-arrow-right ms-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </div> */}

          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom mb-4 mb-lg-0'>
                <Link to='#' className='article-img'>
                  <img
                    src='/assets/img/home/expand_globally.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link to='#' className='d-inline-block text-dark badge bg-warning-soft'>
                      Expand Globally
                    </Link>
                  </div>
                  <Link to='#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Launch multiple storefronts across multiple markets.
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Create multiple storefronts with localized currencies and market specific prices.
                  </p>
                  {/* <Link
                    to="/services"
                    className="read-more-link text-decoration-none"
                  >
                    Learn more <i className="far fa-arrow-right ms-2"></i>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom mb-4 mb-lg-0'>
                <Link to='#' className='article-img'>
                  <img
                    src='/assets/img/home/omnichannel_seller.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link to='#' className='d-inline-block text-dark badge bg-primary-soft'>
                      Omnichannel Seller
                    </Link>
                  </div>
                  <Link to='#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Manage omnichannel operations from one application.
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Manage listings, inventory, fulfillment, shipping labels, and more, all from one application.
                  </p>
                  {/* <Link
                    to="/services"
                    className="read-more-link text-decoration-none"
                  >
                    Learn more <i className="far fa-arrow-right ms-2"></i>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom mb-4 mb-lg-0 mb-md-0'>
                <Link to='#' className='article-img'>
                  <img
                    src='/assets/img/home/headless_ecommerce.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link to='#' className='d-inline-block text-dark badge bg-danger-soft'>
                      Headless Ecommerce
                    </Link>
                  </div>
                  <Link to='#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Leverage SmartSeller's REST API for ultimate flexibility.
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Build a customized solution by integrating API endpoints into your existing architecture.
                  </p>
                  {/* <Link
                    to="/services"
                    className="read-more-link text-decoration-none"
                  >
                    Learn more <i className="far fa-arrow-right ms-2"></i>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
  );
}
