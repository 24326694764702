import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const DataProcessingAddendumDetails = () => {
  return (
    <>
      <section className='support-content ptb-60'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-8 p-lg-8'>

              <div className='support-article-wrap mb-5'>
                <p>
                  This Data Processing Addendum (<strong>"Addendum"</strong>) amends the Terms of Service and any other terms that incorporate by reference this Addendum (together, the 
                  <strong>"Agreement"</strong>) by and between you and SmartSeller Inc., a United States corporation located at 66 W Flagler St, 9th Floor, Miami, FL, 33130, United States,
                  on behalf of itself, (<strong>"SmartSeller"</strong>).                                
                </p>
              </div>

              <div className='support-article-wrap mb-5'>
                <h1 className='display-5 mb-4 fw-bold'>
                  1. Definitions
                </h1>
                <ol type="a">
                  <li>
                    <strong>"European Data Protection Laws"</strong> refers to the European Union Regulation 2016/679, commonly known as the "General Data Protection Regulation" (GDPR), the UK Data 
                    Protection Act 2018 (DPA), and the UK General Data Protection Regulation, defined by the DPA and amended by the Data Protection, Privacy and Electronic Communications (Amendments etc) 
                    (EU Exit) Regulations 2019. Collectively, these include any pertinent laws, statutes, regulations, rules, or binding instruments that implement or pertain to data protection, privacy, 
                    data security, or the processing of Personal Data in any European member state or the United Kingdom. These laws are applicable, in force, and subject to amendments, consolidation, 
                    re-enactment, or replacement over time.
                  </li>
                  <li>
                    The term <strong>"Personal Data"</strong> will be interpreted in alignment with European Data Protection Laws and US Data Protection Laws, as applicable. It pertains to identifiable or 
                    identified individuals, specifically customers who visit or engage in transactions through your store (a <strong>"Customer"</strong>). SmartSeller acts as a Data Processor or Service 
                    Provider (as defined under these laws) while providing you, a Data Controller or Business (as defined under these laws), with its Services. The term "Personal Data" also encompasses 
                    "Personal Information" as defined under US Data Protection Laws. However, it's important to note that Personal Data excludes information processed by SmartSeller in the context of 
                    services provided directly to a consumer, such as through its consumer-facing applications.
                  </li>
                  <li>
                    <strong>"US Data Protection Laws"</strong> refers to the California Consumer Privacy Act, amended by the California Privacy Rights Act (CCPA), the Virginia Consumer Data Protection Act 
                    (VCDPA), the Colorado Privacy Act (CPA), the Utah Consumer Privacy Act (UCPA), the Connecticut Act Concerning Personal Data Privacy and Online Monitoring (CTDPA), and other analogous 
                    comprehensive state privacy laws that impose obligations on a Business or Controller concerning Personal Data (as defined under such laws). This includes any relevant regulations, 
                    rules, or other binding instruments implementing such laws. These laws are applicable and in force, subject to amendments, consolidation, re-enactment, or replacement over time.
                  </li>
                  <li>
                    <strong>"US Consumer"</strong> refers to an individual who qualifies as a "consumer" as per the definition outlined in US Data Protection Laws.
                  </li>
                  <li>
                    All other terms written in capital letters within this Addendum will carry the same meaning as defined in the Agreement.
                  </li>
                </ol>
              </div>

              <div className='support-article-wrap mb-5'>
                <h1 className='display-5 mb-4 fw-bold'>
                  2. Details of Processing
                </h1>
                <p>
                  <strong>2.1</strong> The involved parties acknowledge that the content and particulars of the processing of Personal Data are outlined in Appendix 1 of this Addendum. SmartSeller holds 
                  the right to aggregate, anonymize, or deidentify Personal Data and process it for the purposes outlined in Appendix 1 or as permitted by applicable law. In cases where SmartSeller 
                  receives Deidentified Personal Data from you (as defined in section 5.1 of this Addendum), SmartSeller will handle and utilize the data solely in a Deidentified manner.
                </p>
              </div>

              <div className='support-article-wrap mb-5'>
                <h1 className='display-5 mb-4 fw-bold'>
                  3. European Union and United Kingdom
                </h1>
                <p>
                  <strong>3.1</strong> This section is applicable only to the extent that SmartSeller's processing of Personal Data falls under the jurisdiction of European Data Protection Laws. In 
                  this context, terms such as <strong>"Data Processor," "Data Controller," "Data Subject," "Processing," "Subprocessor,"</strong> and <strong>"Supervisory Authority"</strong> shall 
                  be interpreted in accordance with European Data Protection Laws.
                </p>
                <p>
                  <strong>3.2</strong> You acknowledge that SmartSeller functions as an independent Data Controller concerning personal data collected from consumers through its consumer-facing 
                  applications and services.
                </p>
                <p>
                  <strong>3.3</strong> For Data Subjects located in the European Economic Area or the United Kingdom, the Personal Data of such individuals will be processed by SmartSeller, Inc. 
                  ("SmartSeller US"). As part of the Services, this Personal Data may be transferred to other regions, in compliance with relevant Data Protection Legislation.
                </p>
                <p>
                  <strong>3.4</strong> During the processing of Personal Data in the provision of Services, SmartSeller US commits to:
                </p>
                <ul>
                  <li>
                    <strong>3.4.1</strong> Process the Personal Data as a Data Processor and/or Service Provider solely for the purpose of delivering the Services in accordance with documented 
                    instructions from you (provided that such instructions align with the functionalities of the Services), and as may subsequently be agreed upon by you. If, by law, SmartSeller US 
                    is required to Process the Personal Data for any other purpose, prior notice of this requirement will be provided to you unless prohibited by law.
                  </li>
                  <li>
                    <strong>3.4.2</strong> As part of delivering the Services, SmartSeller US transfers Personal Data at your direction to Stripe, PayPal, and other payment processors, processes Personal 
                    Data as part of processing payments or transactions. In this capacity, Stripe, PayPal, and other payment processors function as independent Data Controllers regarding any Personal 
                    Data related to Customers that they may process, and we assume no responsibility for their data processing practices. Further information about Stripe's privacy practices can be found 
                    here: https://stripe.com/privacy. Further information about PayPal's privacy practices can be found here: https://www.paypal.com/us/legalhub/privacy-full. For information about privacy
                    practices for other payment processors, check the payment processor's website.
                  </li>
                  <li>
                    <strong>3.4.3</strong> SmartSeller US will notify you if, in its opinion, your instruction for the Processing of Personal Data infringes applicable European Data Protection Laws. 
                    Additionally, SmartSeller US will promptly inform you, to the extent permitted by law, upon receiving an inquiry or complaint from a Supervisory Authority related to its Processing 
                    of the Personal Data.
                  </li>
                  <li>
                    <strong>3.4.4</strong> To fulfill your obligations regarding your Customer's Personal Data, SmartSeller US will implement reasonable technical and organizational measures. Adequate 
                    measures will also be implemented to protect the Personal Data against unauthorized or unlawful processing, accidental loss, destruction, damage, theft, alteration, or disclosure. 
                    These measures will be suitable for the potential harm resulting from unauthorized or unlawful processing, accidental loss, destruction, damage, or theft of Personal Data, and will 
                    be appropriate to the nature of the Personal Data to be protected.
                  </li>
                  <li>
                    <strong>3.4.5</strong> Upon your request, SmartSeller US will provide reasonable information to assist you in completing your data protection impact assessments and prior 
                    consultations with regulatory authorities. Furthermore, SmartSeller US will, upon request, furnish you with up-to-date attestations, reports, or extracts thereof, available from an 
                    entity responsible for auditing SmartSeller US's data protection practices (e.g., external auditors, internal audit, data protection auditors), or appropriate certifications to 
                    assess compliance with the terms of this Addendum.
                  </li>
                  <li>
                    <strong>3.4.6</strong> SmartSeller US will promptly notify you upon becoming aware of and confirming any accidental, unauthorized, or unlawful processing of, disclosure of, or 
                    access to the Personal Data. It ensures that its personnel accessing the Personal Data are bound by confidentiality obligations.
                  </li>
                  <li>
                    <strong>3.4.7</strong> Upon termination of the Agreement, SmartSeller US will initiate its purge process to delete or anonymize the Personal Data promptly. Alternatively, 
                    within 60 days of termination, you may request that SmartSeller return such Personal Data.
                  </li>
                </ul>
                <p>
                  <strong>3.5</strong> During the provision of the Services, you acknowledge and grant SmartSeller US general written authorization to utilize Subprocessors, as listed on the 
                  <Link to='/subprocessor-list'> SmartSeller's subprocessors</Link> (<strong>"Subprocessor List"</strong>) available online, for the processing of Personal Data. SmartSeller US's 
                  engagement of any specific Subprocessor for Personal Data processing must adhere to European Data Protection Laws and be governed by a contract between SmartSeller US and the 
                  Subprocessor, ensuring comparable protections to those outlined in this Data Processing Addendum. In the event that SmartSeller US appoints a new subprocessor or plans changes 
                  related to the addition or replacement of subprocessors, such modifications will be reflected in our Subprocessor List. You have a seven (7) day period from the date of the 
                  Subprocessor List update to object to the change. If you object to the appointment of a Subprocessor, you have the option to terminate this agreement in accordance with the 
                  terms of the Agreement and your SmartSeller Agreement, if applicable.
                </p>
                <p>
                  <strong>3.6</strong> You affirm that you have adhered to and continue to adhere to European Data Protection Laws. Specifically, you have secured any required consents, 
                  provided necessary notices, and possess a legitimate basis to disclose data to SmartSeller US, enabling the processing of Personal Data in accordance with the terms outlined 
                  in this Agreement.
                </p>
              </div>

              <div className='support-article-wrap mb-5'>
                <h1 className='display-5 mb-4 fw-bold'>
                  4. US Consumers
                </h1>
                <p>
                  <strong>4.1</strong> This section is applicable only to the extent that, under the US Data Protection Laws, you qualify as a Business or Controller. In the process of delivering 
                  the Services, if SmartSeller handles Personal Data about US Consumers that falls under the jurisdiction of US Data Protection Laws, the following terms from US Data Protection 
                  Laws shall apply in this section: <strong>"Business", "Business Purpose", "Commercial Purpose", "Controller", "Deidentified", "Processor", "Sell", "Sale", "Service Provider",</strong> 
                  and <strong>"Share"</strong>, which is defined in the CCPA, are incorporated herein by reference.
                </p>
                <p>
                  <strong>4.2</strong> In regard to such Personal Data and as mandated by applicable US Data Protection Laws, SmartSeller will:
                </p>
                <ul>
                  <li>
                    <strong>4.2.1</strong> Process Personal Data as a Service Provider and/or Processor on your behalf to deliver the Services or as otherwise permitted by US Data Protection Laws.
                  </li>
                  <li>
                    <strong>4.2.2</strong> Not retain, use, or disclose Personal Data outside its direct business relationship with you or for any purpose other than providing the Services. This includes 
                    refraining from retaining, using, or disclosing such Personal Data for a Commercial Purpose other than performing the Business Purposes outlined in the Agreement or as otherwise 
                    permitted by US Data Protection Laws.
                  </li>
                  <li>
                    <strong>4.2.3</strong> Not Sell or Share such Personal Data.
                  </li>
                  <li>
                    <strong>4.2.4</strong> Not combine Personal Data collected during the Services with Personal Data from another source or collected independently, except to perform the Services, 
                    with consent or direction, or as otherwise permitted by US Data Protection Laws.
                  </li>
                  <li>
                    <strong>4.2.5</strong> In connection with processing Personal Data, comply with provisions of US Data Protection Laws applicable to Service Providers or Processors. This includes 
                    providing the same level of privacy protection required of Businesses or Controllers by US Data Protection Laws. SmartSeller will notify you if it determines it can no longer meet these 
                    obligations, allowing you to take reasonable and appropriate steps to stop and remediate any unauthorized use of Personal Data by SmartSeller.
                  </li>
                  <li>
                    <strong>4.2.6</strong> Only engage subcontractors to process Personal Data on its behalf through a written contract that requires protections comparable to those in this Data 
                    Processing Addendum. You acknowledge and grant SmartSeller general written authorization to use subcontractors listed online at <Link to='/subprocessor-list'> SmartSeller's subprocessors</Link> 
                    (<strong>"Subprocessor List"</strong>) to process Personal Data. This usage must comply with US Data Protection Laws and be governed by a contract between SmartSeller and Subcontractor 
                    that requires comparable protections to this Data Processing Addendum. If SmartSeller appoints a new subcontractor or intends changes concerning subcontractors, such updates will be 
                    reflected in our Subprocessor List. You have seven (7) days from the update to object, and if no response is received, the change is deemed accepted. If you object, termination may 
                    occur in accordance with the Agreement and your SmartSeller Agreement, if applicable.
                  </li>
                  <li>
                    <strong>4.2.7</strong> Take reasonable and appropriate steps, upon reasonable written notice from you, to assist you in confirming that SmartSeller's use of Personal Data aligns 
                    with your obligations under US Data Protection Laws.
                  </li>
                  <li>
                    <strong>4.2.8</strong> Upon request, provide a report of a reasonable assessment of SmartSeller's policies and technical and organizational measures supporting its obligations under 
                    applicable US Data Protection Laws. This assessment will use an appropriate and accepted control standard or framework and assessment procedure.
                  </li>
                  <li>
                    <strong>4.2.9</strong> Upon termination of the Agreement, initiate a prompt purge process to delete or Deidentify the Personal Data. You may also request, within 60 days of 
                    termination, that SmartSeller return such Personal Data.
                  </li>
                </ul>  
                <p>
                  <strong>4.3</strong> You affirm and guarantee that you:
                </p>
                <ul>
                  <li>
                    <strong>4.3.1</strong> Have obtained all necessary consents, rights, and authorizations, and provided any required notices to individuals regarding the disclosure of Personal Data 
                    to SmartSeller. This enables SmartSeller to process Personal Data for the provision of Services, in compliance with applicable law.
                  </li>
                  <li>
                    <strong>4.3.2</strong> Will not share with SmartSeller any Personal Data of individuals subject to US Data Protection Laws who have exercised an opt-out commitment that you are 
                    obligated to honor.
                  </li>
                  <li>
                    <strong>4.3.3</strong> Will not share sensitive data of any US Consumer with SmartSeller unless explicit consent for the processing of such sensitive data has been obtained.
                  </li>
                  <li>
                    <strong>4.3.4</strong> Will promptly inform SmartSeller of any rights requests made by individuals pursuant to US Data Protection Laws that SmartSeller must adhere to. You will 
                    also provide the necessary information to facilitate SmartSeller's compliance with such requests.
                  </li>
                  <li>
                    <strong>4.3.5</strong> Assume sole liability for your compliance with these laws.
                  </li>
                </ul>
                <p>
                  <strong>4.4</strong> Both you and SmartSeller acknowledge that the existence of this Addendum does not imply an admission that the sharing of Personal Data constitutes a Sale or a Share.
                </p>
              </div>

              <div className='support-article-wrap mb-5'>
                <h1 className='display-5 mb-4 fw-bold'>
                  5. General
                </h1>
                <p>
                  <strong>5.1</strong> In case of any conflict or inconsistency between the provisions of the Agreement and this Addendum, the provisions of this Addendum shall prevail, except where 
                  such provisions contradict a requirement under applicable law, in which case the applicable law shall prevail. All liability under this Addendum, including limitations thereof, will 
                  be governed by the relevant provisions of the Agreement, to the extent allowed by applicable law. You acknowledge that Shopify may modify this Addendum by posting the amended version 
                  on SmartSeller's website at <Link to='/data-processing-addendum'> Data Processing Addendum</Link>. Such amendments are effective as of the date of posting. Your continued use of the 
                  Services after the amended Addendum is posted constitutes your acceptance of the changes. If you do not agree to any modifications, discontinue using the Service.
                </p>
                <p>
                  <strong>5.2</strong> Unless explicitly altered in this Addendum, all terms, provisions, and requirements in the Agreement shall remain in full force and effect and govern this Addendum. 
                  If any provision of the Addendum is deemed illegal or unenforceable in a judicial proceeding, that provision shall be severed and rendered inoperative. The remainder of this Addendum 
                  shall continue to be operative and binding on the parties.
                </p>
                <p>
                  <strong>5.3</strong> The terms of this Addendum shall be governed by and interpreted in accordance with the laws of the State of Florida and the laws of United States applicable therein, 
                  without regard to conflicts of laws principles. The parties irrevocably and unconditionally submit to the exclusive jurisdiction of the courts of the State of Florida for any dispute 
                  or claim arising out of or in connection with this Addendum.
                </p>
              </div>

              <div className='support-article-wrap mb-5'>
                <h1 className='display-5 mb-4 fw-bold'>
                  Appendix 1: Details of Processing
                </h1>
                <p>
                  <strong>Nature and purpose of processing:</strong> The processing is carried out to deliver and enhance the Services as outlined in the Terms of Service and any other agreements 
                  incorporating this Addendum. It includes providing support to the Customer, in compliance with European Data Protection Laws or US Data Protection Laws, as applicable, or as directed 
                  by you periodically.
                </p>
                <p>
                  <strong>Subject Matter, Types of Personal Data, and Categories of Data Subjects:</strong> The processing involves Personal Data related to Customers.
                </p>
                <p>
                  <strong>Duration of processing:</strong> The processing period encompasses the duration of this Addendum, plus the time from the Addendum's conclusion until SmartSeller fulfills its 
                  obligation to delete all Customer Personal Data in accordance with the terms of this Addendum.
                </p>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};


export default DataProcessingAddendumDetails;
