import React from 'react';
import AUPPolicyDefinitionsHeader from '../../components/aup-policy-definitions/AUPPolicyDefinitionsHeader';
import PageMeta from '../../components/common/PageMeta';
import AUPPolicyDefinitionsDetails from '../../components/aup-policy-definitions/AUPPolicyDefinitionsDetails';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const AUPPolicyDefinitions = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar navDark />
      <AUPPolicyDefinitionsHeader />
      <AUPPolicyDefinitionsDetails />
      <FooterOne
        style={{
          background:
            "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default AUPPolicyDefinitions;