import React from 'react';
import { Link } from 'react-router-dom';


const BlogPostHeader = ({title, categories, readTime, updatedDate}) => {
  return (
    <React.Fragment>
      <section
        className='page-header position-relative overflow-hidden ptb-120 bg-dark'
        style={{
          background:
            "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-12'>
              <div className='text-center'>
                <h1 className='display-5 fw-bold'>{title}</h1>
                {/* <h4 className='lead'>How SmartSeller handles your data.</h4> */}
                {/* <p>Updated November 3, 2023</p> */}
              </div>
            </div>
          </div>

          <div className='row justify-content-center text-center'>
            <div className='col me-2'>
              <p className='fw-bold' style={{'textAlign': 'right'}}>{updatedDate}</p>
            </div>
            <div className='col-1'>
              <p>|</p>
            </div>
            <div className='col ms-2'>
              <p className='fw-bold' style={{'textAlign': 'left'}}>{readTime}</p>
            </div>
          </div>


          {categories ? (
            <div className='row justify-content-center text-center'>
              <div className='col-xl-8'>
                {categories.map((data) => (
                  <Link
                    key={`header-${data}`}
                    to='#'
                    className='btn btn-soft-primary btn-pill btn-sm m-2'
                  >
                    {data}
                  </Link>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}          
          <div className='bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5'></div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default BlogPostHeader;
