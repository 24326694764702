import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


const AuthorCard = ({ author, menu }) => {
  return (
    <>
      <div className='author-wrap  bg-light p-5 sticky-sidebar rounded-custom mt-5 mt-lg-0'>

        <div className='mb-4 text-left'>
          <div className='author-info'>
            <h5 className='mb-0'>In This Post</h5>
          </div>
          <ul>
            {menu.map((data) => (
              <li>
                <HashLink to={`#${data.id}`} className='text-muted text-decoration-none py-1 d-block'>{data.label}</HashLink>
              </li>
            ))}
          </ul>
        </div>
        <hr className='mb-4'></hr>
        <div className='text-center'>
          <div className='author-info'>
            <h5 className='mb-0'>{author.name}</h5>
            <span className='small'>{author.title}</span>
          </div>
          <ul className='list-unstyled author-social-list list-inline mb-0'>

            {
              author.social.linkedin 
                ? (
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-linkedin-in'></i>
                      </Link>
                    </li>    
                  )
                : (
                    <></>
                  )
            }

            {
              author.social.gitHub 
                ? (
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-github'></i>
                      </Link>
                    </li>    
                  )
                : (
                    <></>
                  )
            }

            {
              author.social.gitLab
                ? (
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-gitlab'></i>
                      </Link>
                    </li>    
                  )
                : (
                    <></>
                  )
            }

          </ul>
        </div>
      </div>
    </>
  );
};

export default AuthorCard;
