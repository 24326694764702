import React from 'react';
import DataProcessingAddendumHeader from '../../components/data-processing-addendum/DataProcessingAddendumHeader';
import PageMeta from '../../components/common/PageMeta';
import DataProcessingAddendumDetails from '../../components/data-processing-addendum/DataProcessingAddendumDetails';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const DataProcessingAddendum = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar navDark />
      <DataProcessingAddendumHeader />
      <DataProcessingAddendumDetails />
      <FooterOne
        style={{
          background:
            "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default DataProcessingAddendum;