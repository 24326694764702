import { Eduardo, Richard, SmartSeller } from './authors'


  // { 
  //   id: 6,
  //   key: 'blogPost6',
  //   author: Eduardo,
  //   categories: [
  //     'Analytics',
  //     'Marketing',
  //   ],
  //   images: [
  //     ''
  //   ],
  //   videos: [],
  //   updatedDateString: 'December 19, 2023',
  //   updatedDate: '2023-12-19',
  //   readTime: '6 min read',
  //   title: '',
  //   slug: '',
  //   excerpt: '',
  //   menu: [
  //     {
  //       id: '',
  //       label: ''
  //     },
  //   ]    
  // },


const blogPosts = [
  { 
    id: 10,
    key: 'blogPost10',
    author: Eduardo,
    categories: [
      'Analytics',
      'A/B Testing',
    ],
    images: [
      '/assets/img/blog/guide-to-a-b-testing-discovering-the-optimal-solution.jpg'
    ],
    videos: [],
    updatedDateString: 'December 22, 2023',
    updatedDate: '2023-12-22',
    readTime: '9 min read',
    title: 'Guide to A/B Testing: Discovering the Optimal Solution',
    slug: 'guide-to-a-b-testing-discovering-the-optimal-solution',
    excerpt: 'In the ever-evolving online landscape, finding the most effective solution to a problem often involves exploring various options.',
    menu: [
      {
        id: 'understanding',
        label: 'Understanding The Mechanics Of A/B Testing'
      },
      {
        id: 'advantages',
        label: 'The Advantages Of A/B Testing'
      },
      {
        id: 'executing',
        label: 'Executing A/B Testing'
      },
      {
        id: 'conclusion',
        label: 'Concluding Thoughts: Effective A/B Testing Strategies'
      }            
    ]    
  },
  { 
    id: 9,
    key: 'blogPost9',
    author: Eduardo,
    categories: [
      'Analytics',
      'Split Testing',
    ],
    images: [
      '/assets/img/blog/introduction-to-split-testing.jpg'
    ],
    videos: [],
    updatedDateString: 'December 21, 2023',
    updatedDate: '2023-12-21',
    readTime: '5 min read',
    title: 'Introduction to Split Testing: Getting Started Guide',
    slug: 'introduction-to-split-testing',
    excerpt: 'To identify the elements that yield the best results on a web page, turn to split testing, also known as A/B testing.',
    menu: [
      {
        id: 'getting-started',
        label: 'Getting Started'
      },
      {
        id: 'what',
        label: 'What To Test?'
      },
      {
        id: 'number',
        label: 'Determining The Number Of Elements To Test Simultaneously'
      },
      {
        id: 'create',
        label: 'Create Your Test'
      },
      {
        id: 'completion',
        label: 'Determining The Completion Of Your Testing'
      },
      {
        id: 'tips',
        label: 'Tips For Effective Split Testing'
      }
    ]    
  },
  { 
    id: 8,
    key: 'blogPost8',
    author: Eduardo,
    categories: [
      'Analytics',
      'Split Testing',
    ],
    images: [
      '/assets/img/blog/guide-to-split-testing-your-website.jpg'
    ],
    videos: [],
    updatedDateString: 'December 20, 2023',
    updatedDate: '2023-12-20',
    readTime: '10 min read',
    title: 'Guide To Split Testing Your Website: Method And Purpose',
    slug: 'guide-to-split-testing-your-website',
    excerpt: 'Split testing provides invaluable data to elevate decision-making and achieve your objectives, whether you aim to enhance a landing page, refine blog post headlines, optimize calls to action (CTAs), or improve any other element of your site.',
    menu: [
      {
        id: 'reduce',
        label: 'Reduce Bounce Rate'
      },
      {
        id: 'engagement',
        label: 'Enhanced User Engagement'
      },
      {
        id: 'increased-conversion-rate',
        label: 'Increased Conversion Rate'
      },
      {
        id: 'boosted-sales',
        label: 'Boosted Sales'
      },
      {
        id: 'executing-split-testing',
        label: 'Executing Split Testing'
      },
      {
        id: 'evaluate',
        label: 'Evaluating The Results'
      },
      {
        id: 'duration',
        label: 'Testing Duration'
      }
    ]    
  },
  { 
    id: 7,
    key: 'blogPost7',
    author: Richard,
    categories: [
      'Analytics',
      'Marketing',
    ],
    images: [
      '/assets/img/blog/elevate-your-stores-potential-with-google-domains-and-analytics.jpg'
    ],
    videos: [],
    updatedDateString: 'December 20, 2023',
    updatedDate: '2023-12-20',
    readTime: '8 min read',
    title: 'Elevate Your Store\'s Potential with Google Domains and Analytics',
    slug: 'elevate-your-stores-potential-with-google-domains-and-analytics',
    excerpt: 'Presenting a polished and professional image for your business is crucial. This doesn\'t entail sacrificing your personality or pretending to be larger than you are.',
    menu: [
      {
        id: 'identity',
        label: 'Unique Domain Identity'
      },
      {
        id: 'analytics',
        label: 'Analytics'
      }
    ]    
  },
  { 
    id: 6,
    key: 'blogPost6',
    author: Eduardo,
    categories: [
      'Analytics',
      'Marketing',
    ],
    images: [
      '/assets/img/blog/creating-a-toolkit-for-google-analytics.jpg'
    ],
    videos: [],
    updatedDateString: 'December 19, 2023',
    updatedDate: '2023-12-19',
    readTime: '6 min read',
    title: 'Creating A Toolkit For Google Analytics',
    slug: 'creating-a-toolkit-for-google-analytics',
    excerpt: 'In this guide, we\'ll assist you in comprehending some fundamental tools and features of Google Analytics, enabling you to optimize your operations seamlessly.',
    menu: [
      {
        id: 'significant',
        label: 'Why This Is Significant'
      },
      {
        id: 'getting-started',
        label: 'Getting Started With Google Analytics'
      },
      {
        id: 'charts',
        label: 'Deciphering The Charts'
      },
      {
        id: 'pause',
        label: 'Take A Moment'
      }
    ]    
  },
  { 
    id: 5,
    key: 'blogPost5',
    author: Richard,
    categories: [
      'Domains',
      'Setup',
      'Store Management',
    ],
    images: [
      '/assets/img/blog/why-you-need-a-personalized-domain.jpg'
    ],
    videos: [],
    updatedDateString: 'December 18, 2023',
    updatedDate: '2023-12-18',
    readTime: '5 min read',
    title: 'Why You Need A Personalized Domain',
    slug: 'why-you-need-a-personalized-domain',
    excerpt: 'Your personalized URL, known as a custom domain, serves as a unique web address for showcasing your shop.',
    menu: [
      {
        id: 'authentic',
        label: 'Be Distinctive, Be Authentic'
      },
      {
        id: 'quality',
        label: 'Affordability Doesn\'t Always Guarantee Quality'
      },
      {
        id: 'preferred',
        label: 'Our Preferred Domain Name Providers'
      },
      {
        id: 'choosing',
        label: 'Choosing An Exceptional Custom Domain'
      }
    ]    
  },
  { 
    id: 4,
    key: 'blogPost4',
    author: Eduardo,
    categories: [
      'Domains',
      'Setup',
      'Store Management',
    ],
    images: [
      '/assets/img/blog/utilize-a-personalized-domain.jpg'
    ],
    videos: [],
    updatedDateString: 'December 18, 2023',
    updatedDate: '2023-12-18',
    readTime: '2 min read',
    title: 'Utilize A Personalized Domain',
    slug: 'utilize-a-personalized-domain',
    excerpt: 'A personalized domain refers to a distinct URL tailored to your brand, guiding customers directly to your online content upon entering it into the address bar.',
    menu: [
      {
        id: 'what',
        label: 'What Constitutes A Personalized Domain?'
      },
      {
        id: 'subdomain',
        label: 'Choosing the Right Time For A Subdomain?'
      },
      {
        id: 'need',
        label: 'In Need Of A Domain?'
      },
      {
        id: 'have',
        label: 'Already Have A Domain?'
      }
    ]    
  },
  { 
    id: 3,
    key: 'blogPost3',
    author: SmartSeller,
    categories: [
      'Domains',
      'Hosting'
    ],
    images: [
      '/assets/img/blog/domain-providers.jpg'
    ],
    videos: [],
    updatedDateString: 'December 17, 2023',
    updatedDate: '2023-12-17',
    readTime: '1 min read',
    title: 'Compatible Domain Service Providers',
    slug: 'compatible-domain-service-providers',
    excerpt: 'Connecting your domain to your SmartSeller storefront is a straightforward process, even if it\'s currently linked to another website.',
    menu: [
      {
        id: 'domain-providers',
        label: 'Domain Providers'
      }
    ]    
  },
  {
    id: 2,
    key: 'blogPost2',
    author: Eduardo,
    categories: [
      'Marketing',
      'SEO'
    ],
    images: [
      '/assets/img/blog/an-introduction-into-search-engine-optimization.jpg'
    ],
    videos: [],
    updatedDateString: 'December 16, 2023',
    updatedDate: '2023-12-16',
    readTime: '7 min read',
    title: 'An Introduction Into Search Engine Optimization',
    slug: 'an-introduction-into-search-engine-optimization',
    excerpt: 'Search engine optimization (SEO) is not about tricks or temporary tactics; it\'s about implementing best practices to enhance the visibility of your website in search results.',
    menu: [
      {
        id: 'fundamentals',
        label: 'Grasp The Fundamentals',
      },
      {
        id: 'descriptions',
        label: 'Craft Compelling Descriptions'
      },
      {
        id: 'domain',
        label: 'Opt For A Personalized Domain'
      },
      {
        id: 'solutions',
        label: 'Provide Informative Solutions'
      },
      {
        id: 'spam',
        label: 'Avoid Spamming'
      },
      {
        id: 'evolve',
        label: 'Be Prepared To Evolve'
      }
    ]
  },    
  {
    id: 1,
    key: 'blogPost1',
    author: Eduardo,
    categories: [
      'Analytics',
      'Setup',
      'Store Management'
    ],
    images: [
      '/assets/img/blog/google-analytics.jpg'
    ],
    videos: [
      'https://www.youtube.com/embed/LA2dPVj5y4Y'
    ],
    updatedDateString: 'December 14, 2023',
    updatedDate: '2023-12-14',
    readTime: '6 min read',
    title: 'Google Analytics',
    slug: 'google-analytics',
    excerpt: 'Armed with insights from Google Analytics, you can make informed decisions about your website\'s content, its structure and presentation, and the most effective marketing strategies. Embracing real data empowers your decision-making process like nothing else!',
    menu: [
      {
        id: 'setup',
        label: 'Setup',
      }
    ]
  }
];


export { blogPosts };