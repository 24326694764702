import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const ShippingTermsDetails = () => {
  return (
    <>
      <section className='support-content ptb-60'>
        <div className='container'>
          <div className='row justify-content-center'>

            <div className='col-lg-8 col-md-8 p-lg-8'>

              <div className='support-article-wrap mb-5'>
                <p>                
                  When utilizing the United States shipping services ("SmartSeller Shipping") provided by SmartSeller, Inc. ("SmartSeller"), you are accepting the terms and conditions outlined in these 
                  Shipping Terms. These terms supplement the SmartSeller Terms of Service and the Privacy Policy. Specifically tailored for merchants based in the United States, these Shipping Terms apply. 
                  If you operate from a different jurisdiction, refer to the SmartSeller Terms of Service for applicable shipping terms. Any additions or tools integrated into SmartSeller Shipping will 
                  be governed by these Shipping Terms. SmartSeller retains the right to modify these terms by posting updates. The current version of the Shipping Terms can be reviewed at any time at 
                  https://www.sseller.io/shipping-terms-us. Regularly check the Shipping Terms for potential updates or changes affecting you.
                </p>
              </div>

              <div className='support-article-wrap mb-5'>
                <h1 className='display-5 mb-4 fw-bold'>
                  A. SmartSeller Shipping
                </h1>
                <p>
                  Before utilizing SmartSeller Shipping, it is essential that you thoroughly read, agree to, and accept all the terms and conditions outlined in these Shipping Terms.
                </p>
                <p>
                  Your usage of SmartSeller Shipping must align with the procedures provided by SmartSeller, and you commit to adhering to any guidelines issued by SmartSeller from time to time. If 
                  you disagree with these procedures, refrain from using SmartSeller Shipping.
                </p>
                <p>
                  SmartSeller Shipping grants you access to purchase postage from the United States Postal Service ("USPS"), services from UPS Market Driver, Inc. ("UPS"), and services from DHL Express 
                  (USA), Inc. ("DHL"), as well as services from Bringer Parcel Services ("BPS") and EasyPost ("EasyPost"), as well as other carriers, subject to eligibility. Each of USPS, UPS, DHL, BPS,
                  EasyShip, and other carriers is referred to as a "Carrier," collectively known as the "Carriers." Eligibility criteria apply. It is required to create separate Carrier accounts to utilize 
                  SmartSeller Shipping.
                </p>
              </div>

              <div className='support-article-wrap mb-5'>
                <h1 className='display-5 mb-4 fw-bold'>
                  B. Your Responsibilities
                </h1>
                <p>
                  SmartSeller functions solely as an intermediary facilitating the purchase of services, postage, and the printing of shipping labels ("Labels") between you and the relevant Carrier. The 
                  responsibility for shipping items to your customers lies with you. SmartSeller does not undertake tasks such as picking up, transporting, delivering, or managing your shipments.
                </p>
                <p>
                  When utilizing USPS postage, you must adhere to and ensure compliance with all relevant USPS rules. Refer to <Link to="https://www.usps.com/ship/shipping-restrictions.htm" target="_blank">
                   Shipping Restrictions</Link> for detailed information on items permitted or prohibited for shipment through USPS, and consult 
                  <Link to="https://pe.usps.com/text/dmm300/dmm300_landing.htm" target="_blank"> Mailing Standards</Link> for packaging and labeling requirements.
                </p>
                <p>
                  When utilizing USPS postage, Shopify will share shipment information with USPS, and such data is subject to the following USPS privacy provision:
                </p>
                <p>
                  <strong>Privacy Act Statement:</strong> Your information will be used to facilitate the purchase of U.S. Postal Service (USPS) postage and fulfill transactional reporting requirements for 
                  USPS postage systems. Collection is authorized by 39 U.S.C. 401, 403, and 404. Providing the information is voluntary, but if not provided, your transaction may not be processed. USPS 
                  does not disclose your information to third parties without your consent, except to facilitate the transaction, to act on your behalf or request, or as legally required. This includes the 
                  following limited circumstances: to a congressional office on your behalf; to financial entities regarding financial transaction issues; to a USPS auditor; to entities, including law 
                  enforcement, as required by law or in legal proceedings; and to contractors and other entities aiding us to fulfill the service (service providers). For more information regarding our 
                  privacy policies, visit www.usps.com/privacypolicy.
                </p>
                <p>
                  When utilizing DHL shipping, your compliance with all applicable DHL rules is essential. Refer to <Link to="https://www.dhl.com/us-en/home.html" target="_blank"> DHL's Terms and Conditions
                  of Carriage</Link> for the governing terms and conditions of your shipment via DHL.
                </p>
                <p>
                  Similarly, your use of UPS shipping requires adherence to all relevant UPS rules. Consult the <Link to="https://www.ups.com/us/en/Home.page" target="_blank"> UPS Tariff/Terms and 
                  Conditions of Service - United States</Link> and the <Link to="https://www.ups.com/assets/resources/media/UTA_with_EUR.pdf" target="_blank"> UPS Technology Agreement</Link> for the terms 
                  and conditions governing your shipment with UPS.
                </p>
                <p>
                  For BPS services, refer to <Link to="https://bringerparcel.com/" target="_blank"> BPS's Terms and Conditions</Link> governing your use of BPS services.
                </p>
                <p>
                  For EasyPost services, refer to <Link to="https://easypost.com/" target="_blank"> EasyPost's Terms and Conditions</Link> governing your use of EasyPost services.
                </p>
                <p>
                  For other carriers' services, refer to the carrier's terms and conditions.
                </p>
                <p>
                  It is your responsibility to ensure compliance with all shipping and customs regulations, both local and international.
                </p>
                <p>
                  SmartSeller Shipping must be linked to SmartSeller orders, and the labels obtained through it cannot be transferred or sold to third parties. You have the discretion to charge or pass 
                  through shipping costs to your customers.
                </p>
                <p>
                  Without the express written permission of SmartSeller, you agree not to reproduce, duplicate, copy, sell, resell, or exploit any part of SmartSeller Shipping or use it without 
                  authorization. It is prohibited to use SmartSeller Shipping for illegal or unauthorized purposes. In your use of SmartSeller Shipping, you must not violate any laws in your jurisdiction, 
                  United States, or the State of Florida.
                </p>
                <p>
                  Any local customs charges, import taxes, or similar fees related to your shipments are your responsibility. You must determine whether such charges will be incurred and arrange for their 
                  payment. If any such charges are imposed on SmartSeller by a competent authority due to your use of SmartSeller Shipping, you agree to reimburse SmartSeller within 7 days of our demand.
                </p>
                <p>
                  SMARTSELLER PROVIDES ACCESS TO SMARTSELLER SHIPPING ON AN "AS IS" AND "AS AVAILABLE" BASIS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. SMARTSELLER MAKES NO REPRESENTATION OR
                  WARRANTIES OF ANY KIND REGARDING SMARTSELLER SHIPPING. SMARTSELLER DISCLAIMS ALL CONDITIONS AND WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES 
                  OR MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. SMARTSELLER SHALL NOT BE LIABLE FOR ANY DAMAGES ARISING FROM OR RELATED TO YOUR USE OF SMARTSELLER SHIPPING, INCLUDING DIRECT, SPECIAL,
                  EXEMPLARY, PUNITIZE, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES. IN NO EVENT SHALL SMARTSELLER'S ENTIRE LIABILITY TO YOU EXCEED THE PRICE YOU PAID FOR SMARTSELLER SHIPPING.
                </p>
                <p>
                  You agree to indemnify and hold SmartSeller, our parent, subsidiaries, affiliates, Shopify partners, officers, directors, agents, and employees harmless from any liability, expense, 
                  loss, claim, or demand, including reasonable attorneys' fees, arising from unauthorized use of SmartSeller Shipping, your breach of these Shipping Terms, violation of any law or 
                  third-party rights, any data uploaded by you, or your actions related to SmartSeller Shipping.
                </p>
                <p>
                  Your use of SmartSeller Shipping is at your own risk, and you should familiarize yourself with and approve the terms of shipping services provided by 
                  <Link to="https://www.usps.com/" target="_blank"> USPS</Link>, <Link to="https://www.ups.com/" target="_blank"> UPS</Link>, <Link to="https://www.dhl.com/" target="_blank"> DHL</Link>, 
                  <Link to="https://www.bringerparcel.com/" target="_blank"> BPS</Link>, <Link to="https://www.easypost.com/" target="_blank"> EasyPost</Link>, and other carriers.
                </p>
              </div>

              <div className='support-article-wrap mb-5'>
                <h1 className='display-5 mb-4 fw-bold'>
                  C. Carrier Accounts
                </h1>
                <p>
                  SmartSeller enables you to integrate your own carrier account(s) with SmartSeller Shipping, allowing you to provide your negotiated carrier rates and buy labels using those rates within 
                  SmartSeller Shipping ("Your Carrier Account"). If you link a Carrier Account to SmartSeller Shipping, all fees, credits, refunds, and charges associated with Your Carrier Account(s) will 
                  be handled directly between you and the carrier(s). You are accountable to the carrier(s) for all transactions made using Your Carrier Account(s). The rates displayed during checkout and for 
                  label purchases when utilizing Your Carrier Account(s) are approximations and may not precisely mirror the rates negotiated with the carrier(s). SmartSeller does not assume responsibility 
                  for the accuracy of rates during checkout or for label purchases involving Your Carrier Account(s). While SmartSeller can assist in connecting Your Carrier Account(s) with SmartSeller 
                  Shipping, any support requirements related to Your Carrier Account(s) should be directed to the carrier(s). If you encounter questions or issues with Your Carrier Account(s), please contact 
                  the carrier(s) directly.
                </p>
              </div>

              <div className='support-article-wrap mb-5'>
                <h1 className='display-5 mb-4 fw-bold'>
                  D. Returned or Abandoned Shipments
                </h1>
                <p>
                  All Fees associated with returned or abandoned shipments are your responsibility, regardless of whether you approve the return or abandonment.
                </p>
              </div>

              <div className='support-article-wrap mb-5'>
                <h1 className='display-5 mb-4 fw-bold'>
                  E. General Terms
                </h1>
                <p>
                  SmartSeller retains the right to modify, suspend, or terminate SmartSeller Shipping or your access to it at any time, with or without notice, for any reason.
                </p>
                <p>
                  Your use of SmartSeller Shipping, along with the information transmitted or stored by SmartSeller, is governed by SmartSeller's privacy policy available at 
                  https://www.sseller.io/privacy-policy.
                </p>
                <p>
                  These Shipping Terms are subject to the laws of the State of Florida and the laws of United States, without regard to conflicts of laws principles. The parties submit exclusively 
                  to the jurisdiction of the courts of the State of Florida regarding any dispute or claim arising from these Shipping Terms. The United Nations Convention on Contracts for the 
                  International Sale of Goods is expressly excluded from applying to these Shipping Terms.
                </p>
                <p>
                  SmartSeller reserves the right to amend these Shipping Terms by posting the revised version at https://www.sseller.io/shipping-terms-us. Amendments are effective as of the posting date. 
                  Your continued use of SmartSeller Shipping after any changes constitutes acceptance of the amended Shipping Terms. If you disagree with the changes, refrain from using the Shipping 
                  Service.
                </p>
                <p>
                  The failure of SmartSeller to enforce any right or provision in these Shipping Terms does not waive that right or provision.
                </p>
                <p>
                  If any part of these Shipping Terms is deemed unenforceable or invalid by a court, it will be enforced to the fullest extent permitted by law, and the remainder will remain valid and 
                  enforceable.
                </p>
                <p>
                  <strong>SmartSeller, Inc.</strong><br></br>
                  ATTN: Chief Privacy Officer<br></br>
                  66 W Flagler St, 9th Floor,<br></br>
                  Miami, FL, 33130,<br></br>
                  United States<br></br>
                </p>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};


export default ShippingTermsDetails;
