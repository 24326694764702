import React from 'react';
import APITermsOfUseHeader from '../../components/api-terms-of-use/APITermsOfUseHeader';
import PageMeta from '../../components/common/PageMeta';
import APITermsOfUseDetails from '../../components/api-terms-of-use/APITermsOfUseDetails';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const APITermsOfUse = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar navDark />
      <APITermsOfUseHeader />
      <APITermsOfUseDetails />
      <FooterOne
        style={{
          background:
            "url('/assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default APITermsOfUse;